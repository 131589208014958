import { Component, CUSTOM_ELEMENTS_SCHEMA, input, OnInit, Optional, Self } from '@angular/core';
import { Content, Editor } from '@tiptap/core';
import { TipTapExtensionsService } from '../../services/tiptap-extensions.service';
import { NgxTiptapModule } from 'ngx-tiptap';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NgControl,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import { NgClass, NgStyle } from '@angular/common';
import { PickerColor, TextStyle } from '../../types/tiptap.type';
import { ButtonComponent, ChromaFormField, ChromaInput, ChromaSelectModule } from 'chroma-ui';
import { OverlayModule } from '@angular/cdk/overlay';
import { emojis } from '../../constants/emojis';
import Placeholder from '@tiptap/extension-placeholder';

@Component({
    selector: 'app-editor',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        ReactiveFormsModule,
        ChromaFormField,
        ChromaInput,
        ChromaSelectModule,
        ButtonComponent,
        OverlayModule,
        NgxTiptapModule
    ],
    providers: [TipTapExtensionsService],
    templateUrl: './editor.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditorComponent implements OnInit, ControlValueAccessor {
    editorClasses = input<string>();
    placeholder = input<string>();
    toolbarMode = input<'always' | 'focused'>('always');
    focused = false;

    showToolbar = false;
    editor = new Editor({
        extensions: this.tiptapExtensionsService.getExtensions(),
        onSelectionUpdate: ({ editor }) => {
            if (editor.isActive('paragraph')) {
                this.textStyleControl.setValue('paragraph');
            } else {
                this.textStyleControl.setValue('heading');
            }
        },
        onFocus: () => {
            if (this.toolbarMode() === 'focused') {
                this.showToolbar = true;
            }

            this.focused = true;
        },
        onBlur: () => {
            this.onTouched();

            this.focused = false;
        },
        onUpdate: ({ editor, transaction }) => {
            if (!transaction.docChanged) {
                return;
            }

            this.onChange(editor.getJSON());
        }
    });

    textStyleControl = new FormControl<TextStyle>('paragraph');

    isColorPickerOpen = false;
    pickerColors = Object.values(PickerColor);
    PickerColor = PickerColor;

    isEmojiPickerOpen = false;
    emojis = emojis.map(emoji =>
        String.fromCodePoint(...emoji.split('-').map(u => parseInt(`0x${u}`, 16)))
    );

    isLinkInputOpen = false;

    linkForm = new FormGroup({
        url: new FormControl<string>(null, Validators.required),
        text: new FormControl<string>(null)
    });

    get linkUrlControl() {
        return this.linkForm.controls.url;
    }

    get linkTextControl() {
        return this.linkForm.controls.text;
    }

    get invalid() {
        const control = this.ngControl?.control;
        return control?.invalid && control?.touched;
    }

    protected onChange: (value: Content) => void = () => {};
    protected onTouched: () => void = () => {};

    constructor(
        @Optional() @Self() private ngControl: NgControl,
        public tiptapExtensionsService: TipTapExtensionsService
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.editor.extensionManager.extensions.find(
            extension => extension.name === Placeholder.name
        ).options.placeholder = this.placeholder();

        this.editor.setOptions({
            editorProps: {
                attributes: {
                    class: `tw-outline-none ${this.editorClasses()}`
                }
            }
        });

        this.showToolbar = this.toolbarMode() === 'always';
    }

    writeValue(value: Content): void {
        if (this.editor) {
            if (!value && this.toolbarMode() === 'focused') {
                this.showToolbar = false;
            }

            this.editor.commands.setContent(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    changeTextStyle(textStyle: TextStyle): void {
        if (textStyle === 'paragraph') {
            this.editor.commands.setParagraph();
        } else {
            this.editor.commands.setHeading({ level: 2 });
        }
    }

    changeTextColor(color: PickerColor): void {
        if (color === PickerColor.Black) {
            this.editor.chain().focus().unsetColor().run();
        } else {
            this.editor.chain().focus().setColor(color).run();
        }

        this.isColorPickerOpen = false;
    }

    openLinkInput(): void {
        this.isLinkInputOpen = true;

        const { view, state } = this.editor;
        const { from, to } = view.state.selection;

        if (!state.selection.empty) {
            const text = state.doc.textBetween(from, to, '');

            this.linkTextControl.setValue(text);
        }
    }

    insertLink(): void {
        if (this.linkForm.valid) {
            const linkText = this.linkTextControl.value || this.linkUrlControl.value;

            if (this.linkTextControl.dirty || !this.linkTextControl.value) {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: this.linkUrlControl.value })
                    .command(({ tr }) => {
                        tr.insertText(linkText);
                        return true;
                    })
                    .run();
            } else {
                this.editor.commands.setLink({
                    href: this.linkUrlControl.value
                });
            }

            this.isLinkInputOpen = false;
            this.linkForm.reset();
        }
    }

    insertImage(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = e => {
                this.editor
                    .chain()
                    .focus()
                    .setImage({ src: e.target.result as string })
                    .run();
            };

            reader.readAsDataURL(file);
        }
    }
}
