import { Directive } from "@angular/core";

@Directive()
export abstract class ChromaFormFieldControl {

    readonly focused: boolean;

    readonly disabled: boolean;

    readonly errorState: boolean;

    readonly pointer?: boolean;

    readonly panelOpen?: boolean;

    abstract onContainerClick(event: MouseEvent): void;
}
