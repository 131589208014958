import { createReducer, on } from '@ngrx/store';
import { NavigationState } from './navigation.state';
import { NavigationActions } from './navigation.actions';

export const navigationFeatureKey = 'navigation';

export const initialState: NavigationState = {
    groups: []
};

export const navigationReducer = createReducer(
    initialState,
    on(NavigationActions.setGroups, (state, { data }) => ({
        ...state,
        groups: data.map(group => ({
            ...group,
            unseen: false,
            expanded: false,
            navigationItems: group.navigationItems.map(item => ({
                ...item,
                unseen: false
            }))
        }))
    })),
    on(NavigationActions.expandActiveGroup, (state, { categoryId }) => ({
        ...state,
        groups: state.groups.map(group => ({
            ...group,
            expanded: group.navigationItems.some(item => item.categoryId === categoryId)
        }))
    })),
    on(NavigationActions.toogleGroup, (state, { groupIndex }) => ({
        ...state,
        groups: state.groups.map((group, index) => ({
            ...group,
            expanded: index === groupIndex ? !state.groups[groupIndex].expanded : false
        }))
    })),
    on(NavigationActions.patchUnseen, (state, { data }) => ({
        ...state,
        groups: state.groups.map(group => {
            const navigationItems = group.navigationItems.map(item => {
                const unseen = data[item.categoryId].unseenRegular + data[item.categoryId].unseenMustRead > 0;
                return unseen ? { ...item, unseen } : item;
            });

            return {
                ...group,
                unseen: navigationItems.some(item => item.unseen),
                navigationItems
            };
        })
    }))
);
