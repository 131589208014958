import { DialogRef } from '@angular/cdk/dialog';
import { Observable } from 'rxjs';

export class ChromaDialogRef<T, R = any> {
    componentInstance: T;

    constructor(private _ref: DialogRef<R, T>) {}

    close(dialogResult?: R): void {
        this._ref.close(dialogResult);
    }

    afterClosed(): Observable<R | undefined> {
        return this._ref.closed;
    }
}
