import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import { ArticleBaseStore } from './article-base.store';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ArticleContentComponent } from '../../../../../shared/components/article/article-content/article-content.component';
import { ArticleActionsComponent } from '../../../../../shared/components/article/article-actions/article-actions.component';
import { ArticleAssetsComponent } from '../../../../../shared/components/article/article-assets/article-assets.component';
import { ButtonComponent, ChromaDialog, ChromaLoading } from 'chroma-ui';
import { TiptapEditorPipe } from '../../../../../shared/pipes/tiptap-editor.pipe';
import { CommentBase } from '../../../../../shared/types/contents.type';
import { SafeResourceUrlPipe } from '../../../../../shared/pipes/safe-resource-url.pipe';
import { filter, map, switchMap, take } from 'rxjs';
import { ReadMode } from '../../../../../core/ui/store/ui.type';
import { Store } from '@ngrx/store';
import * as UISelectors from '../../../../../core/ui/store/ui.selectors';
import { ArticleViewStore } from '../article-view.store';
import { TipTapExtensionsService } from '../../../../../shared/services/tiptap-extensions.service';
import { NgxTiptapModule } from 'ngx-tiptap';
import { ReactiveFormsModule } from '@angular/forms';
import { CommentInputComponent } from './components/comment-input.component';
import { JSONContent } from '@tiptap/core';
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.type';
import { ContentsApi } from '../../../../../shared/api/contents.api';
import * as AuthSelectors from '../../../../../core/auth/store/auth.selectors';

@Component({
    selector: 'app-article-base',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        AsyncPipe,
        DatePipe,
        SafeResourceUrlPipe,
        ReactiveFormsModule,
        ArticleContentComponent,
        ArticleActionsComponent,
        ArticleAssetsComponent,
        ButtonComponent,
        ChromaLoading,
        NgxTiptapModule,
        TiptapEditorPipe,
        CommentInputComponent
    ],
    providers: [ArticleBaseStore, TipTapExtensionsService],
    templateUrl: './article-base.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArticleBaseComponent implements OnInit {
    @ViewChild('printView', { static: false }) printView: ElementRef<HTMLIFrameElement>;

    categoryId: string;
    origin = window.location.origin;
    printViewVisible = false;
    ReadMode = ReadMode;

    authUser$ = this.store.select(AuthSelectors.selectAuthUser);
    readMode$ = this.store.select(UISelectors.selectReadMode);

    article$ = this.articleBaseStore.article$;
    articleLoading$ = this.articleBaseStore.articleLoading$;
    articleComments$ = this.articleBaseStore.articleComments$;

    articleLoaded$ = this.articleViewStore.articleLoaded$;
    scrollbarActive$ = this.articleViewStore.scrollbarActive$;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private readonly articleBaseStore: ArticleBaseStore,
        private readonly articleViewStore: ArticleViewStore,
        private dialog: ChromaDialog,
        private contentsApi: ContentsApi
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(
                map(params => ({
                    categoryId: params.categoryId,
                    articleId: params.articleId
                }))
            )
            .subscribe(({ categoryId, articleId }) => {
                this.categoryId = categoryId;

                this.articleBaseStore.getArticle(articleId);
            });
    }

    onCopyToClipboard(): void {
        navigator.clipboard.writeText(window.location.href);
    }

    onArticleBookmark(bookmarked: boolean): void {
        if (bookmarked) {
            this.articleBaseStore.unsetBookmark();
        } else {
            this.articleBaseStore.setBookmark();
        }
    }

    onArticleLike(liked: boolean): void {
        if (liked) {
            this.articleBaseStore.unsetLike();
        } else {
            this.articleBaseStore.setLike();
        }
    }

    onArticlePrint(): void {
        this.printViewVisible = true;
    }

    onArticleDelete(): void {
        this.articleBaseStore.deleteArticle(this.categoryId);
    }

    onArticleEdit(articleId: string): void {
        this.articleBaseStore.getArticle(articleId);
    }

    asCommentBase(response: any): CommentBase & { editing: boolean } {
        return response;
    }

    onCommentSubmit(body: JSONContent): void {
        this.articleBaseStore.createComment({
            type: 'comment',
            body
        });
    }

    onSubCommentSubmit(body: JSONContent, parentCommentId: string): void {
        this.articleBaseStore.createComment({
            type: 'sub-comment',
            body,
            parentCommentId
        });
    }

    onCommentEdit(commentId: string, parentCommentId?: string): void {
        this.articleBaseStore.setCommentEditing({ commentId, parentCommentId });
    }

    onCommentUpdate(articleId: string, commentId: string, body: JSONContent): void {
        this.contentsApi
            .updateComment(articleId, commentId, body)
            .pipe(take(1))
            .subscribe(() => this.articleBaseStore.getCommentsForArticle(false));
    }

    onCommentDelete(articleId: string, commentId: string): void {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
            ConfirmDialogComponent,
            {
                data: {
                    title: 'Kommentar löschen',
                    message: `Sind Sie sich sicher, dass Sie diesen Kommentar endgültig löschen wollen?`,
                    type: 'delete'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => result?.confirmed),
                switchMap(() => this.contentsApi.deleteComment(articleId, commentId))
            )
            .subscribe(() => this.articleBaseStore.getCommentsForArticle(false));
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent): void {
        if (event.data === 'onafterprint') {
            this.printViewVisible = false;
        }
    }
}
