import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { ButtonComponent, ChromaLoading, ChromaTooltipModule } from 'chroma-ui';
import { ContentsApi } from '../../../../shared/api/contents.api';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { BehaviorSubject, filter, map, switchMap, take } from 'rxjs';
import { ReadMode } from '../../../../core/ui/store/ui.type';
import { Store } from '@ngrx/store';
import { UIActions } from '../../../../core/ui/store/ui.actions';
import * as UISelectors from '../../../../core/ui/store/ui.selectors';
import { ArticleViewStore } from './article-view.store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const DESKTOP_BREAKPOINT = 1536;
@Component({
    selector: 'app-article-view',
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        AsyncPipe,
        DatePipe,
        ButtonComponent,
        ChromaLoading,
        ChromaTooltipModule,
        RouterOutlet,
        InfiniteScrollModule
    ],
    providers: [ArticleViewStore, ContentsApi],
    templateUrl: './article-view.component.html'
})
export class ArticleViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('articleBase') articleBase: ElementRef;

    categoryId: string;
    articleId: string;
    innerHeight = window.innerHeight;
    observer: ResizeObserver;
    ReadMode = ReadMode;
    skeletonItems = Array.from({ length: 5 }, (_, i) => i);

    desktopBreakpoint = new BehaviorSubject(false);

    readMode$ = this.store.select(UISelectors.selectReadMode);

    intros$ = this.articleViewStore.intros$;
    introsLoading$ = this.articleViewStore.introsLoading$;
    introsEndReached$ = this.articleViewStore.introsEndReached$;

    articleLoaded$ = this.articleViewStore.articleLoaded$;

    scrollbarActive$ = this.articleViewStore.scrollbarActive$;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private readonly articleViewStore: ArticleViewStore
    ) {}

    ngOnInit(): void {
        this.setDesktopBreakpoint();

        this.route.params.pipe(map(params => params.categoryId)).subscribe(categoryId => {
            this.categoryId = categoryId;

            this.desktopBreakpoint
                .pipe(
                    filter(valid => valid),
                    take(1),
                    switchMap(() =>
                        this.readMode$.pipe(
                            filter(readMode => readMode === ReadMode.Explore),
                            take(1)
                        )
                    )
                )
                .subscribe(() => this.articleViewStore.createSession({ categoryId }));
        });

        this.route.firstChild?.params.subscribe(params => (this.articleId = params.articleId));
    }

    ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    ngAfterViewInit(): void {
        this.observer = new ResizeObserver(() => this.articleViewStore.setScrollbarActive());

        this.observer.observe(this.articleBase.nativeElement);
    }

    changeReadMode(): void {
        this.store.dispatch(UIActions.changeReadMode());
    }

    loadMoreItems(): void {
        this.articleViewStore.loadNextSet();
    }

    setDesktopBreakpoint(): void {
        this.desktopBreakpoint.next(window.innerWidth >= DESKTOP_BREAKPOINT);
    }

    @HostListener('window:resize')
    onResize(): void {
        this.articleViewStore.setScrollbarActive();
        this.setDesktopBreakpoint();
    }
}
