import { Component, EventEmitter, input, OnInit, Output } from '@angular/core';
import { EditorComponent } from '../../../../../../shared/components/editor/editor.component';
import { ButtonComponent } from 'chroma-ui';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { JSONContent } from '@tiptap/core';
import { ArticleBaseStore } from '../article-base.store';
import { filter } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';

@Component({
    selector: 'app-comment-input',
    standalone: true,
    imports: [ReactiveFormsModule, ButtonComponent, EditorComponent],
    templateUrl: './comment-input.component.html'
})
export class CommentInputComponent implements OnInit {
    editorClasses = input<string>();
    parentCommentId = input<string>();
    editing = input<boolean>(false);
    placeholder = input<string>();
    value = input<JSONContent>();

    @Output() upsertComment = new EventEmitter<JSONContent>();

    commentControl = new FormControl(null, Validators.required);

    constructor(private readonly articleBaseStore: ArticleBaseStore) {}

    ngOnInit(): void {
        this.articleBaseStore.submitted$
            .pipe(
                concatLatestFrom(() => this.articleBaseStore.submittedParentCommentId$),
                filter(
                    ([submitted, parentCommentId]) =>
                        (submitted === 'sub-comment' &&
                            parentCommentId === this.parentCommentId()) ||
                        submitted === 'comment'
                )
            )
            .subscribe(() => {
                this.commentControl.reset();
                this.articleBaseStore.resetSubmitted();
            });

        if (this.value()) {
            this.commentControl.setValue(this.value());
        }
    }

    onCommentUpsert(): void {
        if (this.commentControl.valid) {
            this.upsertComment.emit(this.commentControl.getRawValue());
        } else {
            this.commentControl.markAsTouched();
        }
    }
}
