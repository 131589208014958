@switch (type()) {
    @case ('dot') {
        <svg
            width="72"
            height="20"
            viewBox="0 0 72 20">
            <circle
                class="circle"
                cx="8"
                cy="10"
                r="8"></circle>
            <circle
                class="circle"
                cx="36"
                cy="10"
                r="8"></circle>
            <circle
                class="circle"
                cx="64"
                cy="10"
                r="8"></circle>
        </svg>
    }
    @default {
        <svg
            class="spinner"
            viewBox="25 25 50 50">
            <circle
                class="spinner-path tw-stroke-primary-base"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="3" />
        </svg>
    }
}
