import {
    AfterViewInit,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import { ArticleBaseStore } from './article-base.store';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ArticleContentComponent } from '../../../../../shared/components/article/article-content/article-content.component';
import { ArticleActionsComponent } from '../../../../../shared/components/article/article-actions/article-actions.component';
import { ArticleAssetsComponent } from '../../../../../shared/components/article/article-assets/article-assets.component';
import { ChromaLoading } from 'chroma-ui';
import { TiptapEditorPipe } from '../../../../../shared/pipes/tiptap-editor.pipe';
import { Article } from '../../../../../shared/types/contents.type';
import { SafeResourceUrlPipe } from '../../../../../shared/pipes/safe-resource-url.pipe';
import { map } from 'rxjs';
import { ReadMode } from '../../../../../core/ui/store/ui.type';
import { Store } from '@ngrx/store';
import * as UISelectors from '../../../../../core/ui/store/ui.selectors';
import { ArticleViewStore } from '../article-view.store';
import { TipTapExtensionsService } from '../../../../../shared/services/tiptap-extensions.service';
import { NgxTiptapModule } from 'ngx-tiptap';

@Component({
    selector: 'app-article-base',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        AsyncPipe,
        DatePipe,
        SafeResourceUrlPipe,
        ArticleContentComponent,
        ArticleActionsComponent,
        ArticleAssetsComponent,
        ChromaLoading,
        NgxTiptapModule,
        TiptapEditorPipe
    ],
    providers: [ArticleBaseStore, TipTapExtensionsService],
    templateUrl: './article-base.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArticleBaseComponent implements OnInit {
    @ViewChild('printView', { static: false }) printView: ElementRef<HTMLIFrameElement>;

    categoryId: string;
    articleId: string;
    origin = window.location.origin;
    printViewVisible = false;
    ReadMode = ReadMode;

    readMode$ = this.store.select(UISelectors.selectReadMode);

    article$ = this.articleBaseStore.article$;
    articleLoading$ = this.articleBaseStore.articleLoading$;
    articleComments$ = this.articleBaseStore.articleComments$;

    intros$ = this.articleViewStore.intros$;
    articleLoaded$ = this.articleViewStore.articleLoaded$;
    scrollbarActive$ = this.articleViewStore.scrollbarActive$;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private readonly articleBaseStore: ArticleBaseStore,
        private readonly articleViewStore: ArticleViewStore
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(
                map(params => ({
                    categoryId: params.categoryId,
                    articleId: params.articleId
                }))
            )
            .subscribe(({ categoryId, articleId }) => {
                this.categoryId = categoryId;
                this.articleId = articleId;

                this.articleBaseStore.getArticle(articleId);
            });
    }

    onCopyToClipboard(): void {
        navigator.clipboard.writeText(window.location.href);
    }

    onArticleBookmark(bookmarked: boolean): void {
        if (bookmarked) {
            this.articleBaseStore.unsetBookmark();
        } else {
            this.articleBaseStore.setBookmark();
        }
    }

    onArticleLike(liked: boolean): void {
        if (liked) {
            this.articleBaseStore.unsetLike();
        } else {
            this.articleBaseStore.setLike();
        }
    }

    onArticlePrint(): void {
        this.printViewVisible = true;
    }

    onArticleDelete(): void {
        this.articleBaseStore.deleteArticle(this.categoryId);
    }

    onArticleEdit(): void {
        this.articleBaseStore.getArticle(this.articleId);
    }

    asArticle(response: any): Article {
        return response as Article;
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent): void {
        if (event.data === 'onafterprint') {
            this.printViewVisible = false;
        }
    }
}
