import { CdkDialogContainer } from "@angular/cdk/dialog";
import { Component } from "@angular/core";
import { ChromaDialogConfig } from "./dialog-config";
import { PortalModule } from "@angular/cdk/portal";

@Component({
    selector: 'chroma-dialog-container',
    templateUrl: 'dialog-container.html',
    standalone: true,
    imports: [PortalModule]
})
export class ChromaDialogContainer extends CdkDialogContainer<ChromaDialogConfig> {

}
