@if (article(); as article) {
    <div class="tw-inline-flex tw-gap-x-5">
        @if (featureFlags.includes('LIKED') || featureFlags.includes('LIKED_ANONYMOUS')) {
            <button
                type="button"
                chroma-button
                size="icon"
                appearance="outline"
                class="tw-cursor-default"
                (click)="like.emit(article.meta.liked)">
                <span
                    class="material-symbols-rounded tw-icon-xl"
                    [ngClass]="{
                        'tw-filled-symbol': article.meta.liked
                    }"
                    >favorite</span
                >
            </button>
        }
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="clipboard.emit()">
            <span class="material-symbols-rounded tw-icon-xl">share</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="bookmark.emit(article.meta.bookmarked)">
            <span
                class="material-symbols-rounded tw-icon-xl"
                [ngClass]="{
                    'tw-filled-symbol': article.meta.bookmarked
                }"
                >bookmark</span
            >
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="print.emit()">
            <span class="material-symbols-rounded tw-icon-xl">print</span>
        </button>
        @if (article.meta.canDelete) {
            <button
                type="button"
                chroma-button
                size="icon"
                appearance="outline"
                class="tw-cursor-default"
                (click)="deleteArticle()">
                <span class="material-symbols-rounded tw-icon-xl">delete</span>
            </button>
        }
        @if (article.meta.canEdit) {
            <button
                type="button"
                chroma-button
                size="icon"
                appearance="outline"
                class="tw-cursor-default"
                (click)="startEditing()">
                <span class="material-symbols-rounded tw-icon-xl">edit</span>
            </button>
        }
    </div>
}
