import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '@shared/environments/environment';
import { Title } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(
        private titleService: Title
    ) {}

    ngOnInit(): void {
        registerLocaleData(de.default)

        this.titleService.setTitle(`MERCURY | ${environment.name}`);
    }
}
