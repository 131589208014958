import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, Optional, Output, ViewChild } from "@angular/core";
import { cva } from "cva";
import { CHROMA_OPTION_PARENT_COMPONENT, ChromaOptionParentComponent } from "./option.parent";
import { ChromaPseudoCheckboxComponent } from "../selection/pseudo-checkbox/pseudo-checkbox.component";

export const optionStyle = cva([
    'tw-flex',
    'tw-py-1.25', 'tw-px-3.5',
    'tw-cursor-pointer',
    'hover:tw-bg-primary-hover-light'
]);

export class ChromaOptionSelectionChange {
    constructor(
      public source: ChromaOption,
      public isUserInput = false,
    ) {}
}

@Component({
    selector: 'chroma-option',
    templateUrl: './option.html',
    standalone: true,
    host: {
        '[class.tw-bg-primary-hover-light]': 'active',
        '(click)': '_selectViaInteraction()'
    },
    imports: [ChromaPseudoCheckboxComponent]
})
export class ChromaOption {

    @HostBinding('class')
    hostClasses = optionStyle();

    private _selected = false;
    private _active = false;

    get multiple() {
        return this._parent && this._parent.multiple;
    }

    get selected(): boolean {
        return this._selected;
    }

    @Input() value: any;

    @Output() readonly selectionChange = new EventEmitter<ChromaOptionSelectionChange>();

    @ViewChild('text', {static: true}) _text: ElementRef<HTMLElement> | undefined;

    constructor(
        private _element: ElementRef<HTMLElement>,
        @Optional() @Inject(CHROMA_OPTION_PARENT_COMPONENT) private _parent: ChromaOptionParentComponent
    ) { }

    get active(): boolean {
        return this._active;
    }

    get viewValue(): string {
        return (this._text?.nativeElement.textContent || '').trim();
    }

    select(): void {
        if (!this._selected) {
            this._selected = true;
        }
    }

    deselect(): void {
        if (this._selected) {
            this._selected = false;
        }
    }

    setActiveStyles(): void {
        if (!this._active) {
            this._active = true;
        }
    }

    setInactiveStyles(): void {
        if (this._active) {
            this._active = false;
        }
    }

    _selectViaInteraction(): void {
        this._selected = this.multiple ? !this._selected : true;
        this._emitSelectionChangeEvent(true);
    }

    _getHostElement(): HTMLElement {
        return this._element.nativeElement;
    }

    private _emitSelectionChangeEvent(isUserInput = false): void {
        this.selectionChange.emit(new ChromaOptionSelectionChange(this, isUserInput));
    }
}

export function _getOptionScrollPosition(
    optionOffset: number,
    optionHeight: number,
    currentScrollPosition: number,
    panelHeight: number,
  ): number {
    if (optionOffset < currentScrollPosition) {
      return optionOffset;
    }
  
    if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
      return Math.max(0, optionOffset - panelHeight + optionHeight);
    }
  
    return currentScrollPosition;
  }
