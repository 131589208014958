import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
    AfterContentInit,
    Component,
    ContentChildren,
    Directive,
    EventEmitter,
    Inject,
    InjectionToken,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    booleanAttribute,
    forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cva } from 'cva';

let nextUniqueId = 0;

export const CHROMA_RADIO_GROUP_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChromaRadioGroup),
    multi: true
};

export const CHROMA_RADIO_GROUP = new InjectionToken<ChromaRadioGroup>('ChromaRadioGroup');

@Directive({
    selector: 'chroma-radio-group',
    providers: [
        CHROMA_RADIO_GROUP_CONTROL_VALUE_ACCESSOR,
        { provide: CHROMA_RADIO_GROUP, useExisting: ChromaRadioGroup }
    ],
    host: {
        role: 'radiogroup',
        class: 'tw-flex tw-gap-x-5 tw-gap-y-3.75',
        '[class.tw-flex-col]': 'orientation === "vertical"'
    },
    standalone: true
})
export class ChromaRadioGroup implements AfterContentInit, ControlValueAccessor {
    private _value: any = null;

    private _name: string = `chroma-radio-group-${nextUniqueId++}`;

    private _isInitialized: boolean = false;

    private _disabled: boolean = false;

    _controlValueAccessorChangeFn: (value: any) => void = () => {};

    onTouched: () => any = () => {};

    @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

    @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

    @ContentChildren(forwardRef(() => ChromaRadioButton), { descendants: true })
    _radios: QueryList<ChromaRadioButton>;

    @Input()
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    @Input()
    get value(): any {
        return this._value;
    }
    set value(newValue: any) {
        if (this._value !== newValue) {
            this._value = newValue;

            this._updateSelectedRadioFromValue();
        }
    }

    @Input({ transform: booleanAttribute })
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
    }

    ngAfterContentInit(): void {
        this._isInitialized = true;
    }

    private _updateSelectedRadioFromValue(): void {
        if (this._radios) {
            this._radios.forEach(radio => {
                radio.checked = this.value === radio.value;
            });
        }
    }

    _emitChangeEvent(): void {
        if (this._isInitialized) {
            this.change.emit(this._value);
        }
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => void) {
        this._controlValueAccessorChangeFn = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}

@Component({
    selector: 'chroma-radio-button',
    templateUrl: 'radio.html',
    standalone: true
})
export class ChromaRadioButton implements OnInit, OnDestroy {
    inputStyle = cva([
        'tw-form-radio',
        'tw-w-4.5',
        'tw-h-4.5',
        'tw-my-px',
        'tw-border-control-base',
        'tw-text-primary-base',
        'checked:tw-bg-checked',
        'checked:tw-border-transparent',
        'focus:tw-border-transparent',
        'focus:tw-ring',
        'focus:tw-ring-offset-0',
        'focus:tw-ring-primary-focus',
        'disabled:tw-cursor-not-allowed',
        'disabled:checked:tw-bg-neutral-400',
        'tw-cursor-pointer'
    ]);

    labelStyle = cva(['tw-mb-0', 'tw-text-sm', 'tw-leading-5'], {
        variants: {
            disabled: {
                true: 'tw-cursor-not-allowed tw-text-neutral-400',
                false: 'tw-cursor-pointer'
            }
        }
    });

    private _uniqueId: string = `chroma-radio-${++nextUniqueId}`;

    @Input() name: string;

    @Input({ transform: booleanAttribute })
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: boolean) {
        if (this._checked !== value) {
            this._checked = value;

            if (value) {
                this._radioDispatcher.notify(this._uniqueId, this.name);
            }
        }
    }

    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        if (this._value !== value) {
            this._value = value;
        }
    }

    @Input({ transform: booleanAttribute })
    get disabled(): boolean {
        return this._disabled || (this.radioGroup !== null && this.radioGroup.disabled);
    }
    set disabled(value: boolean) {
        this._setDisabled(value);
    }

    @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

    radioGroup: ChromaRadioGroup;

    get inputId(): string {
        return `checkbox-${this._uniqueId}-input`;
    }

    private _checked: boolean = false;

    private _disabled: boolean;

    private _value: any = null;

    private _removeUniqueSelectionListener: () => void = () => {};

    constructor(
        @Optional() @Inject(CHROMA_RADIO_GROUP) radioGroup: ChromaRadioGroup,
        private _radioDispatcher: UniqueSelectionDispatcher
    ) {
        this.radioGroup = radioGroup;
    }

    ngOnInit(): void {
        if (this.radioGroup) {
            this.checked = this.radioGroup.value === this._value;

            this.name = this.radioGroup.name;
        }

        this._removeUniqueSelectionListener = this._radioDispatcher.listen((id, name) => {
            if (id !== this._uniqueId && name === this.name) {
                this.checked = false;
            }
        });
    }

    ngOnDestroy(): void {
        this._removeUniqueSelectionListener();
    }

    private _emitChangeEvent(): void {
        this.change.emit(this._value);
    }

    _onInputInteraction(event: Event): void {
        event.stopPropagation();

        if (!this.checked && !this.disabled) {
            const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
            this.checked = true;
            this._emitChangeEvent();

            if (this.radioGroup) {
                this.radioGroup._controlValueAccessorChangeFn(this.value);
                if (groupValueChanged) {
                    this.radioGroup._emitChangeEvent();
                }
            }
        }
    }

    protected _setDisabled(value: boolean) {
        if (this._disabled !== value) {
            this._disabled = value;
        }
    }
}
