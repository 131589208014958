<div class="tw-flex tw-gap-x-2.5">
    <input
        type="radio"
        [class]="inputStyle()"
        [id]="inputId"
        [checked]="checked"
        [disabled]="disabled"
        [attr.name]="name"
        (change)="_onInputInteraction($event)" />
    <label
        [class]="labelStyle({ disabled })"
        [for]="inputId">
        <ng-content></ng-content>
    </label>
</div>
