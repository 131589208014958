export enum PickerColor {
    Black = '#363636',
    Neutral = '#A3A3A3',
    Blue = '#2563EB',
    Indigo = '#3730A3',
    Teal = '#0D9488',
    Amber = '#F59E0B',
    Red = '#DC2626',
    Violet = '#7C3AED'
}

export type TextStyle = 'heading' | 'paragraph';
