import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from '@shared/environments/environment';

const documentElement = document.querySelector('html');

documentElement.setAttribute('data-theme', environment.theme);
documentElement.setAttribute('data-chroma-ui', 'true');

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
