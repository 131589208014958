import { Component, EventEmitter, input, OnInit, Output } from '@angular/core';
import { Article } from '../../../types/contents.type';
import { ButtonComponent, ChromaDialog } from 'chroma-ui';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../dialogs/confirm-dialog/confirm-dialog.type';
import { filter } from 'rxjs';
import { ComposeArticleDialogComponent } from '../../dialogs/compose-article-dialog/compose-article-dialog.component';
import { ComposeArticleDialogData } from '../../dialogs/compose-article-dialog/compose-article-dialog.type';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { getFeatureFlags } from '../../../helpers/local-storage.helper';

@Component({
    selector: 'app-article-actions',
    standalone: true,
    imports: [NgClass, ButtonComponent],
    templateUrl: './article-actions.component.html'
})
export class ArticleActionsComponent implements OnInit {
    article = input<Article>();

    featureFlags = getFeatureFlags();

    @Output() clipboard = new EventEmitter<void>();
    @Output() bookmark = new EventEmitter<boolean>();
    @Output() like = new EventEmitter<boolean>();
    @Output() print = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() edit = new EventEmitter<void>();

    constructor(
        private router: Router,
        private dialog: ChromaDialog
    ) {}

    ngOnInit(): void {
        this.router.events.subscribe(() => this.dialog.closeAll());
    }

    deleteArticle(): void {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
            ConfirmDialogComponent,
            {
                data: {
                    title: 'Beitrag löschen',
                    message:
                        'Sind Sie sich sicher, dass Sie diesen Beitrag endgültig löschen wollen?',
                    type: 'delete'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.confirmed))
            .subscribe(() => this.delete.emit());
    }

    startEditing(): void {
        const dialogRef = this.dialog.open<ComposeArticleDialogComponent, ComposeArticleDialogData>(
            ComposeArticleDialogComponent,
            {
                data: {
                    mode: 'edit',
                    article: this.article()
                },
                defaultContainer: false,
                positionStrategy: new GlobalPositionStrategy()
            }
        );

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.updated))
            .subscribe(() => this.edit.emit());
    }
}
