<div class="tw-h-full tw-w-screen tw-overflow-y-auto tw-py-7.5 sm:tw-px-5 sm:tw-scrollbar-gutter-stable lg:tw-px-8 2xl:tw-w-[calc(100vw-theme(space.64))] 2xl:tw-py-12.5">
    <chroma-dialog-header
        [title]="data.mode === 'edit' ? 'Artikel bearbeiten' : 'Neuen Artikel erstellen'"
        [largeHeader]="true"
        (closeDialog)="closeDialog()" />
    <form [formGroup]="composeArticleForm">
        <div class="tw-gap-y tw-flex tw-flex-col tw-gap-y-7.5 2xl:tw-gap-y-10">
            <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <div class="tw-flex tw-flex-col tw-gap-y-10">
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Kategorie</div>
                        <div class="tw-grid tw-gap-7.5 md:tw-grid-cols-2 lg:tw-flex">
                            <div class="tw-col-span-1 tw-flex lg:tw-w-[372px]">
                                <chroma-form-field>
                                    <chroma-select formControlName="categoryId">
                                        @for (group of categoryGroups$ | async; track $index) {
                                            <chroma-optgroup [label]="group.label">
                                                @for (item of group.navigationItems; track item.categoryId) {
                                                    <chroma-option [value]="item.categoryId">{{ item.label }}</chroma-option>
                                                }
                                            </chroma-optgroup>
                                        }
                                    </chroma-select>
                                </chroma-form-field>
                            </div>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Titel</div>
                        <div class="tw-flex tw-w-full">
                            <chroma-form-field>
                                <input
                                    type="text"
                                    chroma-input
                                    formControlName="title"
                                    placeholder="Bitte schreiben Sie ihren Titel hier..." />
                            </chroma-form-field>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Artikelinhalt</div>
                        <app-editor
                            formControlName="body"
                            editorClasses="tw-min-h-60"
                            placeholder="Bitte schreiben Sie Ihren Artikelinhalt hier..." />
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Vorschaubild (optional)</div>
                        <div class="tw-flex tw-flex-col tw-gap-3.75">
                            <span class="tw-text-secondary">
                                Unterstützte Formate: JPG, PNG<br />
                                Maximale Dateigröße: {{ maxCoverFileSize }} MB
                            </span>
                            <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                @if (coverImageIdControl.value) {
                                    <div class="tw-relative tw-aspect-square tw-w-full sm:tw-w-56">
                                        <img
                                            class="tw-h-full tw-w-full tw-object-cover"
                                            [src]="'/derifiles/' + coverImageIdControl.value" />
                                        <div class="tw-absolute tw-top-0 tw-h-full tw-w-full tw-bg-white/40">
                                            <div class="tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-2.5">
                                                <div>
                                                    <button
                                                        type="button"
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="coverImageFile.click()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                                    </button>
                                                    <input
                                                        #coverImageFile
                                                        type="file"
                                                        class="tw-hidden"
                                                        accept="image/png, image/jpeg"
                                                        (change)="uploadCoverImage($event)" />
                                                </div>
                                                <div>
                                                    <button
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="removeCoverImage()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="tw-flex tw-aspect-square tw-w-full tw-items-center tw-justify-center tw-border tw-border-dashed tw-border-secondary sm:tw-w-56">
                                        <button
                                            type="button"
                                            chroma-button
                                            class="tw-cursor-default"
                                            size="icon"
                                            (click)="coverImageFile.click()">
                                            <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                        </button>
                                        <input
                                            #coverImageFile
                                            type="file"
                                            class="tw-hidden"
                                            accept="image/png, image/jpeg"
                                            (change)="uploadCoverImage($event)" />
                                    </div>
                                }
                                @if (coverImageIdControl.hasError('fileSizeExceeded')) {
                                    <span class="tw-text-[0.8125rem] tw-leading-3.25 tw-text-warning">Maximale Dateigröße überschritten.</span>
                                }
                            </div>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-grid tw-gap-7.5 md:tw-grid-cols-2 lg:tw-flex">
                        <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                            <div class="tw-text-xl tw-leading-6">Startdatum (optional)</div>
                            <app-date-input
                                formControlName="publishDate"
                                [min]="minPublishDate" />
                        </div>
                        <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                            <div class="tw-text-xl tw-leading-6">Enddatum (optional)</div>
                            <app-date-input
                                formControlName="expiryDate"
                                [min]="minExpiryDate" />
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Artikelstatus</div>
                        <div class="tw-flex tw-flex-col tw-gap-x-7.5 tw-gap-y-2.5 sm:tw-flex-row">
                            <chroma-checkbox formControlName="mustRead">
                                <ng-container label>Lesepflichtig</ng-container>
                            </chroma-checkbox>
                            <chroma-checkbox formControlName="pinned">
                                <ng-container label>Pinned</ng-container>
                            </chroma-checkbox>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    @if (featureFlags.includes('MAIL_NOTIFICATION') || featureFlags.includes('CONTENT_TEMPLATES')) {
                        <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                            <div class="tw-text-xl tw-leading-6">Weitere Optionen</div>
                            <div class="tw-flex tw-flex-col tw-gap-x-7.5 tw-gap-y-2.5 sm:tw-flex-row">
                                @if (featureFlags.includes('MAIL_NOTIFICATION')) {
                                    <chroma-checkbox formControlName="notifyUsers">
                                        <ng-container label>E-Mail-Benachrichtigung</ng-container>
                                    </chroma-checkbox>
                                }
                                @if (featureFlags.includes('CONTENT_TEMPLATES')) {
                                    <chroma-checkbox formControlName="storeCopyAsTemplate">
                                        <ng-container label>Als Vorlage speichern</ng-container>
                                    </chroma-checkbox>
                                }
                            </div>
                        </div>
                    }
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-10">
                        <span class="tw-text-secondary">Maximale Dateigröße: {{ maxAttachmentFileSize }} MB</span>
                        @if (attachmentsControl.value.length) {
                            <div class="tw-flex tw-flex-wrap tw-gap-5">
                                @for (attachment of attachmentsControl.value; track attachment.fileId) {
                                    <span class="tw-w-asset-button tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="material-symbols-rounded tw-text-asset-icon">attach_file</span>
                                            <span class="tw-inline-flex tw-min-w-0 tw-grow tw-flex-col">
                                                <span class="tw-inline-flex tw-text-sm tw-font-bold"
                                                    ><span class="tw-line-clamp-1 tw-break-all">{{ attachment.fileName.split('.')[0] }}</span>
                                                    <span>.{{ attachment.fileName.split('.')[1] }}</span>
                                                </span>
                                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">{{ attachment.size | filesize }}</span>
                                            </span>
                                            <button
                                                chroma-button
                                                class="tw-cursor-default"
                                                size="icon"
                                                appearance="text"
                                                (click)="removeAttachment(attachment.fileId)">
                                                <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                            </button>
                                        </span>
                                    </span>
                                }
                            </div>
                        }
                        <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                            <div>
                                <button
                                    type="button"
                                    chroma-button
                                    class="tw-cursor-default"
                                    appearance="outline"
                                    (click)="attachmentFile.click()">
                                    <span
                                        class="material-symbols-rounded tw-text-xl tw-leading-none"
                                        icon-left
                                        >attach_file</span
                                    >
                                    <span>Anhang hinzufügen</span>
                                </button>
                                <input
                                    #attachmentFile
                                    type="file"
                                    class="tw-hidden"
                                    (change)="uploadAttachment($event)" />
                            </div>
                            @if (attachmentsControl.hasError('fileSizeExceeded')) {
                                <span class="tw-text-[0.8125rem] tw-leading-3.25 tw-text-warning">Maximale Dateigröße überschritten.</span>
                            }
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-10">
                        @if (productsControl.value.length) {
                            <div class="tw-flex tw-flex-wrap tw-gap-5">
                                @for (product of productsControl.value; track product.dq) {
                                    <span class="tw-w-asset-button tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="material-symbols-rounded tw-text-asset-icon">show_chart</span>
                                            <span class="tw-inline-flex tw-min-w-0 tw-flex-grow tw-flex-col">
                                                <span class="tw-line-clamp-1 tw-break-all tw-text-sm tw-font-bold">{{ product.name }}</span>
                                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">(ISIN) {{ product.isin }}</span>
                                            </span>
                                            <button
                                                chroma-button
                                                class="tw-cursor-default"
                                                size="icon"
                                                appearance="text"
                                                (click)="removeProduct(product.dq)">
                                                <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                            </button>
                                        </span>
                                    </span>
                                }
                            </div>
                        }

                        <div>
                            <button
                                type="button"
                                chroma-button
                                class="tw-cursor-default"
                                appearance="outline"
                                [active]="isProductSelectionOpen"
                                cdkOverlayOrigin
                                #productSelectionTrigger="cdkOverlayOrigin"
                                [class.tw-pointer-events-none]="isProductSelectionOpen"
                                (click)="isProductSelectionOpen = !isProductSelectionOpen">
                                <span
                                    class="material-symbols-rounded tw-text-xl tw-leading-none"
                                    icon-left
                                    >query_stats</span
                                >
                                <span>Finanzprodukt verlinken</span>
                            </button>
                            <ng-template
                                cdkConnectedOverlay
                                [cdkConnectedOverlayOrigin]="productSelectionTrigger"
                                [cdkConnectedOverlayOpen]="isProductSelectionOpen"
                                [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' }]"
                                (overlayOutsideClick)="isProductSelectionOpen = false">
                                <div class="tw-mb-2.5 tw-max-h-[330px] tw-w-[400px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                                    <div class="tw-flex tw-h-full tw-flex-col tw-gap-y-5">
                                        @if ((solrProducts$ | async).length) {
                                            <div class="tw-overflow-y-scroll tw-pr-4">
                                                <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                                    @for (product of solrProducts$ | async; track $index) {
                                                        <span class="tw-inline-flex tw-w-full tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                                            <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                                                <span class="material-symbols-rounded tw-text-asset-icon">show_chart</span>
                                                                <span class="tw-inline-flex tw-min-w-0 tw-flex-grow tw-flex-col">
                                                                    <span class="tw-line-clamp-1 tw-break-all tw-text-sm tw-font-bold">{{ product.name }}</span>
                                                                    <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">(ISIN) {{ product.isin }}</span>
                                                                </span>
                                                                <button
                                                                    chroma-button
                                                                    class="tw-cursor-default"
                                                                    size="icon"
                                                                    appearance="text"
                                                                    (click)="addProduct({ dq: product.id, isin: product.isin, name: product.name })">
                                                                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">add_circle</span>
                                                                </button>
                                                            </span>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div class="tw-flex tw-w-full">
                                            <chroma-form-field>
                                                <input
                                                    type="text"
                                                    chroma-input
                                                    [formControl]="solrSearchControl"
                                                    placeholder="ISIN oder WKN eingeben" />
                                            </chroma-form-field>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-px-5 sm:tw-px-0">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    (click)="onUpsertArticle()">
                    {{ data.mode === 'edit' ? 'Artikel aktualisieren' : 'Artikel veröffentlichen' }}
                </button>
            </div>
        </div>
    </form>
</div>
