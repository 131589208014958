import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationBarsComponent } from '../../shared/components/navigation-bars/navigation-bars.component';
import * as UISelectors from '../../core/ui/store/ui.selectors';
import { NavigationActions } from '../../core/navigation/store/navigation.actions';
import * as NavigationSelectors from '../../core/navigation/store/navigation.selectors';
import { RouterLink, RouterOutlet } from '@angular/router';
import * as RouterSelectors from '../../shared/store/router/router.selectors';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UIActions } from '../../core/ui/store/ui.actions';

@Component({
    selector: 'app-feed-view',
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        RouterLink,
        RouterOutlet,
        NavigationBarsComponent,
        InfiniteScrollModule
    ],
    templateUrl: './feed-view.component.html'
})
export class FeedViewComponent implements OnInit {
    isSidebarOpen$ = this.store.select(UISelectors.selectIsSidebarOpen);
    infiniteLoading$ = this.store.select(UISelectors.selectInfiniteLoading);
    navigationGroups$ = this.store.select(NavigationSelectors.selectNavigationGroups);
    categoryId$ = this.store.select(RouterSelectors.selectRouteParam('categoryId'));

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(NavigationActions.loadGroups());
    }

    toggleNavigationGroup(groupIndex: number): void {
        this.store.dispatch(NavigationActions.toogleGroup({ groupIndex }));
    }

    onScroll(): void {
        this.store.dispatch(UIActions.scrolledToEnd());
    }
}
