<div class="tw-h-full tw-w-screen tw-overflow-y-auto tw-py-7.5 sm:tw-px-5 sm:tw-scrollbar-gutter-stable lg:tw-px-8 2xl:tw-w-[calc(100vw-theme(space.64))] 2xl:tw-py-12.5">
    <chroma-dialog-header
        [title]="data.mode === 'edit' ? 'Vorlage bearbeiten' : 'Neue Vorlage erstellen'"
        [largeHeader]="true"
        (closeDialog)="closeDialog()" />
    <form [formGroup]="composeTemplateForm">
        <div class="tw-gap-y tw-flex tw-flex-col tw-gap-y-7.5 2xl:tw-gap-y-10">
            <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <div class="tw-flex tw-flex-col tw-gap-y-10">
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Titel</div>
                        <div class="tw-flex tw-w-full">
                            <chroma-form-field>
                                <input
                                    type="text"
                                    chroma-input
                                    formControlName="title"
                                    placeholder="Bitte schreiben Sie ihren Titel hier..." />
                            </chroma-form-field>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Artikelinhalt</div>
                        <app-editor
                            formControlName="body"
                            editorClasses="tw-min-h-60"
                            placeholder="Bitte schreiben Sie Ihren Artikelinhalt hier..." />
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Vorschaubild (optional)</div>
                        <div class="tw-flex tw-flex-col tw-gap-3.75">
                            <span class="tw-text-secondary">
                                Unterstützte Formate: JPG, PNG<br />
                                Maximale Dateigröße: {{ maxCoverFileSize }} MB
                            </span>
                            <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                @if (coverImageIdControl.value) {
                                    <div class="tw-relative tw-aspect-square tw-w-full sm:tw-w-56">
                                        <img
                                            class="tw-h-full tw-w-full tw-object-cover"
                                            [src]="'/derifiles/' + coverImageIdControl.value" />
                                        <div class="tw-absolute tw-top-0 tw-h-full tw-w-full tw-bg-white/40">
                                            <div class="tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-2.5">
                                                <div>
                                                    <button
                                                        type="button"
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="coverImageFile.click()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                                    </button>
                                                    <input
                                                        #coverImageFile
                                                        type="file"
                                                        class="tw-hidden"
                                                        accept="image/png, image/jpeg"
                                                        (change)="uploadCoverImage($event)" />
                                                </div>
                                                <div>
                                                    <button
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="removeCoverImage()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="tw-flex tw-aspect-square tw-w-full tw-items-center tw-justify-center tw-border tw-border-dashed tw-border-secondary sm:tw-w-56">
                                        <button
                                            type="button"
                                            chroma-button
                                            class="tw-cursor-default"
                                            size="icon"
                                            (click)="coverImageFile.click()">
                                            <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                        </button>
                                        <input
                                            #coverImageFile
                                            type="file"
                                            class="tw-hidden"
                                            accept="image/png, image/jpeg"
                                            (change)="uploadCoverImage($event)" />
                                    </div>
                                }
                                @if (coverImageIdControl.hasError('fileSizeExceeded')) {
                                    <span class="tw-text-[0.8125rem] tw-leading-3.25 tw-text-warning">Maximale Dateigröße überschritten.</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-px-5 sm:tw-px-0">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    (click)="onUpsertTemplate()">
                    {{ data.mode === 'edit' ? 'Vorlage aktualisieren' : 'Vorlage erstellen' }}
                </button>
            </div>
        </div>
    </form>
</div>
