@if (article(); as article) {
    <div class="tw-inline-flex tw-gap-x-5">
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="like.emit(article.meta.liked)">
            @if (article.meta.liked) {
                <span class="material-symbols-rounded tw-text-xl tw-leading-none tw-filled-symbol">favorite</span>
            } @else {
                <span class="material-symbols-rounded tw-text-xl tw-leading-none">favorite</span>
            }
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="clipboard.emit()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">share</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="bookmark.emit(article.meta.bookmarked)">
            @if (article.meta.bookmarked) {
                <span class="material-symbols-rounded tw-text-xl tw-leading-none">bookmark_remove</span>
            } @else {
                <span class="material-symbols-rounded tw-text-xl tw-leading-none">bookmark_add</span>
            }
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="print.emit()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">print</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="deleteArticle()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="editArticle()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">edit</span>
        </button>
    </div>
}
