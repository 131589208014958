import { Component, Input } from '@angular/core';

@Component({
    selector: 'chroma-optgroup',
    templateUrl: 'optgroup.html',
    standalone: true
})
export class ChromaOptgroup {
    @Input() label: string;
}
