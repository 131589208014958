import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState } from './navigation.state';
import { selectRouteParam } from '../../../shared/store/router/router.selectors';
import { ContentPermission } from '../types/navigations.type';

export const selectNavigation = createFeatureSelector<NavigationState>('navigation');

export const selectNavigationGroups = createSelector(
    selectNavigation,
    selectRouteParam('categoryId'),
    (state, categoryId) =>
        state.groups.map(group => {
            const navigationItems = group.navigationItems.map(item => ({
                ...item,
                active: item.categoryId === categoryId
            }));

            return {
                ...group,
                active: navigationItems.some(item => item.active),
                navigationItems
            };
        })
);

export const selectNavigationItems = createSelector(selectNavigation, state =>
    state.groups.flatMap(group => group.navigationItems)
);

export const selectCategoryGroups = createSelector(selectNavigationGroups, groups =>
    groups
        .map(group => {
            const composePermissions: Array<ContentPermission> = ['CREATE', 'MODERATE'];
            const filteredItems = group.navigationItems.filter(item =>
                composePermissions.includes(item.meta.contentPermissions)
            );

            return filteredItems.length > 0 ? { ...group, navigationItems: filteredItems } : null;
        })
        .filter(Boolean)
);

export const canCompose = createSelector(
    selectCategoryGroups,
    state => state.flatMap(group => group.navigationItems).length > 0
);
