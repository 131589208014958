import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import moment from 'moment';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import 'moment/locale/de';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgClass, MatDatepickerModule, FormsModule, ReactiveFormsModule]
})
export class DateInputComponent implements OnInit, ControlValueAccessor, Validator {
    customHeader = CalendarHeaderComponent;

    min = input();

    @HostBinding('class.date-input') dateInputClass = true;
    @Input() placeholder: string;

    @Input() set excludeDays(days: string[]) {
        this._excludeDays = days.map(day => moment(day));
    }

    _excludeDays: moment.Moment[] = [];

    @Input() format: string;
    @Input() dateFilter: (date: moment.Moment) => boolean;
    @Input() removeOffset = false;

    @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<moment.Moment>;
    @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

    @Input() disableChangeEventOnInit = false;

    @HostBinding('class.date-input--full-width')
    @Input()
    fullWidth = false;

    lastValidValue = null;
    datepickerInputValue: moment.Moment;
    dateControl = new UntypedFormControl(moment());
    focused = false;

    constructor(private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (!this.disableChangeEventOnInit) {
            setTimeout(() => {
                this.onInputChanged();
            }, 0);
        }

        this.dateControl.valueChanges.subscribe(value => {
            this.onInputChanged();
        });
    }

    onChange() {}
    onTouched() {}

    writeValue(isoDate: string): void {
        let parsedDate = moment(isoDate, this.format ? this.format : undefined);

        if (parsedDate && !parsedDate.isValid()) {
            parsedDate = null;
        }

        this.dateControl.setValue(parsedDate, { emitEvent: false });
        this.lastValidValue = parsedDate;
    }

    convertValueToString(value: moment.Moment) {
        if (this.format) {
            return value.format(this.format);
        } else {
            return value.toISOString(!this.removeOffset);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = () => {
            const currentValue = this.dateControl.value;
            this.lastValidValue = currentValue;

            if (fn) {
                fn(currentValue ? this.convertValueToString(currentValue) : null);
            }
        };
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onInputChanged() {
        const validationResult = this.validate(null);

        if (!validationResult) {
            this.onChange();
        } else {
            this.dateControl.setValue(this.lastValidValue);
        }
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.dateControl) {
            if (this.dateControl.valid && !this.isValueExcluded()) {
                return null;
            } else {
                return {
                    dateInput: 'Invalid date specified.'
                };
            }
        } else {
            return null;
        }
    }

    onFocus() {
        console.log('focus')
    }

    onOpenDatepicker() {
        this.datepicker.open();
    }

    onDatepickerOpened(event) {
        setTimeout(() => {
            this.input.nativeElement.focus();
        }, 0);
    }

    onResetClicked(): void {
        this.dateControl.setValue(null);
        this.datepicker.close();
    }

    filter = (date: moment.Moment) => {
        let result = true;

        if (this?._excludeDays) {
            result = !this._excludeDays.reduce((excluded, day) => {
                return excluded || date.isSame(day, 'day');
            }, false);
        }

        if (this.dateFilter && result) {
            result = this.dateFilter(date);
        }

        return result;
    };

    private isValueExcluded(): boolean {
        const currentValue = this.dateControl.value;

        return this._excludeDays.reduce((excluded, day) => {
            return excluded || currentValue.isSame(day, 'day');
        }, false);
    }
}
