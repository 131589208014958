import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UIState } from './ui.state';

export const selectUI = createFeatureSelector<UIState>('ui');

export const selectIsSidebarOpen = createSelector(
    selectUI,
    (state: UIState) => state.isSidebarOpen
);

export const selectIsProfileMenuOpen = createSelector(
    selectUI,
    (state: UIState) => state.isProfileMenuOpen
);

export const selectInfiniteLoading = createSelector(
    selectUI,
    (state: UIState) => state.inifiniteLoading
);

export const selectScrolledToEnd = createSelector(
    selectUI,
    (state: UIState) => state.scrolledToEnd
);

export const selectReadMode = createSelector(
    selectUI,
    (state: UIState) => state.readMode
);
