import { SelectionModel } from "@angular/cdk/collections";
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition, OverlayModule } from "@angular/cdk/overlay";
import { AfterContentInit, Component, ContentChild, ContentChildren, DoCheck, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Optional, Output, QueryList, Self, ViewChild } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from "@angular/forms";
import { defer, merge, Observable, Subject } from "rxjs";
import { startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { ChromaOption, ChromaOptionSelectionChange, _getOptionScrollPosition } from "../core/option/option";
import { ChromaFormField, CHROMA_FORM_FIELD } from "../form-field/form-field";
import { ChromaFormFieldControl } from "../form-field/form-field-control";
import { CHROMA_OPTION_PARENT_COMPONENT } from "../core/option/option.parent";
import { NgClass } from "@angular/common";
import { ChromaSelectSearch } from "./select-search";
import { ActiveDescendantKeyManager } from "@angular/cdk/a11y";
import { ErrorStateMatcher } from '../core/error/error-options';

@Component({
    selector: 'chroma-select',
    templateUrl: './select.html',
    standalone: true,
    host: {
        'class': 'tw-w-full tw-outline-none',
        '[attr.tabindex]': '0',
        '(keydown)': '_handleKeydown($event)',
        '(focus)': '_onFocus()',
        '(blur)': '_onBlur()'
    },
    providers: [
        {provide: ChromaFormFieldControl, useExisting: ChromaSelect},
        {provide: CHROMA_OPTION_PARENT_COMPONENT, useExisting: ChromaSelect}
    ],
    imports: [NgClass, OverlayModule]
})
export class ChromaSelect implements AfterContentInit, DoCheck, OnInit, OnDestroy, ControlValueAccessor, ChromaFormFieldControl {

    @ContentChild(ChromaSelectSearch, { read: ElementRef, static: true }) search: ElementRef;

    @ContentChildren(ChromaOption, {descendants: true}) options: QueryList<ChromaOption>;

    _positions: Array<ConnectedPosition> = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            panelClass: 'tw-mt-2.5'
        },
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            panelClass: 'tw-mt-2.5'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            panelClass: 'tw-mb-2.5'
        },
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            panelClass: 'tw-mb-2.5'
        }
    ];

    _scrollOptionIntoView(index: number): void {
        const option = this.options.toArray()[index];

        if (option) {
            const panel: HTMLElement = this.panel.nativeElement;
            const element = option._getHostElement();
            const optionScrollPosition = _getOptionScrollPosition(
                element.offsetTop,
                element.offsetHeight,
                panel.scrollTop,
                panel.offsetHeight
            );

            if (index === 0) {
                panel.scrollTop = 0;
            } else if (this.options.length - 1 > index) {
                panel.scrollTop = optionScrollPosition;
            } else {
                panel.scrollTop = optionScrollPosition + 6;
            }
        }
    }

    private _positioningSettled(): void {
        this._scrollOptionIntoView(this._keyManager.activeItemIndex || 0);
    }

    _hasSearch = false;

    private _value: any;

    @Output() readonly openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly optionSelectionChanges: Observable<ChromaOptionSelectionChange> = defer(() => {
        const options = this.options;
    
        if (options) {
          return options.changes.pipe(
            startWith(options),
            switchMap(() => merge(...options.map(option => option.selectionChange)))
          );
        }
    
        return this._ngZone.onStable.pipe(
          take(1),
          switchMap(() => this.optionSelectionChanges)
        );
    }) as Observable<ChromaOptionSelectionChange>;

    @Output() readonly selectionChange = new EventEmitter<any>();

    constructor(
        protected _ngZone: NgZone,
        @Optional() private _parentForm: NgForm,
            @Optional() private _parentFormGroup: FormGroupDirective,
        private _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Inject(CHROMA_FORM_FIELD) protected _parentFormField: ChromaFormField,
        @Self() @Optional() public ngControl: NgControl
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _panelOpen = false;

    private _compareWith = (o1: any, o2: any) => o1 === o2;

    protected readonly _destroy = new Subject<void>();

    _selectionModel: SelectionModel<ChromaOption>;

    _keyManager: ActiveDescendantKeyManager<ChromaOption>;

    _preferredOverlayOrigin: CdkOverlayOrigin | ElementRef | undefined;

    _overlayWidth: string | number;

    _onChange: (value: any) => void = () => {};

    get focused(): boolean {
        return this._focused || this._panelOpen;
    }
    private _focused = false;

    @ViewChild('panel') panel: ElementRef;

    @ViewChild(CdkConnectedOverlay)
    protected _overlayDir: CdkConnectedOverlay;

    @Input()
    get multiple(): boolean {
        return this._multiple;
    }
    set multiple(value: boolean) {
        this._multiple = value;
    }
    private _multiple = false;

    @Input()
    get compareWith() {
        return this._compareWith;
    }
    set compareWith(fn: (o1: any, o2: any) => boolean) {
        this._compareWith = fn;
    }
    
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: any) {
        this._disabled = value;
    }
    private _disabled = false;

    errorState = false;

    pointer = true;

    ngOnInit(): void {
        this._selectionModel = new SelectionModel(this.multiple);
    }

    ngAfterContentInit(): void {
        if (!this._hasSearch) {
            this._initKeyManager();
        }

        this._selectionModel.changed.pipe(
            takeUntil(this._destroy)
        ).subscribe(event => {
            event.added.forEach(option => option.select());
            event.removed.forEach(option => option.deselect());
        });

        this.options.changes.pipe(
            startWith(null),
            takeUntil(this._destroy)
        ).subscribe(() => {
            this._resetOptions();
            this._initializeSelection();
        });

        if (this.search?.nativeElement) {
            this._hasSearch = true;
        }
    }

    ngDoCheck(): void {
        const ngControl = this.ngControl;
        if (ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this._keyManager?.destroy();
        this._destroy.next();
        this._destroy.complete();
    }

    toggle(): void {
        this.panelOpen ? this.close() : this.open();
        this.openedChange.next(this.panelOpen);
    }

    open(): void {
        if (this._parentFormField) {
            this._preferredOverlayOrigin = this._parentFormField.getConnectedOverlayOrigin();
        }
        
        this._overlayWidth = (this._preferredOverlayOrigin as ElementRef).nativeElement.getBoundingClientRect().width;

        if (this._canOpen()) {
            this._panelOpen = true;
            this.openedChange.next(true);
            this._highlightCorrectOption();
        }
    }

    close(): void {
        if (this._panelOpen) {
            this._panelOpen = false;
            this.openedChange.next(false);
        }
    }

    writeValue(value: any): void {
        this._assignValue(value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(): void { }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    get panelOpen(): boolean {
        return this._panelOpen;
    }

    get selected(): ChromaOption | Array<ChromaOption> {
        return this.multiple ? this._selectionModel?.selected || [] : this._selectionModel?.selected[0];
    }

    get triggerValue(): string {
        if (this._multiple) {
            const selectedOptions = this._selectionModel.selected.map(option => option.viewValue);

            return selectedOptions.join(', ');
        }

        return this._selectionModel.selected[0].viewValue;
    }

    updateErrorState() {
        const oldState = this.errorState;
        const control = this.ngControl ? (this.ngControl.control as AbstractControl) : null;
        const parent = this._parentFormGroup || this._parentForm;
        const newState = this._defaultErrorStateMatcher.isErrorState(control, parent);

        if (newState !== oldState) {
            this.errorState = newState;
        }
      }

    _onAttached(): void {
        this._overlayDir.positionChange.pipe(take(1)).subscribe(() =>
          this._positioningSettled()
        );
    }

    get empty(): boolean {
        return !this._selectionModel || this._selectionModel.isEmpty();
    }

    _handleKeydown(event: KeyboardEvent): void {
        if (!this.disabled) {
            this.panelOpen ? this._handleOpenKeydown(event) : this._handleClosedKeydown(event);
        }
    }

    private _handleOpenKeydown(event: KeyboardEvent): void {
        const manager = this._keyManager;
        const key = event.key;

        if ((key === 'Enter' || key === ' ') && manager.activeItem) {
            event.preventDefault();
            manager.activeItem._selectViaInteraction();
        } else {
            this._keyManager.onKeydown(event);
        } 
    }

    private _handleClosedKeydown(event: KeyboardEvent): void {
        const isOpenKey = ['Enter', ' '].includes(event.key);

        if (isOpenKey) {
            event.preventDefault();
            this.open();
        }
    }

    _onFocus(): void {
        if (!this.disabled) {
            this._focused = true;
        }
    }

    _onBlur(): void {
        this._focused = false;
    }

    private _initializeSelection(): void {
        Promise.resolve().then(() => {
            if (this.ngControl) {
                this._value = this.ngControl.value;
            }

            this._setSelectionByValue(this._value);
        });
    }

    private _setSelectionByValue(value: any | any[]): void {
        this._selectionModel.clear();
        
        if (this.multiple && value) {
            value.forEach((currentValue: any) => this._selectOptionByValue(currentValue));
            this._sortValues();
        } else {
            this._selectOptionByValue(value);
        }
    }

    private _selectOptionByValue(value: any): void {
        const correspondingOption = this.options.find((option: ChromaOption) => {
            if (this._selectionModel.isSelected(option)) {
                return false;
            }

            return option.value != null && this._compareWith(option.value, value);
        });

        if (correspondingOption) {
            this._selectionModel.select(correspondingOption);
        }
    }

    private _assignValue(newValue: any | any[]): void {
        if (newValue !== this._value) {
            if (this.options) {
                this._setSelectionByValue(newValue);
            }

            this._value = newValue;
        }
    }

    protected _canOpen(): boolean {
        return !this._panelOpen && !this.disabled && this.options?.length > 0;
    }

    private _initKeyManager(): void {
        this._keyManager = new ActiveDescendantKeyManager<ChromaOption>(this.options);

        this._keyManager.tabOut.subscribe(() => {
            if (this.panelOpen) {
                if (!this.multiple && this._keyManager.activeItem) {
                    this._keyManager.activeItem._selectViaInteraction();
                }

                this.close();
            }
        });

        this._keyManager.change.subscribe(() => {
            if (this.panelOpen && this.panel) {
                this._scrollOptionIntoView(this._keyManager.activeItemIndex || 0)
            }
        });
    }

    private _resetOptions(): void {
        const changedOrDestroyed = merge(this.options.changes, this._destroy);

        this.optionSelectionChanges.pipe(
            takeUntil(changedOrDestroyed)
        ).subscribe(event => {
            this._onSelect(event.source);

            if (event.isUserInput && !this.multiple && this._panelOpen) {
                this.close();
            }
        });
    }

    private _onSelect(option: ChromaOption): void {
        const wasSelected = this._selectionModel.isSelected(option);

        if (wasSelected !== option.selected) {
            option.selected
                ? this._selectionModel.select(option)
                : this._selectionModel.deselect(option);
        }

        if (wasSelected !== this._selectionModel.isSelected(option)) {
            this._propagateChanges();
        }

        if (this.multiple) {
            this._sortValues();
        }
    }

    private _propagateChanges(): void {
        let valueToEmit: any = null;

        if (this.multiple) {
            valueToEmit = (this.selected as Array<ChromaOption>).map(option => option.value);
        } else {
            valueToEmit = (this.selected as ChromaOption).value;
        }

        this._value = valueToEmit;
        this._onChange(valueToEmit);
        this.selectionChange.emit(valueToEmit);
    }

    private _highlightCorrectOption(): void {
        if (this._keyManager && !this._hasSearch) {
            if (this.empty) {
                this._keyManager.setActiveItem(0);
            } else {
                this._keyManager.setActiveItem(this._selectionModel.selected[0]);
            }
        }
    }

    private _sortValues(): void {
        const options = this.options.toArray();

        this._selectionModel.sort((a, b) =>
            options.indexOf(a) - options.indexOf(b)
        );
    }

    onContainerClick() {
        this.open();
    }
}
