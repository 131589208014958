import { Component, OnInit } from '@angular/core';
import { TemplatesViewStore } from './templates-view.store';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ButtonComponent, ChromaDialog, ChromaLoading } from 'chroma-ui';
import { Template } from '../../../shared/types/contents.type';
import { ComposeArticleDialogComponent } from '../../../shared/components/dialogs/compose-article-dialog/compose-article-dialog.component';
import { ComposeArticleDialogData } from '../../../shared/components/dialogs/compose-article-dialog/compose-article-dialog.type';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.type';
import { filter, switchMap } from 'rxjs';
import { ContentsApi } from '../../../shared/api/contents.api';
import { ComposeTemplateDialogComponent } from '../../../shared/components/dialogs/compose-template-dialog/compose-template-dialog.component';
import { ComposeTemplateDialogData } from '../../../shared/components/dialogs/compose-template-dialog/compose-template-dialog.type';

@Component({
    selector: 'app-templates-view',
    standalone: true,
    imports: [AsyncPipe, DatePipe, ButtonComponent, ChromaLoading],
    providers: [TemplatesViewStore],
    templateUrl: './templates-view.component.html'
})
export class TemplatesViewComponent implements OnInit {
    templates$ = this.templatesViewStore.templates$;
    templatesLoading$ = this.templatesViewStore.templatesLoading$;

    constructor(
        private readonly templatesViewStore: TemplatesViewStore,
        private dialog: ChromaDialog,
        private contentsApi: ContentsApi
    ) {}

    ngOnInit(): void {
        this.templatesViewStore.loadTemplates();
    }

    onCreateTemplate(): void {
        const dialogRef = this.dialog.open<
            ComposeTemplateDialogComponent,
            ComposeTemplateDialogData
        >(ComposeTemplateDialogComponent, {
            data: {
                mode: 'create'
            },
            defaultContainer: false,
            positionStrategy: new GlobalPositionStrategy()
        });

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.created))
            .subscribe(() => this.templatesViewStore.loadTemplates());
    }

    onEditTemplate(template: Template): void {
        const dialogRef = this.dialog.open<
            ComposeTemplateDialogComponent,
            ComposeTemplateDialogData
        >(ComposeTemplateDialogComponent, {
            data: {
                mode: 'edit',
                template
            },
            defaultContainer: false,
            positionStrategy: new GlobalPositionStrategy()
        });

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.updated))
            .subscribe(() => this.templatesViewStore.loadTemplates());
    }

    onDeleteTemplate(template: Template): void {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
            ConfirmDialogComponent,
            {
                data: {
                    title: 'Vorlage löschen',
                    message: `Sind Sie sich sicher, dass Sie die Vorlage '${template.title}' endgültig löschen wollen?`,
                    type: 'delete'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => result?.confirmed),
                switchMap(() => this.contentsApi.deleteTemplate(template.id))
            )
            .subscribe(() => this.templatesViewStore.loadTemplates());
    }

    startComposing(template: Template): void {
        this.dialog.open<ComposeArticleDialogComponent, ComposeArticleDialogData>(
            ComposeArticleDialogComponent,
            {
                data: {
                    mode: 'template',
                    article: template
                },
                defaultContainer: false,
                positionStrategy: new GlobalPositionStrategy()
            }
        );
    }
}
