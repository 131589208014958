import { HttpClient } from '@angular/common/http';
import { BaseApi } from './base.api';
import { environment } from '@shared/environments/environment';

export abstract class MercuryApi extends BaseApi {
    constructor(resourceName: string, http: HttpClient) {
        const environmentPath = `api/environment/${environment.id}`;
        const basePath = resourceName ? `${environmentPath}/${resourceName}` : `${environmentPath}`;

        super(basePath, http);
    }
}
