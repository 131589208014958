import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UIActions } from './ui.actions';
import { DOCUMENT } from '@angular/common';
import { debounceTime, filter, fromEvent, map, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as UISelectors from './ui.selectors';
import * as NgrxRouterActions from '@ngrx/router-store';
import * as RouterSelectors from '../../../shared/store/router/router.selectors';
import { READ_MODE_KEY, ReadMode } from './ui.type';

@Injectable()
export class UIEffects {
    toggleBodyOverflow$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UIActions.toggleSidebar, UIActions.closeSidebar),
                concatLatestFrom(() => this.store.select(UISelectors.selectIsSidebarOpen)),
                tap(([, isOpen]) =>
                    isOpen
                        ? this.document.body.classList.add('tw-overflow-hidden')
                        : this.document.body.classList.remove('tw-overflow-hidden')
                )
            ),
        {
            dispatch: false
        }
    );

    closeSidebar$ = createEffect(() =>
        fromEvent(window, 'resize').pipe(
            debounceTime(300),
            filter(() => window.innerWidth > 1024),
            map(() => UIActions.closeSidebar())
        )
    );

    activateInfiniteLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NgrxRouterActions.routerNavigationAction),
            concatLatestFrom(() => this.store.select(RouterSelectors.selectRouteData)),
            map(([, data]) => UIActions.setInfiniteLoading({ loading: data.infiniteLoading ? true : false }))
        )
    );

    changeReadMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UIActions.changeReadMode),
            concatLatestFrom(() => this.store.select(UISelectors.selectReadMode)),
            map(([, currentReadMode]) =>
                currentReadMode === ReadMode.View ? ReadMode.Explore : ReadMode.View
            ),
            tap(readMode => localStorage.setItem(READ_MODE_KEY, readMode)),
            map(readMode => UIActions.setReadMode({ readMode }))
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        @Inject(DOCUMENT) private document: Document
    ) {}
}
