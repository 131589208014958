import { Component, input } from '@angular/core';

@Component({
  selector: 'chroma-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class ChromaLoading {

  type = input<'spinner' | 'dot'>('spinner')

}
