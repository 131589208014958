import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { concatMap, Observable, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../../shared/api/contents.api';
import { Intro } from '../../../../shared/types/contents.type';
import { concatLatestFrom } from '@ngrx/effects';

const ARTICLE_LOADING_LIMIT = 5;

type Loading = 'inactive' | 'initial' | 'next';

interface ArticleViewState {
    categoryId?: string;
    sessionId?: string;
    loading: Loading;
    offset: number;
    endReached: boolean;
    scrolledToEnd: number;
    data: Array<Intro>;
    scrollbarActive: boolean;
    articleLoaded: boolean;
}

@Injectable()
export class ArticleViewStore extends ComponentStore<ArticleViewState> {
    constructor(private contentsApi: ContentsApi) {
        super({
            loading: 'inactive',
            offset: 0,
            endReached: false,
            scrolledToEnd: 0,
            data: [],
            scrollbarActive: false,
            articleLoaded: false
        });
    }

    readonly createSession = this.effect(
        (
            params$: Observable<{
                categoryId: string;
            }>
        ) =>
            params$.pipe(
                tap(() => this.setLoading('initial')),
                switchMap(({ categoryId }) =>
                    this.contentsApi.createSession(categoryId, false, false).pipe(
                        tap(data => this.setSessionId(data.sessionId)),
                        tap(() =>
                            this.loadArticlePreviews({
                                initial: true
                            })
                        )
                    )
                )
            )
    );

    readonly loadNextSet = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setLoading('next')),
            tap(() => this.setScrolledToEnd()),
            tap(() =>
                this.loadArticlePreviews({
                    initial: false
                })
            )
        )
    );

    readonly loadArticlePreviews = this.effect(
        (
            params$: Observable<{
                initial: boolean;
            }>
        ) =>
            params$.pipe(
                concatLatestFrom(() => [this.sessionId$, this.scrolledToEnd$]),
                concatMap(([{ initial }, sessionId, multiplier]) =>
                    this.contentsApi
                        .getArticlePreviews(
                            sessionId,
                            ARTICLE_LOADING_LIMIT,
                            initial ? 0 : multiplier * ARTICLE_LOADING_LIMIT
                        )
                        .pipe(
                            tap(data => this.addArticlePreviews({ data })),
                            tap(() => this.setLoading('inactive'))
                        )
                )
            )
    );

    readonly setLoading = this.updater(
        (state, loading: Loading): ArticleViewState => ({
            ...state,
            loading
        })
    );

    readonly setSessionId = this.updater(
        (state, sessionId: string): ArticleViewState => ({
            ...state,
            sessionId
        })
    );

    readonly addArticlePreviews = this.updater(
        (state, payload: { data: Array<Intro> }): ArticleViewState => ({
            ...state,
            data: [...state.data, ...payload.data],
            endReached: payload.data.length === 0
        })
    );

    readonly setArticleLoaded = this.updater(
        (state): ArticleViewState => ({
            ...state,
            articleLoaded: true
        })
    );

    readonly setScrolledToEnd = this.updater(
        (state): ArticleViewState => ({
            ...state,
            scrolledToEnd: state.scrolledToEnd + 1
        })
    );

    readonly setScrollbarActive = this.updater(
        (state): ArticleViewState => ({
            ...state,
            scrollbarActive: document.body.scrollHeight > window.innerHeight
        })
    );

    readonly sessionId$: Observable<string> = this.select(state => state.sessionId);

    readonly introsLoading$: Observable<Loading> = this.select(state => state.loading);
    readonly introsEndReached$: Observable<boolean> = this.select(state => state.endReached);
    readonly intros$: Observable<Array<Intro>> = this.select(state => state.data);

    readonly articleLoaded$: Observable<boolean> = this.select(state => state.articleLoaded);

    readonly scrolledToEnd$: Observable<number> = this.select(state => state.scrolledToEnd);
    readonly scrollbarActive$: Observable<boolean> = this.select(state => state.scrollbarActive);
}
