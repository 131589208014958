import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { AuthService } from '../../auth/auth.service';
import { filter, switchMap, tap } from 'rxjs';
import { EnvironmentApi } from '../api/environment.api';
import { ENV_CONFIG_KEY } from '../types/environment.type';
import { AuthActions } from '../../auth/store/auth.actions';

@Injectable()
export class EnvironmentEffects {
    private fetchAndStoreConfig$ = () =>
        this.environmentApi
            .getConfig()
            .pipe(
                tap(({ derifinBaseUrl, featureFlags }) =>
                    localStorage.setItem(
                        ENV_CONFIG_KEY,
                        JSON.stringify({ derifinBaseUrl, featureFlags })
                    )
                )
            );

    loadConfigOnInit$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROOT_EFFECTS_INIT),
                filter(
                    () =>
                        this.authService.isRefreshTokenValid() &&
                        !localStorage.getItem(ENV_CONFIG_KEY)
                ),
                switchMap(this.fetchAndStoreConfig$)
            ),
        { dispatch: false }
    );

    loadConfigOnLogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loggedIn),
                filter(() => this.authService.isRefreshTokenValid()),
                switchMap(this.fetchAndStoreConfig$)
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private environmentApi: EnvironmentApi,
        private authService: AuthService
    ) {}
}
