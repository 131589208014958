import { Injectable } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from './auth.actions';
import { catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { AuthApi } from '../api/auth.api';
import { RouterActions } from '../../../shared/store/router/router.actions';
import { AuthService } from '../auth.service';
import { AuthError } from '../types/auth-error.type';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../types/auth.type';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logIn),
            switchMap(action =>
                this.authApi.getTokenUsingCredentials(action.username, action.password).pipe(
                    mergeMap(authToken => [
                        AuthActions.setAuthToken({ authToken }),
                        AuthActions.loggedIn(),
                        RouterActions.navigate({ path: '/feed' })
                    ]),
                    catchError(() =>
                        of(AuthActions.logInFailed({ error: AuthError.InvalidCredentials }))
                    )
                )
            )
        )
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logOut),
            tap(() => {
                localStorage.removeItem(REFRESH_TOKEN_KEY);
                localStorage.removeItem(ACCESS_TOKEN_KEY);
            }),
            map(() =>
                RouterActions.navigate({
                    path: '/login'
                })
            )
        )
    );

    setAuthTokens$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.setAuthToken),
                tap(action => {
                    if (action.authToken.refreshToken) {
                        localStorage.setItem(REFRESH_TOKEN_KEY, action.authToken.refreshToken);
                    }

                    localStorage.setItem(ACCESS_TOKEN_KEY, action.authToken.token);
                })
            ),
        { dispatch: false }
    );

    loadAuthUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT, AuthActions.loggedIn),
            filter(() => this.authService.isRefreshTokenValid()),
            switchMap(() =>
                this.authApi
                    .getAuthenticatedUser()
                    .pipe(map(authUser => AuthActions.setAuthUser(authUser)))
            )
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshToken),
            switchMap(() =>
                this.authApi
                    .getTokenUsingRefreshToken()
                    .pipe(map(authToken => AuthActions.setAuthToken({ authToken })))
            )
        )
    );

    constructor(
        private actions$: Actions,
        private authApi: AuthApi,
        private authService: AuthService
    ) {}
}
