import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cva } from 'cva';

let nextUniqueId = 0;

@Component({
    selector: 'chroma-slide-toggle',
    templateUrl: 'slide-toggle.html',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ChromaSlideToggle,
            multi: true
          }
    ],
    imports: [NgClass]
})
export class ChromaSlideToggle implements ControlValueAccessor {
    buttonStyle = cva(
        [
            'tw-inline-flex',
            'tw-flex-shrink-0',
            'tw-w-9',
            'tw-h-4.5',
            'tw-p-0.5',
            'tw-my-px',
            'tw-bg-white',
            'tw-border',
            'tw-rounded-full',
            'focus:tw-ring',
            'focus:tw-ring-offset-0',
            'focus:tw-ring-primary-focus',
            'disabled:tw-cursor-not-allowed',
            'disabled:tw-opacity-60'
        ],
        {
            variants: {
                checked: {
                    true: 'tw-border-black',
                    false: 'tw-border-control-base'
                }
            }
        }
    );

    labelStyle = cva(['tw-mb-0', 'tw-text-sm', 'tw-leading-5'], {
        variants: {
            disabled: {
                true: 'tw-cursor-not-allowed tw-opacity-60',
                false: 'tw-cursor-pointer'
            }
        }
    });

    private _onChange = (_: any) => {};
    private _onTouched = () => {};

    private _uniqueId: number;
    private _checked: boolean = false;

    get buttonId(): string {
        return `slide-toggle-${this._uniqueId}-button`;
    }

    constructor() {
        this._uniqueId = nextUniqueId++;
    }

    @Input({ transform: booleanAttribute }) disabled: boolean = false;

    @Input({ transform: booleanAttribute })
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: boolean) {
        this._checked = value;
    }

    @Output() readonly change: EventEmitter<any> = new EventEmitter<boolean>();

    _handleClick(): void {
        this.checked = !this.checked;
        this._onChange(this.checked);
        this.change.emit(this.checked);
    }

    writeValue(value: any): void {
        this.checked = !!value;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
