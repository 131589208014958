import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeResourceUrl',
    standalone: true
})
export class SafeResourceUrlPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
