<div class="tw-flex tw-justify-between tw-px-5 sm:tw-px-0">
    <h1
        class="tw-mb-5 tw-text-black dark:tw-text-old-black"
        [ngClass]="{
        'tw-mb-7.5 lg:tw-mb-10 tw-leading-[1.875rem] tw-text-[1.625rem] lg:tw-leading-9 tw-text-3xl': largeHeader,
        'tw-mb-5 tw-text-lg tw-uppercase tw-leading-5.5': !largeHeader
    }">
        {{ title }}
    </h1>
    <button
        type="button"
        tabindex="-1"
        class="tw-relative tw-bottom-[6px] tw-left-[6px] tw-h-8 tw-text-black hover:tw-text-primary-hover-dark dark:tw-text-old-black dark:hover:tw-text-primary-hover-dark"
        (click)="closeDialog.next()"
        (mousedown)="closeDialog.next()">
        <span class="material-symbols-rounded tw-text-3.3xl">close</span>
    </button>
</div>
