<div class="sm:tw-w-[558px]">
    <chroma-dialog-header
        [title]="data.title"
        (closeDialog)="closeDialog()" />

    <div class="tw-flex tw-flex-col tw-gap-y-7.5">
        <p class="tw-m-0 tw-text-black">{{ data.message }}</p>

        <div class="tw-flex tw-justify-end">
            <button
                type="button"
                chroma-button
                class="tw-cursor-default"
                (click)="confirm()">
                @switch (data.type) {
                    @case ('delete') {
                        <span>Löschen</span>
                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                    }
                    @default {
                        <span>Bestätigen</span>
                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">check_circle</span>
                    }
                }
            </button>
        </div>
    </div>
</div>
