import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SolrResultsResponse } from '../types/solr.type';
import { BaseApi } from './base.api';

@Injectable({ providedIn: 'root' })
export class SolrApi extends BaseApi {
    constructor(http: HttpClient) {
        super('', http);
    }

    getResults(term: string): Observable<SolrResultsResponse> {
        return this.get<SolrResultsResponse>(`search/${term}`, { skipOAuth: true }).pipe(
            map(response => response)
        );
    }
}
