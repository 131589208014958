import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ArticlePrintViewStore } from './article-print-view.store';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, from, map, Subject, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../shared/api/contents.api';
import { AsyncPipe } from '@angular/common';
import { ArticleContentComponent } from '../../../shared/components/article/article-content/article-content.component';
import { TipTapExtensionsService } from '../../../shared/services/tiptap-extensions.service';

@Component({
    selector: 'app-article-print-view',
    standalone: true,
    imports: [AsyncPipe, ArticleContentComponent],
    providers: [ArticlePrintViewStore, ContentsApi, TipTapExtensionsService],
    templateUrl: './article-print-view.component.html'
})
export class ArticlePrintViewComponent implements OnInit, AfterViewInit {
    article$ = this.articlePrintViewStore.article$;
    articleLoaded$ = this.articlePrintViewStore.articleLoaded$;

    imageLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private readonly articlePrintViewStore: ArticlePrintViewStore
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(map(params => params.articleId))
            .subscribe(articleId => this.articlePrintViewStore.loadArticle(articleId));
    }

    ngAfterViewInit(): void {
        this.articleLoaded$
            .pipe(
                filter(loaded => loaded),
                switchMap(() => {
                    this.cdr.detectChanges();

                    return combineLatest([
                        this.article$.pipe(map(article => !!article.author.profilePictureId)),
                        this.imageLoaded$,
                        from(document.fonts.ready).pipe(map(() => true))
                    ]).pipe(
                        filter(([imageExists, imageLoaded, fontsReady]) => {
                            return (
                                (imageExists && imageLoaded && fontsReady) ||
                                (!imageExists && fontsReady)
                            );
                        })
                    );
                })
            )
            .subscribe(() => window.print());

        window.onafterprint = () =>
            window.parent.postMessage('onafterprint', window.location.origin);
    }

    onImageLoaded(): void {
        this.imageLoaded$.next(true);
    }
}
