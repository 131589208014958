import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class BaseApi {
    basePath: string;

    constructor(
        protected resourcePath: string,
        protected http: HttpClient
    ) {
        this.basePath = resourcePath
            ? `${window.location.origin}/${resourcePath}`
            : window.location.origin;
    }

    protected get<T>(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        if (endpoint.length > 0) {
            return this.http.get<T>(`${this.basePath}/${endpoint}`, options);
        } else {
            return this.http.get<T>(`${this.basePath}`, options);
        }
    }

    protected post<T>(endpoint: string, body?: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };
        return this.http.post<T>(`${this.basePath}/${endpoint}`, body, options);
    }

    protected patch<T>(endpoint: string, body?: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };
        return this.http.patch<T>(`${this.basePath}/${endpoint}`, body, options);
    }

    protected delete<T>(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        return this.http.delete<T>(`${this.basePath}/${endpoint}`, options);
    }

    private getHeaders(apiOptions: ApiOptions): { [header: string]: string | string[] } {
        const headers = apiOptions.headers || {};

        if (apiOptions.body) {
            headers['Content-Type'] = 'application/json';
        }

        if (apiOptions.basicAuth) {
            headers.Authorization = `Basic ${window.btoa(
                String.fromCharCode(
                    ...new TextEncoder().encode(
                        apiOptions.basicAuth.username + ':' + apiOptions.basicAuth.password
                    )
                )
            )}`;
        }

        if (apiOptions.skipOAuth) {
            headers['X-Skip-OAuth'] = 'true';
        }

        return headers;
    }
}

export interface ApiResponse<D> {
    data: D;
}

export interface ApiOptions {
    token?: string;
    basicAuth?: {
        username: string;
        password: string;
    };
    body?: any;
    headers?: {
        [header: string]: string | string[];
    };
    params?:
        | HttpParams
        | {
              [param: string]: string | string[];
          };
    observe?: any;
    reportProgress?: boolean;
    responseType?: any;
    skipOAuth?: boolean;
}
