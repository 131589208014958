<app-navigation-bars [isAuthView]="true" />

<div
    class="tw-h-screen"
    [ngClass]="{ 'tw-bg-gradient-to-br tw-from-init-bg tw-to-end-bg': (loggingIn$ | async) === false }">
    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
        @if ((loggingIn$ | async) === false) {
            <div class="tw-w-full tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-5 tw-py-7.5 sm:tw-w-[600px] sm:tw-rounded sm:tw-shadow">
                <h1 class="tw-mb-5 tw-text-center tw-text-2.25xl tw-font-normal tw-leading-none tw-text-black sm:tw-text-3xl">Willkommen bei Mercury</h1>
                <div
                    class="tw-flex tw-flex-col tw-gap-y-7.5 sm:tw-items-center"
                    cdkTrapFocus
                    [cdkTrapFocusAutoCapture]="true">
                    <h2 class="tw-mb-0 tw-text-center tw-text-xl tw-font-normal tw-leading-none tw-text-black">Bitte melden sie sich an</h2>

                    <form
                        [formGroup]="loginForm"
                        (submit)="onSubmit()"
                        novalidate>
                        <div class="tw-flex tw-flex-col tw-gap-y-2.5 sm:tw-flex-row sm:tw-justify-center sm:tw-gap-x-2.5">
                            <div class="tw-flex sm:tw-w-[200px]">
                                <chroma-form-field>
                                    <label
                                        class="tw-sr-only"
                                        for="usernameInput"
                                        >Benutzername</label
                                    >
                                    <input
                                        chromaInput
                                        type="text"
                                        id="usernameInput"
                                        placeholder="Benutzername"
                                        autocomplete="username"
                                        formControlName="username"
                                        cdkFocusInitial />
                                </chroma-form-field>
                            </div>

                            <div class="tw-flex sm:tw-w-[200px]">
                                <chroma-form-field>
                                    <label
                                        class="tw-sr-only"
                                        for="passwordInput"
                                        >Passwort</label
                                    >
                                    <input
                                        chromaInput
                                        [type]="hidePassword ? 'password' : 'text'"
                                        id="passwordInput"
                                        placeholder="Passwort"
                                        autocomplete="current-password"
                                        formControlName="password" />
                                    <button
                                        chromaSuffix
                                        type="button"
                                        class="tw-flex tw-items-center tw-text-primary-base disabled:tw-text-neutral-400"
                                        tabindex="-1"
                                        [disabled]="!loginForm.value.password"
                                        (click)="hidePassword = !hidePassword">
                                        <span class="material-symbols-rounded">{{ hidePassword ? 'visibility' : 'visibility_off' }}</span>
                                    </button>
                                </chroma-form-field>
                            </div>

                            <button
                                type="submit"
                                chroma-button
                                size="icon">
                                <span class="material-symbols-rounded tw-text-xl tw-leading-none">login</span>
                            </button>
                        </div>

                        @if ((authError$ | async) === authError.InvalidCredentials) {
                            <div class="tw-mt-2.5 tw-flex tw-items-center tw-gap-x-2.5 tw-rounded tw-bg-red-50 tw-px-3.5 tw-py-2.5 tw-text-red-600">
                                <span class="material-symbols-rounded">error</span>
                                <div class="tw-text-sm">Ihr Benutzername und/oder Passwort sind nicht korrekt.</div>
                            </div>
                        }
                    </form>

                    <a
                        class="tw-text-center tw-text-base tw-text-primary-base"
                        [href]="resetPasswordUrl"
                        >Benutzername oder Passwort vergessen?</a
                    >

                    <div class="tw-text-center tw-text-base tw-text-black">
                        <div>Sie brauchen Unterstützung bei Ihrem Zugang?</div>
                        <div>E-Mail: <a href="mailto:helpdesk@dericon.de">helpdesk&#64;dericon.de</a></div>
                    </div>
                </div>
            </div>
        } @else {
            <chroma-loading />
        }
    </div>
</div>
