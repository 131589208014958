import { createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';
import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export const initialState: AuthState = {
    loggingIn: false,
    loggedIn: false,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.logIn, state => ({
        ...state,
        loggingIn: true
    })),
    on(AuthActions.loggedIn, state => ({
        ...state,
        loggingIn: false
    })),
    on(AuthActions.setAuthUser, (state, authUser) => ({
        ...state,
        user: authUser,
        loggedIn: true
    })),
    on(AuthActions.logOut, state => ({
        ...state,
        loggedIn: false
    })),
    on(AuthActions.logInFailed, (state, action) => ({
        ...state,
        loggingIn: false,
        error: action.error
    }))
);
