import { Component, EventEmitter, input, Output } from '@angular/core';
import { Article } from '../../../types/contents.type';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { ArticleAssetsComponent } from '../article-assets/article-assets.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { TiptapEditorPipe } from '../../../pipes/tiptap-editor.pipe';
import { ReadMode } from '../../../../core/ui/store/ui.type';
import { Store } from '@ngrx/store';
import * as UISelectors from '../../../../core/ui/store/ui.selectors';
import { getFeatureFlags } from '../../../helpers/local-storage.helper';

@Component({
    selector: 'app-article-content',
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        DatePipe,
        ArticleAssetsComponent,
        NgxTiptapModule,
        TiptapEditorPipe
    ],
    templateUrl: './article-content.component.html'
})
export class ArticleContentComponent {
    article = input<Article>();
    innerWidth = input<number>();

    readMode$ = this.store.select(UISelectors.selectReadMode);

    featureFlags = getFeatureFlags();
    ReadMode = ReadMode;

    @Output() imageLoaded = new EventEmitter<void>();

    constructor(private store: Store) {}
}
