import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
    standalone: true
})
export class PaginatePipe implements PipeTransform {
    transform<T>(data: Array<T>, pageIndex: number, pageSize: number, active = false): Array<T> {
        if (!data || pageIndex === undefined || pageSize === undefined || !active) {
            return data;
        }

        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;

        return data.slice(startIndex, endIndex);
    }
}
