import { Component, HostBinding, Input } from '@angular/core';
import { cva } from 'cva';

export const checkboxStyle = cva([
  'tw-form-checkbox',
  'tw-w-4.5',
  'tw-h-4.5',
  'tw-rounded-half',
  'tw-border-control-base',
  'dark:tw-rounded-sm'
]);

@Component({
  selector: 'chroma-pseudo-checkbox',
  standalone: true,
  template: '',
  host: {
    '[class.checked]': 'checked',
    '[class.tw-bg-primary-base]': 'checked',
    '[class.tw-bg-checked]': 'checked',
    '[class.tw-bg-center]': 'checked',
    '[class.tw-border-transparent]': 'checked'
  },
  styles: [`
    :host.checked {
      background-image: url("./icons/check-small.svg");
    }
  `]
})
export class ChromaPseudoCheckboxComponent {

  @Input() checked = false;

  @HostBinding('class')
  hostClasses = checkboxStyle();

}
