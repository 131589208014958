<div class="tw-flex tw-items-center tw-justify-between">
    <span class="tw-text-base tw-leading-5">Zeige {{ startIndex + 1 }} – {{ endIndex }} von {{ length }} Ergebnissen</span>
    <div class="tw-flex tw-gap-x-3.75">
        <button
            type="button"
            chroma-button
            appearance="outline"
            size="icon"
            [disabled]="_previousButtonsDisabled()"
            (click)="previousPage()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">arrow_back_ios_new</span>
        </button>
        <button
            type="button"
            chroma-button
            appearance="outline"
            size="icon"
            [disabled]="_nextButtonsDisabled()"
            (click)="nextPage()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">arrow_forward_ios</span>
        </button>
    </div>
</div>
