import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageReferenceResolverService {
    resolveRemoteImagePath(ref: string): string {
        return ['/derifiles', ref].join('/');
    }
}
