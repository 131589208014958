<div class="tw-flex tw-flex-col tw-gap-y-5">
    <div class="tw-flex tw-gap-x-2.5">
        <ng-content></ng-content>
        <app-editor
            class="tw-flex-grow peer-[]:tw-pt-1.5"
            [formControl]="commentControl"
            [editorClasses]="editorClasses()"
            [toolbarMode]="editing() ? 'always' : 'focused'"
            [placeholder]="placeholder()" />
    </div>
    <button
        type="button"
        chroma-button
        class="tw-cursor-default tw-self-end"
        (click)="onCommentUpsert()">
        {{ editing() ? 'Aktualisieren' : 'Absenden' }}
    </button>
</div>
