import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button-default.component';

export class PageEvent {
    pageIndex: number;
}

@Component({
    selector: 'chroma-paginator',
    templateUrl: 'paginator.html',
    standalone: true,
    imports: [ButtonComponent]
})
export class ChromaPaginator {
    @Input() pageIndex = 0;
    @Input() length = 0;
    @Input() pageSize = 500;

    @Output() readonly page = new EventEmitter<PageEvent>();

    get startIndex(): number {
        return this.pageIndex * this.pageSize;
    }

    get endIndex(): number {
        return this.startIndex < this.length
            ? Math.min(this.startIndex + this.pageSize, this.length)
            : this.startIndex + this.pageSize;
    }

    previousPage(): void {
        if (!this.hasPreviousPage()) {
            return;
        }

        this.pageIndex = this.pageIndex - 1;
        this._emitPageEvent();
    }

    nextPage(): void {
        if (!this.hasNextPage()) {
            return;
        }

        this.pageIndex = this.pageIndex + 1;
        this._emitPageEvent();
    }

    hasPreviousPage(): boolean {
        return this.pageIndex >= 1;
    }

    hasNextPage(): boolean {
        return this.pageIndex < this.getNumberOfPages() - 1;
    }

    getNumberOfPages(): number {
        if (!this.pageSize) {
            return 0;
        }

        return Math.ceil(this.length / this.pageSize);
    }

    _previousButtonsDisabled() {
        return !this.hasPreviousPage();
    }

    _nextButtonsDisabled() {
        return !this.hasNextPage();
    }

    private _emitPageEvent(): void {
        this.page.emit({
            pageIndex: this.pageIndex
        });
    }
}
