import { NgModule } from '@angular/core';
import { ChromaOption } from './option';
import { ChromaOptgroup } from './optgroup';

@NgModule({
    imports: [ChromaOption, ChromaOptgroup],
    exports: [ChromaOption, ChromaOptgroup]
})
export class ChromaOptionModule {}

export * from './option';
export * from './optgroup';
