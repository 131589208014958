<div
    class="tw-h-full tw-w-screen tw-overflow-y-auto tw-py-7.5 sm:tw-px-5 sm:tw-scrollbar-gutter-stable lg:tw-w-[calc(100vw-theme(space.64))] lg:tw-px-8 2xl:tw-py-12.5"
    cdkScrollable>
    <chroma-dialog-header
        [title]="data.editMode ? 'Artikel bearbeiten' : 'Neuen Artikel erstellen'"
        [largeHeader]="true"
        (closeDialog)="closeDialog()" />
    <form [formGroup]="composeArticleForm">
        <div class="tw-gap-y tw-flex tw-flex-col tw-gap-y-7.5 2xl:tw-gap-y-10">
            <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <div class="tw-flex tw-flex-col tw-gap-y-10">
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Kategorie</div>
                        <div class="tw-flex tw-w-full sm:tw-w-[372px]">
                            <chroma-form-field>
                                <chroma-select formControlName="categoryId">
                                    @for (group of navigationGroups$ | async; track $index) {
                                        <chroma-optgroup [label]="group.label">
                                            @for (item of group.navigationItems; track item.categoryId) {
                                                <chroma-option [value]="item.categoryId">{{ item.label }}</chroma-option>
                                            }
                                        </chroma-optgroup>
                                    }
                                </chroma-select>
                            </chroma-form-field>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Titel</div>
                        <div class="tw-flex tw-w-full">
                            <chroma-form-field>
                                <input
                                    type="text"
                                    chroma-input
                                    formControlName="title"
                                    placeholder="Bitte schreiben Sie ihren Titel hier..." />
                            </chroma-form-field>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Artikelinhalt</div>
                        <div
                            class="tw-flex tw-flex-col tw-gap-y-6 tw-rounded tw-px-2.5 tw-pb-2.5 tw-pt-[0.4375rem] tw-ring-1 tw-ring-inset"
                            [ngClass]="{
                                'tw-ring-secondary': !isBodyControlInvalid(),
                                'tw-ring-red-600': isBodyControlInvalid()
                            }">
                            <div class="tw-divide-separator tw-hidden tw-divide-x sm:tw-flex">
                                <div class="tw-flex tw-w-[250px] tw-pr-2.5">
                                    <chroma-form-field>
                                        <chroma-select
                                            [formControl]="textStyleControl"
                                            (selectionChange)="changeTextStyle($event)">
                                            <chroma-option value="heading">Überschrift</chroma-option>
                                            <chroma-option value="paragraph">Fließtext</chroma-option>
                                        </chroma-select>
                                    </chroma-form-field>
                                </div>
                                <div class="tw-flex tw-gap-x-1.25 tw-px-2.5">
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('bold')"
                                        (click)="editor.chain().focus().toggleBold().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_bold</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('italic')"
                                        (click)="editor.chain().focus().toggleItalic().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_italic</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('underline')"
                                        (click)="editor.chain().focus().toggleUnderline().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_underlined</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('strike')"
                                        (click)="editor.chain().focus().toggleStrike().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_strikethrough</span>
                                    </button>
                                </div>
                                <div class="tw-px-2.5">
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-relative tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="isColorPickerOpen"
                                        cdkOverlayOrigin
                                        #colorPickerTrigger="cdkOverlayOrigin"
                                        [class.tw-pointer-events-none]="isColorPickerOpen"
                                        (click)="isColorPickerOpen = !isColorPickerOpen">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_color_text</span>
                                        <span
                                            class="tw-absolute tw-bottom-[9px] tw-h-[4px] tw-w-[17px] tw-rounded-[1px]"
                                            [ngStyle]="{
                                                'background-color': editor.getAttributes('textStyle').color ? editor.getAttributes('textStyle').color : PickerColor.Black
                                            }"></span>
                                    </button>
                                    <ng-template
                                        cdkConnectedOverlay
                                        [cdkConnectedOverlayOrigin]="colorPickerTrigger"
                                        [cdkConnectedOverlayOpen]="isColorPickerOpen"
                                        (overlayOutsideClick)="isColorPickerOpen = false">
                                        <div class="tw-my-2.5 tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                                            <div class="tw-grid tw-grid-cols-4 tw-gap-2.5">
                                                @for (color of pickerColors; track $index) {
                                                    <button
                                                        type="button"
                                                        class="tw-inline-flex tw-h-7.5 tw-w-7.5 tw-cursor-default tw-items-center tw-justify-center tw-rounded-half"
                                                        [ngStyle]="{ 'background-color': color }"
                                                        (click)="changeTextColor(color)">
                                                        <span
                                                            class="material-symbols-rounded"
                                                            [ngClass]="{
                                                                'tw-text-white': editor.isActive('textStyle', { color }) || (color === PickerColor.Black && !editor.getAttributes('textStyle').color),
                                                                'tw-text-transparent hover:tw-text-white/60': !editor.isActive('textStyle', { color }) && (color !== PickerColor.Black || editor.getAttributes('textStyle').color)
                                                            }">
                                                            check
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="tw-flex tw-gap-x-1.25 tw-px-2.5">
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('bulletList')"
                                        (click)="editor.chain().focus().toggleBulletList().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_list_bulleted</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        [active]="editor.isActive('orderedList')"
                                        (click)="editor.chain().focus().toggleOrderedList().run()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_list_numbered</span>
                                    </button>
                                </div>
                                <div class="tw-flex tw-gap-x-1.25 tw-pl-2.5">
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        cdkOverlayOrigin
                                        #linkInputTrigger="cdkOverlayOrigin"
                                        [class.tw-pointer-events-none]="isLinkInputOpen"
                                        (click)="openLinkInput()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">link</span>
                                    </button>
                                    <ng-template
                                        cdkConnectedOverlay
                                        [cdkConnectedOverlayOrigin]="linkInputTrigger"
                                        [cdkConnectedOverlayOpen]="isLinkInputOpen"
                                        (overlayOutsideClick)="isLinkInputOpen = false; linkForm.reset()">
                                        <div class="tw-my-2.5 tw-w-[400px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                                            <form [formGroup]="linkForm">
                                                <div class="tw-flex tw-flex-col tw-items-end tw-gap-y-5">
                                                    <div class="tw-flex tw-w-full">
                                                        <chroma-form-field>
                                                            <input
                                                                type="text"
                                                                chroma-input
                                                                formControlName="url"
                                                                placeholder="URL" />
                                                        </chroma-form-field>
                                                    </div>
                                                    <div class="tw-flex tw-w-full">
                                                        <chroma-form-field>
                                                            <input
                                                                type="text"
                                                                chroma-input
                                                                formControlName="text"
                                                                placeholder="Anzeigetext (optional)" />
                                                        </chroma-form-field>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="text"
                                                            chroma-button
                                                            class="tw-cursor-default"
                                                            (click)="insertLink()">
                                                            Einfügen
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>

                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        (click)="editorImageFile.click()">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">image</span>
                                    </button>
                                    <input
                                        #editorImageFile
                                        type="file"
                                        class="tw-hidden"
                                        accept="image/png, image/jpeg"
                                        (change)="insertImage($event)" />

                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        size="icon"
                                        appearance="text"
                                        cdkOverlayOrigin
                                        #emojiPickerTrigger="cdkOverlayOrigin"
                                        [class.tw-pointer-events-none]="isEmojiPickerOpen"
                                        (click)="isEmojiPickerOpen = !isEmojiPickerOpen">
                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">sentiment_satisfied</span>
                                    </button>
                                    <ng-template
                                        cdkConnectedOverlay
                                        [cdkConnectedOverlayOrigin]="emojiPickerTrigger"
                                        [cdkConnectedOverlayOpen]="isEmojiPickerOpen"
                                        (overlayOutsideClick)="isEmojiPickerOpen = false">
                                        <div class="tw-my-2.5 tw-h-[251px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                                            <div class="tw-h-full tw-overflow-y-auto">
                                                <div class="tw-mr-4 tw-grid tw-grid-cols-7 tw-gap-1.25">
                                                    @for (emoji of emojis; track $index) {
                                                        <button
                                                            type="button"
                                                            class="tw-h-[34px] tw-w-[34px] tw-cursor-default tw-rounded hover:tw-bg-primary-hover-light"
                                                            (click)="editor.commands.setEmoji(emoji)">
                                                            <span
                                                                class="tw-inline-block tw-h-[24px] tw-w-[24px] tw-font-emoji tw-text-[24px] tw-leading-[32px]"
                                                                [innerHTML]="emoji"></span>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <tiptap-editor
                                formControlName="body"
                                [editor]="editor"
                                outputFormat="json" />
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Vorschaubild (optional)</div>
                        <div class="tw-flex tw-flex-col tw-gap-3.75">
                            <span class="tw-leading-5 tw-text-secondary">
                                Unterstützte Formate: JPG, PNG<br />
                                Maximale Dateigröße: {{ maxCoverFileSize }} MB
                            </span>
                            <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                @if (coverImageIdControl.value) {
                                    <div class="tw-relative tw-aspect-square tw-w-full sm:tw-w-56">
                                        <img
                                            class="tw-h-full tw-w-full tw-object-cover"
                                            [src]="'/derifiles/' + coverImageIdControl.value" />
                                        <div class="tw-absolute tw-top-0 tw-h-full tw-w-full tw-bg-white/40">
                                            <div class="tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-2.5">
                                                <div>
                                                    <button
                                                        type="button"
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="coverImageFile.click()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                                    </button>
                                                    <input
                                                        #coverImageFile
                                                        type="file"
                                                        class="tw-hidden"
                                                        accept="image/png, image/jpeg"
                                                        (change)="uploadCoverImage($event)" />
                                                </div>
                                                <div>
                                                    <button
                                                        chroma-button
                                                        class="tw-cursor-default"
                                                        size="icon"
                                                        (click)="removeCoverImage()">
                                                        <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="tw-flex tw-aspect-square tw-w-full tw-items-center tw-justify-center tw-border tw-border-dashed tw-border-secondary sm:tw-w-56">
                                        <button
                                            type="button"
                                            chroma-button
                                            class="tw-cursor-default"
                                            size="icon"
                                            (click)="coverImageFile.click()">
                                            <span class="material-symbols-rounded tw-text-xl tw-leading-none">upload</span>
                                        </button>
                                        <input
                                            #coverImageFile
                                            type="file"
                                            class="tw-hidden"
                                            accept="image/png, image/jpeg"
                                            (change)="uploadCoverImage($event)" />
                                    </div>
                                }
                                @if (coverImageIdControl.hasError('fileSizeExceeded')) {
                                    <span class="tw-text-[0.8125rem] tw-leading-3.25 tw-text-warning">Maximale Dateigröße überschritten.</span>
                                }
                            </div>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Artikelstatus</div>
                        <div class="tw-flex tw-flex-col tw-gap-x-7.5 tw-gap-y-2.5 sm:tw-flex-row">
                            <chroma-checkbox formControlName="mustRead">
                                <ng-container label>Lesepflichtig</ng-container>
                            </chroma-checkbox>
                            <chroma-checkbox formControlName="pinned">
                                <ng-container label>Pinned</ng-container>
                            </chroma-checkbox>
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                        <div class="tw-text-xl tw-leading-6">Weitere Optionen</div>
                        <chroma-checkbox formControlName="notifyUsers">
                            <ng-container label>E-Mail-Benachrichtigung</ng-container>
                        </chroma-checkbox>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-10">
                        <span class="tw-leading-5 tw-text-secondary">Maximale Dateigröße: {{ maxAttachmentFileSize }} MB</span>
                        @if (attachmentsControl.value.length) {
                            <div class="tw-flex tw-flex-wrap tw-gap-5">
                                @for (attachment of attachmentsControl.value; track attachment.fileId) {
                                    <span class="tw-w-asset-button tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="material-symbols-rounded tw-text-asset-icon">attach_file</span>
                                            <span class="tw-inline-flex tw-min-w-0 tw-grow tw-flex-col">
                                                <span class="tw-inline-flex tw-text-sm tw-font-bold"
                                                    ><span class="tw-line-clamp-1 tw-break-all">{{ attachment.fileName.split('.')[0] }}</span>
                                                    <span>.{{ attachment.fileName.split('.')[1] }}</span>
                                                </span>
                                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">{{ attachment.size | filesize }}</span>
                                            </span>
                                            <button
                                                chroma-button
                                                class="tw-cursor-default"
                                                size="icon"
                                                appearance="text"
                                                (click)="removeAttachment(attachment.fileId)">
                                                <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                            </button>
                                        </span>
                                    </span>
                                }
                            </div>
                        }
                        <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                            <div>
                                <button
                                    type="button"
                                    chroma-button
                                    class="tw-cursor-default"
                                    appearance="outline"
                                    (click)="attachmentFile.click()">
                                    <span
                                        class="material-symbols-rounded tw-text-xl tw-leading-none"
                                        icon-left
                                        >attach_file</span
                                    >
                                    <span>Anhang hinzufügen</span>
                                </button>
                                <input
                                    #attachmentFile
                                    type="file"
                                    class="tw-hidden"
                                    (change)="uploadAttachment($event)" />
                            </div>
                            @if (attachmentsControl.hasError('fileSizeExceeded')) {
                                <span class="tw-text-[0.8125rem] tw-leading-3.25 tw-text-warning">Maximale Dateigröße überschritten.</span>
                            }
                        </div>
                    </div>
                    <hr class="tw-border-border-separator" />
                    <div class="tw-flex tw-flex-col tw-gap-y-10">
                        @if (productsControl.value.length) {
                            <div class="tw-flex tw-flex-wrap tw-gap-5">
                                @for (product of productsControl.value; track product.dq) {
                                    <span class="tw-w-asset-button tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="material-symbols-rounded tw-text-asset-icon">show_chart</span>
                                            <span class="tw-inline-flex tw-min-w-0 tw-flex-grow tw-flex-col">
                                                <span class="tw-line-clamp-1 tw-break-all tw-text-sm tw-font-bold">{{ product.name }}</span>
                                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">(ISIN) {{ product.isin }}</span>
                                            </span>
                                            <button
                                                chroma-button
                                                class="tw-cursor-default"
                                                size="icon"
                                                appearance="text"
                                                (click)="removeProduct(product.dq)">
                                                <span class="material-symbols-rounded tw-text-xl tw-leading-none">delete</span>
                                            </button>
                                        </span>
                                    </span>
                                }
                            </div>
                        }

                        <div>
                            <button
                                type="button"
                                chroma-button
                                class="tw-cursor-default"
                                appearance="outline"
                                cdkOverlayOrigin
                                #productSelectionTrigger="cdkOverlayOrigin"
                                [class.tw-pointer-events-none]="isProductSelectionOpen"
                                (click)="isProductSelectionOpen = !isProductSelectionOpen">
                                <span
                                    class="material-symbols-rounded tw-text-xl tw-leading-none"
                                    icon-left
                                    >query_stats</span
                                >
                                <span>Finanzprodukt verlinken</span>
                            </button>
                            <ng-template
                                cdkConnectedOverlay
                                [cdkConnectedOverlayOrigin]="productSelectionTrigger"
                                [cdkConnectedOverlayOpen]="isProductSelectionOpen"
                                [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' }]"
                                (overlayOutsideClick)="isProductSelectionOpen = false">
                                <div class="tw-mb-2.5 tw-max-h-[330px] tw-w-[400px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                                    <div class="tw-flex tw-h-full tw-flex-col tw-gap-y-5">
                                        @if ((solrProducts$ | async).length) {
                                            <div class="tw-overflow-y-scroll tw-pr-4">
                                                <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                                    @for (product of solrProducts$ | async; track $index) {
                                                        <span class="tw-inline-flex tw-w-full tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base">
                                                            <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                                                <span class="material-symbols-rounded tw-text-asset-icon">show_chart</span>
                                                                <span class="tw-inline-flex tw-min-w-0 tw-flex-grow tw-flex-col">
                                                                    <span class="tw-line-clamp-1 tw-break-all tw-text-sm tw-font-bold">{{ product.name }}</span>
                                                                    <span class="tw-text-sm tw-leading-4.5 tw-text-secondary">(ISIN) {{ product.isin }}</span>
                                                                </span>
                                                                <button
                                                                    chroma-button
                                                                    class="tw-cursor-default"
                                                                    size="icon"
                                                                    appearance="text"
                                                                    (click)="addProduct({ dq: product.id, isin: product.isin, name: product.name })">
                                                                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">add_circle</span>
                                                                </button>
                                                            </span>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div class="tw-flex tw-w-full">
                                            <chroma-form-field>
                                                <input
                                                    type="text"
                                                    chroma-input
                                                    [formControl]="solrSearchControl"
                                                    placeholder="ISIN oder WKN eingeben" />
                                            </chroma-form-field>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-px-5 sm:tw-px-0">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    (click)="upsertArticle()">
                    {{ data.editMode ? 'Artikel aktualisieren' : 'Artikel veröffentlichen' }}
                </button>
            </div>
        </div>
    </form>
</div>
