<div class="tw-fixed tw-left-0 tw-top-top-bar tw-z-10 tw-w-full tw-bg-surface-brand-light tw-px-5 tw-pb-5 tw-pt-7.5 sm:tw-w-screen sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable lg:tw-top-top-bar-lg lg:tw-px-8 2xl:tw-left-64 2xl:tw-w-[calc(100vw-theme(space.64))] 2xl:tw-pt-12.5">
    <div class="tw-flex tw-flex-col tw-gap-y-10">
        <h1 class="tw-text-2.25xl tw-leading-7.5 sm:tw-text-3xl">Meine Leseliste</h1>
        <chroma-slide-toggle
            class="tw-self-end"
            [checked]="restrictToMustRead === true"
            [disabled]="['initial', 'switch'].includes(introsLoading$ | async)"
            (change)="filterByStatus('restrictToMustRead', $event)"
            >lesepflichtige</chroma-slide-toggle
        >
    </div>
</div>
<div class="tw-h-[6.875rem] sm:tw-h-[7.25rem]"></div>

@if ((introsLoading$ | async) === 'initial') {
    <div class="tw-fixed tw-left-0 tw-top-top-bar tw-flex tw-h-[calc(100%-theme(height.top-bar-lg))] tw-w-full tw-items-center tw-justify-center sm:tw-top-top-bar-lg 2xl:tw-left-64 2xl:tw-w-[calc(100%-theme(space.64))]">
        <chroma-loading />
    </div>
} @else {
    <div class="tw-mt-5 tw-flex tw-flex-col tw-gap-y-2.5">
        <div class="tw-flex tw-flex-col tw-gap-y-7.5">
            <div class="tw-grid tw-flex-1 tw-divide-y tw-divide-border-separator sm:tw-grid-cols-2 sm:tw-gap-2.5 sm:tw-divide-y-0 2xl:tw-grid-cols-2 4xl:tw-grid-cols-3">
                @for (intro of intros$ | async; track $index) {
                    <a
                        class="tw-relative tw-flex tw-h-[227px] tw-cursor-default tw-select-none tw-bg-white after:tw-pointer-events-none after:tw-absolute after:-tw-inset-px after:hover:tw-ring-5 after:hover:tw-ring-inset after:hover:tw-ring-primary-focus sm:tw-h-52 sm:tw-rounded sm:tw-shadow-soft sm:after:tw-rounded"
                        [routerLink]="['..', 'category', intro.categoryId, 'article', intro.id]">
                        <span class="tw-flex tw-flex-grow tw-flex-col tw-gap-y-5 tw-px-5 tw-py-7.5">
                            <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-5">
                                <span class="tw-flex tw-items-center tw-gap-x-2 tw-rounded tw-bg-surface-brand-light tw-px-2 tw-py-1 tw-text-primary-base">
                                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">shoppingmode</span>
                                    <span class="tw-text-sm">{{ intro.categoryName }}</span>
                                </span>
                                <button
                                    type="button"
                                    chroma-button
                                    size="icon"
                                    appearance="outline"
                                    class="tw-cursor-default"
                                    (click)="unsetBookmark($event, intro.id)">
                                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">bookmark_remove</span>
                                </button>
                            </span>
                            <span class="tw-flex tw-flex-col tw-gap-y-2">
                                <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5">
                                    @if (intro.author.profilePictureId) {
                                        <img
                                            class="tw-h-[25px] tw-w-[25px] tw-rounded-full tw-object-contain"
                                            [src]="'/derifiles/' + intro.author.profilePictureId" />
                                    } @else {
                                        <span class="tw-inline-flex tw-h-[25px] tw-w-[25px] tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light">
                                            <span class="material-symbols-rounded tw-text-primary-base">person</span>
                                        </span>
                                    }
                                    <span class="tw-flex-1 tw-text-sm tw-font-bold tw-leading-none tw-text-secondary">{{ intro.author.firstName }} {{ intro.author.lastName }}</span>
                                    @if (intro.mustRead) {
                                        <span class="material-symbols-rounded tw-text-warning">flag</span>
                                    }
                                </span>
                                <span class="tw-line-clamp-3 tw-font-bold sm:tw-line-clamp-2">{{ intro.title }}</span>
                            </span>
                        </span>
                    </a>
                }
            </div>
        </div>
        @if ((introsLoading$ | async) === 'next') {
            <div class="tw-flex tw-justify-center tw-py-6">
                <chroma-loading type="dot" />
            </div>
        }
        @if ((introsLoading$ | async) === 'inactive' && ((introsEndReached$ | async) || !(intros$ | async).length)) {
            <div class="tw-flex tw-justify-center tw-px-3.5 tw-py-6">
                <span class="tw-text-center tw-text-neutral-400">
                    @if (!(intros$ | async).length) {
                        <span>Keine Einträge gefunden.</span>
                    } @else {
                        <span>Keine älteren Einträge gefunden.</span>
                    }
                </span>
            </div>
        }
    </div>
}
