import { Directive, HostBinding, Input } from '@angular/core';
import { cva } from 'cva';

export type ButtonAppearance = 'fill' | 'outline' | 'text';
export type ButtonSize = 'base' | 'small' | 'icon';

export const buttonStyle = cva(
    [
        'tw-inline-block',
        'tw-no-underline',
        'tw-rounded',
        'dark:tw-rounded-full',
        'focus-visible:tw-outline',
        'focus-visible:tw-outline-2',
        'disabled:tw-cursor-not-allowed',
        'tw-whitespace-nowrap',
        'tw-leading-5'
    ],
    {
        variants: {
            fill: {
                standard: [
                    'tw-text-white',
                    'tw-bg-primary-base',
                    'hover:tw-bg-primary-hover-dark',
                    'focus-visible:tw-outline-primary-focus',
                    'disabled:tw-text-neutral-400',
                    'disabled:tw-bg-neutral-200',
                    'dark:disabled:tw-text-old-gray-500',
                    'dark:disabled:tw-bg-old-gray-300'
                ]
            },
            outline: {
                standard: [
                    'tw-text-primary-base',
                    'tw-ring-primary-base',
                    'hover:tw-text-white',
                    'hover:tw-bg-primary-hover-dark',
                    'dark:hover:tw-text-primary-base',
                    'dark:hover:tw-bg-primary-hover-light',
                    'dark:disabled:hover:tw-bg-transparent',
                    'hover:tw-ring-0',
                    'disabled:hover:tw-ring-1',
                    'dark:hover:tw-ring-1',
                    'focus-visible:tw-outline-primary-focus',
                    'disabled:tw-text-neutral-400',
                    'dark:disabled:tw-text-old-gray-500',
                    'disabled:tw-ring-neutral-400',
                    'dark:disabled:tw-ring-old-gray-500'
                ],
                neutral: [
                    'tw-text-black',
                    'dark:tw-text-white',
                    'tw-ring-black',
                    'dark:tw-ring-white',
                    'hover:tw-bg-white/10',
                    'focus-visible:tw-outline-white/30',
                    'disabled:tw-opacity-60'
                ]
            },
            text: {
                standard: [
                    'tw-text-primary-base',
                    'hover:tw-bg-primary-hover-light',
                    'focus-visible:tw-outline-primary-focus',
                    'dark:hover:tw-bg-primary-hover-light',
                    'disabled:tw-text-neutral-400',
                    'dark:disabled:tw-text-old-gray-500'
                ],
                neutral: [
                    'tw-text-black',
                    'dark:tw-text-white',
                    'hover:tw-bg-white/10',
                    'focus-visible:tw-outline-white/30',
                    'disabled:tw-opacity-60'
                ]
            },
            size: {
                base: ['tw-text-base', 'tw-px-3.75', 'tw-py-2.25', 'dark:tw-px-3'],
                small: ['tw-text-xs', 'tw-px-2.5', 'tw-py-1.5'],
                icon: ['tw-p-2.25']
            },
            active: {
                true: 'tw-bg-primary-hover-light'
            }
        },
        compoundVariants: [
            {
                outline: ['standard', 'neutral'],
                class: 'tw-ring-inset tw-ring-1 disabled:tw-bg-transparent'
            },
            {
                text: ['standard', 'neutral'],
                class: 'disabled:tw-bg-transparent'
            }
        ]
    }
);

@Directive()
export class ButtonBase {
    constructor(private intent: string) {}

    public _size: ButtonSize = 'base';
    public _appearance: ButtonAppearance = 'fill';
    public _active: boolean = false;

    @HostBinding('class')
    public hostClasses = buttonStyle({
        [this._appearance]: this.intent,
        size: this._size,
        active: this._active
    });

    @Input() set size(value: ButtonSize) {
        this._size = value;
        this.hostClasses = buttonStyle({
            [this._appearance]: this.intent,
            size: this._size,
            active: this._active
        });
    }

    @Input() set active(value: boolean) {
        this._active = value;
        this.hostClasses = buttonStyle({
            [this._appearance]: this.intent,
            size: this._size,
            active: this._active
        });
    }
}
