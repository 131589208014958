import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Article, ArticleComment } from '../../../../../shared/types/contents.type';
import { Observable, map, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../../../shared/api/contents.api';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouterActions } from '../../../../../shared/store/router/router.actions';
import { ArticleViewStore } from '../article-view.store';
import { NavigationActions } from '../../../../../core/navigation/store/navigation.actions';

interface ArticleBaseState {
    loading: boolean;
    article?: Article;
    comments: Array<ArticleComment>;
}

@Injectable()
export class ArticleBaseStore extends ComponentStore<ArticleBaseState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi,
        private readonly articleViewStore: ArticleViewStore
    ) {
        super({
            loading: false,
            comments: []
        });
    }

    readonly getArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            tap(() => this.startArticleLoading()),
            switchMap(articleId =>
                this.contentsApi.getArticle(articleId).pipe(
                    tap(data => {
                        this.setArticle(data);
                        this.articleViewStore.setArticleLoaded();
                        this.getCommentsForArticle(articleId);

                        if (!data.meta.seen) {
                            this.seenArticle(articleId);
                        }
                    })
                )
            )
        )
    );

    readonly seenArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            switchMap(articleId =>
                this.contentsApi
                    .seenArticle(articleId)
                    .pipe(tap(() => this.store.dispatch(NavigationActions.loadUnseen())))
            )
        )
    );

    readonly deleteArticle = this.effect((categoryId$: Observable<string>) =>
        categoryId$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([categoryId, articleId]) =>
                this.contentsApi.deleteArticle(articleId).pipe(
                    tap(() =>
                        this.store.dispatch(
                            RouterActions.navigate({
                                path: `/feed/category/${categoryId}`
                            })
                        )
                    )
                )
            )
        )
    );

    readonly getCommentsForArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            switchMap(articleId =>
                this.contentsApi
                    .getCommentsForArticle(articleId)
                    .pipe(tap(data => this.setArticleComments(data)))
            )
        )
    );

    readonly setBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.setBookmark(articleId).pipe(tap(() => this.updateBookmark(true)))
            )
        )
    );

    readonly unsetBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi
                    .unsetBookmark(articleId)
                    .pipe(tap(() => this.updateBookmark(false)))
            )
        )
    );

    readonly setLike = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.setLike(articleId).pipe(tap(() => this.updateLike(true)))
            )
        )
    );

    readonly unsetLike = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi
                    .unsetLike(articleId)
                    .pipe(tap(() => this.updateLike(false)))
            )
        )
    );

    readonly startArticleLoading = this.updater(state => ({
        ...state,
        loading: true
    }));

    readonly setArticle = this.updater(
        (state, article: Article): ArticleBaseState => ({
            ...state,
            article,
            loading: false
        })
    );

    readonly setArticleComments = this.updater((state, comments: Array<ArticleComment>) => ({
        ...state,
        comments
    }));

    readonly updateLike = this.updater(
        (state, liked: boolean): ArticleBaseState => ({
            ...state,
            article: {
                ...state.article,
                meta: {
                    ...state.article.meta,
                    liked
                },
                interactions: {
                    ...state.article.interactions,
                    likeCount: state.article.interactions.likeCount + (liked ? 1 : -1)
                }
            }
        })
    );

    readonly updateBookmark = this.updater(
        (state, bookmarked: boolean): ArticleBaseState => ({
            ...state,
            article: {
                ...state.article,
                meta: {
                    ...state.article.meta,
                    bookmarked
                }
            }
        })
    );

    readonly articleLoading$: Observable<boolean> = this.select(state => state.loading);
    readonly article$: Observable<Article> = this.select(state => state.article);
    readonly articleComments$: Observable<Array<ArticleComment>> = this.select(
        state => state.comments
    );
}
