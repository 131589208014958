import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterActions } from './router.actions';
import { tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class RouterEffects {
    navigate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.navigate),
                tap(action => this.router.navigateByUrl(action.path))
            ),
        {
            dispatch: false
        }
    );

    constructor(
        private actions$: Actions,
        private router: Router
    ) {}
}
