import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
    Article,
    ArticleComment,
    CommentBase,
    ReplyComment
} from '../../../../../shared/types/contents.type';
import { Observable, map, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../../../shared/api/contents.api';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouterActions } from '../../../../../shared/store/router/router.actions';
import { ArticleViewStore } from '../article-view.store';
import { NavigationActions } from '../../../../../core/navigation/store/navigation.actions';
import { JSONContent } from '@tiptap/core';

type Submitted = 'comment' | 'sub-comment' | false;

interface ArticleBaseState {
    loading: boolean;
    article?: Article;
    comments: Array<
        ArticleComment & {
            editing: boolean;
            replies: Array<ReplyComment & { editing: boolean }>;
        }
    >;
    submitted: Submitted;
    submittedParentCommentId: string;
}

@Injectable()
export class ArticleBaseStore extends ComponentStore<ArticleBaseState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi,
        private readonly articleViewStore: ArticleViewStore
    ) {
        super({
            loading: false,
            comments: [],
            submitted: false,
            submittedParentCommentId: null
        });
    }

    readonly getArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            tap(() => this.startArticleLoading()),
            switchMap(articleId =>
                this.contentsApi.getArticle(articleId).pipe(
                    tap(data => {
                        this.setArticle(data);
                        this.articleViewStore.setArticleLoaded();
                        this.getCommentsForArticle(false);

                        if (!data.meta.seen) {
                            this.seenArticle();
                        }
                    })
                )
            )
        )
    );

    readonly seenArticle = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi
                    .seenArticle(articleId)
                    .pipe(tap(() => this.store.dispatch(NavigationActions.loadUnseen())))
            )
        )
    );

    readonly deleteArticle = this.effect((categoryId$: Observable<string>) =>
        categoryId$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([categoryId, articleId]) =>
                this.contentsApi.deleteArticle(articleId).pipe(
                    tap(() =>
                        this.store.dispatch(
                            RouterActions.navigate({
                                path: `/feed/category/${categoryId}`
                            })
                        )
                    )
                )
            )
        )
    );

    readonly createComment = this.effect(
        (
            params$: Observable<{
                type: Submitted;
                parentCommentId?: string;
                body: JSONContent;
            }>
        ) =>
            params$.pipe(
                concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
                switchMap(([{ type, parentCommentId, body }, articleId]) =>
                    this.contentsApi.createComment(articleId, parentCommentId, body).pipe(
                        tap(() => {
                            if (parentCommentId) {
                                this.setSubmittedParentCommentId(parentCommentId);
                            }

                            this.getCommentsForArticle(type);
                        })
                    )
                )
            )
    );

    readonly getCommentsForArticle = this.effect((type$: Observable<Submitted>) =>
        type$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([type, articleId]) =>
                this.contentsApi.getCommentsForArticle(articleId).pipe(
                    tap(data => {
                        this.setArticleComments(data);

                        if (type) {
                            this.setSubmitted(type);
                        }
                    })
                )
            )
        )
    );

    readonly setBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.setBookmark(articleId).pipe(tap(() => this.updateBookmark(true)))
            )
        )
    );

    readonly unsetBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi
                    .unsetBookmark(articleId)
                    .pipe(tap(() => this.updateBookmark(false)))
            )
        )
    );

    readonly setLike = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.setLike(articleId).pipe(tap(() => this.updateLike(true)))
            )
        )
    );

    readonly unsetLike = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.unsetLike(articleId).pipe(tap(() => this.updateLike(false)))
            )
        )
    );

    readonly startArticleLoading = this.updater(state => ({
        ...state,
        loading: true
    }));

    readonly setArticle = this.updater(
        (state, article: Article): ArticleBaseState => ({
            ...state,
            article,
            loading: false
        })
    );

    readonly setArticleComments = this.updater((state, comments: Array<ArticleComment>) => ({
        ...state,
        comments: comments.map(comment => ({
            ...comment,
            editing: false,
            replies: comment.replies.map(reply => ({ ...reply, editing: false }))
        }))
    }));

    readonly setCommentEditing = this.updater(
        (
            state,
            { commentId, parentCommentId }: { commentId: string; parentCommentId?: string }
        ) => ({
            ...state,
            comments: state.comments.map(comment => ({
                ...comment,
                editing: comment.id === commentId ? !comment.editing : false,
                replies:
                    parentCommentId && parentCommentId === comment.id
                        ? comment.replies.map((reply: ReplyComment & { editing: boolean }) => ({
                              ...reply,
                              editing: reply.id === commentId ? !reply.editing : false
                          }))
                        : comment.replies
            }))
        })
    );

    readonly updateLike = this.updater(
        (state, liked: boolean): ArticleBaseState => ({
            ...state,
            article: {
                ...state.article,
                meta: {
                    ...state.article.meta,
                    liked
                },
                interactions: {
                    ...state.article.interactions,
                    likeCount: state.article.interactions.likeCount + (liked ? 1 : -1)
                }
            }
        })
    );

    readonly updateBookmark = this.updater(
        (state, bookmarked: boolean): ArticleBaseState => ({
            ...state,
            article: {
                ...state.article,
                meta: {
                    ...state.article.meta,
                    bookmarked
                }
            }
        })
    );

    readonly setSubmitted = this.updater(
        (state, submitted: Submitted): ArticleBaseState => ({
            ...state,
            submitted
        })
    );

    readonly resetSubmitted = this.updater(
        (state): ArticleBaseState => ({
            ...state,
            submitted: false,
            submittedParentCommentId: null
        })
    );

    readonly setSubmittedParentCommentId = this.updater(
        (state, submittedParentCommentId: string): ArticleBaseState => ({
            ...state,
            submittedParentCommentId
        })
    );

    readonly articleLoading$: Observable<boolean> = this.select(state => state.loading);
    readonly article$: Observable<Article> = this.select(state => state.article);
    readonly articleComments$: Observable<
        Array<
            ArticleComment & {
                editing: boolean;
                replies: Array<ReplyComment & { editing: boolean }>;
            }
        >
    > = this.select(state => state.comments);

    readonly submitted$: Observable<Submitted> = this.select(state => state.submitted);
    readonly submittedParentCommentId$: Observable<string> = this.select(
        state => state.submittedParentCommentId
    );
}
