import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonComponent, ChromaDialog, ChromaLoading, ChromaSlideToggle } from 'chroma-ui';
import { BookmarksViewStore } from './bookmarks-view.store';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.type';

@Component({
    selector: 'app-bookmarks-view',
    standalone: true,
    imports: [AsyncPipe, RouterLink, ButtonComponent, ChromaLoading, ChromaSlideToggle],
    providers: [BookmarksViewStore],
    templateUrl: './bookmarks-view.component.html'
})
export class BookmarksViewComponent implements OnInit, OnDestroy {
    restrictToMustRead = false;

    intros$ = this.bookmarksViewStore.intros$;
    introsLoading$ = this.bookmarksViewStore.introsLoading$;
    introsEndReached$ = this.bookmarksViewStore.introsEndReached$;

    private destroy$ = new Subject<void>();

    constructor(
        private store: Store,
        private readonly bookmarksViewStore: BookmarksViewStore,
        private dialog: ChromaDialog
    ) {}

    ngOnInit(): void {
        this.bookmarksViewStore.initSession({
            restrictToMustRead: this.restrictToMustRead,
            filter: false
        });

        combineLatest([
            this.bookmarksViewStore.initialLoad$,
            this.bookmarksViewStore.introsEndReached$,
            this.store.select(UISelectors.selectScrolledToEnd)
        ])
            .pipe(
                filter(
                    ([initialLoad, endReached, multiplier]) =>
                        !initialLoad && !endReached && multiplier > 0
                ),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.bookmarksViewStore.loadNextSet());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterByStatus(property: string, value: boolean): void {
        this.store.dispatch(UIActions.resetScrolledToEnd());

        this[property] = value;

        this.bookmarksViewStore.initSession({
            restrictToMustRead: this.restrictToMustRead,
            filter: true
        });
    }

    unsetBookmark(e: Event, articleId: string): void {
        e.preventDefault();
        e.stopPropagation();

        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
            ConfirmDialogComponent,
            {
                data: {
                    title: 'Artikel entfernen',
                    message: `Sind Sie sich sicher, dass Sie diesen Artikel aus Ihrer Leseliste entfernen wollen?`,
                    type: 'delete'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.confirmed))
            .subscribe(() => this.bookmarksViewStore.unsetBookmark(articleId));
    }
}
