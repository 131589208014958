import { inject, Pipe, PipeTransform } from '@angular/core';
import { Editor, JSONContent } from '@tiptap/core';
import { TipTapExtensionsService } from '../services/tiptap-extensions.service';

@Pipe({
    name: 'tiptapEditor',
    standalone: true
})
export class TiptapEditorPipe implements PipeTransform {
    private tipTapExtensionsService = inject(TipTapExtensionsService);

    transform(content: JSONContent): any {
        return new Editor({
            editable: false,
            extensions: this.tipTapExtensionsService.getExtensions(),
            content
        });
    }
}
