<div class="tw-inline-flex tw-items-center tw-w-full tw-gap-x-2.5">
    @if (multiple) {
        <chroma-pseudo-checkbox [checked]="selected" />
    }

    <span class="tw-w-full tw-text-base tw-leading-5 tw-text-black dark:tw-text-old-black" #text><ng-content></ng-content></span>

    @if (selected && !multiple) {
        <span class="material-symbols-rounded tw-text-1.5xl tw-text-primary-base">check</span>
    }
</div>
