export const ENV_CONFIG_KEY = 'envConfig';

export type FeatureFlag =
    | 'SEEN'
    | 'SEEN_ANONYMOUS'
    | 'LIKED'
    | 'LIKED_ANONYMOUS'
    | 'MAIL_NOTIFICATION'
    | 'CONTENT_TEMPLATES';

export interface EnvironmentConfig {
    derifinBaseUrl: string;
    featureFlags: Array<FeatureFlag>;
}
