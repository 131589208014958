<app-navigation-bars />

<div
    class="sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable"
    infiniteScroll
    [infiniteScrollDisabled]="(infiniteLoading$ | async) === false"
    [infiniteScrollDistance]="0.5"
    (scrolled)="onScroll()">
    <div
        class="tw-fixed tw-bottom-0 tw-top-top-bar tw-z-20 tw-w-full tw-overflow-y-auto tw-border-r tw-border-neutral-200 tw-bg-white tw-pb-11.25 tw-pt-2.5 lg:tw-top-top-bar-lg lg:tw-w-64 lg:tw-pt-22.5"
        [ngClass]="{ 'tw-hidden lg:tw-block': (isSidebarOpen$ | async) === false }">
        <div class="tw-flex tw-flex-col">
            @for (group of navigationGroups$ | async; track groupIndex; let groupIndex = $index) {
                <button
                    type="button"
                    class="tw-flex tw-h-12.5 tw-cursor-default tw-items-center tw-gap-x-2.5 tw-px-8 tw-text-left hover:tw-bg-primary-hover-light lg:tw-h-20"
                    (click)="toggleNavigationGroup(groupIndex)">
                    @if (!group.expanded && group.active) {
                        <span class="tw-absolute tw-left-0 tw-h-12.5 tw-w-1.25 tw-bg-primary-base lg:tw-h-20"></span>
                    }
                    @if (group.unseen) {
                        <span class="tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-solid tw-border-primary-base tw-bg-primary-base"></span>
                    } @else {
                        <span class="tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-solid tw-border-primary-base"></span>
                    }
                    <span class="tw-line-clamp-2 tw-flex-1 tw-leading-5 lg:tw-line-clamp-3">{{ group.label }}</span>
                    @if (group.expanded) {
                        <span class="material-symbols-rounded tw-text-primary-base">expand_less</span>
                    } @else {
                        <span class="material-symbols-rounded tw-text-primary-base">expand_more</span>
                    }
                </button>
                @if (group.expanded) {
                    @for (item of group.navigationItems; track $index) {
                        <a
                            [routerLink]="'/feed/category/' + item.categoryId"
                            class="tw-flex tw-h-12.5 tw-cursor-default tw-items-center tw-gap-x-2.5 tw-px-8 hover:tw-bg-primary-hover-light">
                            @if (item.categoryId === (categoryId$ | async)) {
                                <span class="tw-absolute tw-left-0 tw-h-12.5 tw-w-1.25 tw-bg-primary-base"></span>
                            }
                            @if (item.unseen) {
                                <span class="tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-solid tw-border-primary-base tw-bg-primary-base"></span>
                            } @else {
                                <span class="tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-solid tw-border-primary-base"></span>
                            }
                            <span class="tw-line-clamp-2 tw-flex-1 tw-leading-5">{{ item.label }}</span>
                        </a>
                    }
                }
            }
        </div>
    </div>
    <div class="tw-py-7.5 sm:tw-px-5 lg:tw-ml-64 lg:tw-px-8 2xl:tw-py-12.5">
        <router-outlet></router-outlet>
    </div>
</div>

<div class="tw-h-bottom-bar md:tw-hidden"></div>
