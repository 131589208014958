import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewEncapsulation
} from '@angular/core';
import {
    MatCalendar,
    MatCalendarHeader,
    MatDatepicker,
    MatDatepickerIntl
} from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from 'chroma-ui';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

@Component({
    selector: 'app-calendar-header',
    templateUrl: './calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, ButtonComponent]
})
export class CalendarHeaderComponent<D> extends MatCalendarHeader<D> {
    constructor(
        private intl: MatDatepickerIntl,
        calendar: MatCalendar<D>,
        private dateAdapter: DateAdapter<D, any>,
        @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
        private datePicker: MatDatepicker<D>
    ) {
        super(intl, calendar, dateAdapter, dateFormats, cdr);
    }

    selectToday(): void {
        this.datePicker.select(this.dateAdapter.today());
        this.datePicker.close();
    }

    clearDate(): void {
        this.datePicker.select(null);
        this.datePicker.close();
    }
}
