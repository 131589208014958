import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
    ButtonComponent,
    CheckboxComponent,
    CHROMA_DIALOG_DATA,
    ChromaDialogHeader,
    ChromaDialogRef,
    ChromaInput,
    ChromaSelectModule,
    FileSizePipe
} from 'chroma-ui';
import { NgxTiptapModule } from 'ngx-tiptap';
import { JSONContent } from '@tiptap/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as NavigationSelectors from '../../../../core/navigation/store/navigation.selectors';
import { AsyncPipe } from '@angular/common';
import { ComposeArticleDialogData } from './compose-article-dialog.type';
import { AttachmentsApi } from '../../../api/attachments.api';
import {
    combineLatest,
    debounceTime,
    map,
    Observable,
    startWith,
    Subject,
    take,
    takeUntil
} from 'rxjs';
import { FileAttachment } from '../../../types/attachment.type';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContentsApi } from '../../../api/contents.api';
import { ComposeArticleDialogStore } from './compose-article-dialog.store';
import { Product } from '../../../types/product.type';
import { Router } from '@angular/router';
import { SearchResult } from '../../../types/solr.type';
import { EditorComponent } from '../../editor/editor.component';
import { DateInputComponent } from '../../date-input/date-input.component';
import moment from 'moment';
import { getFeatureFlags } from '../../../helpers/local-storage.helper';

@Component({
    selector: 'app-compose-article-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        FileSizePipe,
        ReactiveFormsModule,
        OverlayModule,
        ChromaDialogHeader,
        EditorComponent,
        ButtonComponent,
        ChromaSelectModule,
        ChromaInput,
        CheckboxComponent,
        NgxTiptapModule,
        DateInputComponent
    ],
    templateUrl: './compose-article-dialog.component.html',
    providers: [ComposeArticleDialogStore]
})
export class ComposeArticleDialogComponent implements OnInit, OnDestroy {
    data: ComposeArticleDialogData = inject(CHROMA_DIALOG_DATA);

    maxCoverFileSize = 1;
    maxAttachmentFileSize = 15;

    isProductSelectionOpen = false;

    minPublishDate: Date;
    minExpiryDate: Date;

    featureFlags = getFeatureFlags();

    composeArticleForm: FormGroup<{
        categoryId: FormControl<string>;
        title: FormControl<string>;
        body: FormControl<JSONContent>;
        coverImageId: FormControl<string>;
        publishDate: FormControl<Date | null>;
        expiryDate: FormControl<Date | null>;
        mustRead: FormControl<boolean>;
        pinned: FormControl<boolean>;
        notifyUsers: FormControl<boolean>;
        storeCopyAsTemplate: FormControl<boolean>;
        attachments: FormControl<Array<FileAttachment>>;
        products: FormControl<Array<Product>>;
        tags: FormControl<Array<any>>;
    }>;

    get bodyControl() {
        return this.composeArticleForm.controls.body;
    }

    get coverImageIdControl() {
        return this.composeArticleForm.controls.coverImageId;
    }

    get attachmentsControl() {
        return this.composeArticleForm.controls.attachments;
    }

    get productsControl() {
        return this.composeArticleForm.controls.products;
    }

    solrSearchControl = new FormControl();

    private _destroy = new Subject<void>();

    categoryGroups$ = this.store.select(NavigationSelectors.selectCategoryGroups);

    solrProducts$: Observable<Array<SearchResult>>;

    constructor(
        private router: Router,
        private dialogRef: ChromaDialogRef<ComposeArticleDialogComponent>,
        private store: Store,
        private readonly composeArticleDialogStore: ComposeArticleDialogStore,
        private contentsApi: ContentsApi,
        private attachmentsApi: AttachmentsApi
    ) {}

    ngOnInit(): void {
        const { article } = this.data;

        this.composeArticleForm = new FormGroup({
            categoryId: new FormControl(article.categoryId, Validators.required),
            title: new FormControl('title' in article ? article.title : '', Validators.required),
            body: new FormControl('body' in article ? article.body : null, Validators.required),
            coverImageId: new FormControl('coverImageId' in article ? article.coverImageId : null),
            publishDate: new FormControl('publishDate' in article ? article.publishDate : null),
            expiryDate: new FormControl('expiryDate' in article ? article.expiryDate : null),
            mustRead: new FormControl('mustRead' in article ? article.mustRead : false),
            pinned: new FormControl('pinned' in article ? article.pinned : false),
            notifyUsers: new FormControl(false),
            storeCopyAsTemplate: new FormControl(false),
            attachments: new FormControl('attachments' in article ? article.attachments : []),
            products: new FormControl('products' in article ? article.products : []),
            tags: new FormControl([])
        });

        this.solrProducts$ = combineLatest([
            this.composeArticleDialogStore.solrProducts$,
            this.productsControl.valueChanges.pipe(startWith([]))
        ]).pipe(
            map(([solrProducts]) =>
                solrProducts.filter(
                    solrProduct =>
                        !this.productsControl.value
                            .map(product => product.dq)
                            .includes(solrProduct.id)
                )
            )
        );

        this.solrSearchControl.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy))
            .subscribe(term => this.composeArticleDialogStore.searchSolr({ term }));

        const today = moment();
        this.minPublishDate = today.toDate();
        this.minExpiryDate = today.add(1, 'day').toDate();
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    uploadCoverImage(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            if (file.size > this.maxCoverFileSize * 1024 * 1024) {
                this.coverImageIdControl.setErrors({
                    fileSizeExceeded: true
                });
            } else {
                this.attachmentsApi
                    .uploadFile(file)
                    .pipe(take(1))
                    .subscribe(data => this.coverImageIdControl.setValue(data.fileId));
            }
        }
    }

    removeCoverImage(): void {
        this.coverImageIdControl.setValue(null);
    }

    uploadAttachment(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            if (file.size > this.maxAttachmentFileSize * 1024 * 1024) {
                this.attachmentsControl.setErrors({
                    fileSizeExceeded: true
                });
            } else {
                this.attachmentsApi
                    .uploadFile(file)
                    .pipe(take(1))
                    .subscribe(attachment =>
                        this.attachmentsControl.setValue([
                            ...this.attachmentsControl.value,
                            attachment
                        ])
                    );
            }
        }
    }

    removeAttachment(fileId: string): void {
        this.attachmentsControl.setValue(
            this.attachmentsControl.value.filter(attachment => attachment.fileId !== fileId)
        );
    }

    addProduct(product: Product): void {
        this.productsControl.setValue([...this.productsControl.value, product]);

        this.isProductSelectionOpen = false;
    }

    removeProduct(dq: string): void {
        this.productsControl.setValue(
            this.productsControl.value.filter(product => product.dq !== dq)
        );
    }

    onUpsertArticle(): void {
        if (this.composeArticleForm.valid) {
            const { attachments, products, ...formData } = this.composeArticleForm.getRawValue();
            const composedArticle = {
                ...formData,
                attachments: attachments.map(attachment => attachment.fileId),
                products: products.map(product => product.dq)
            };

            if (this.data.mode === 'edit') {
                this.contentsApi
                    .updateArticle(this.data.article.id, composedArticle)
                    .subscribe(() =>
                        this.dialogRef.close({
                            updated: true
                        })
                    );
            } else {
                this.contentsApi
                    .createArticle(composedArticle)
                    .subscribe(data =>
                        moment(formData.publishDate).isSame(moment(), 'day')
                            ? this.router.navigate([
                                  'feed',
                                  'category',
                                  data.categoryId,
                                  'article',
                                  data.id
                              ])
                            : this.router.navigate(['feed', 'category', data.categoryId])
                    );
            }
        } else {
            this.composeArticleForm.markAllAsTouched();
        }
    }
}
