import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, concatMap, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../shared/api/contents.api';
import { ArticleIntro } from '../../../shared/types/contents.type';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UIActions } from './../../../core/ui/store/ui.actions';
import * as UISelectors from './../../../core/ui/store/ui.selectors';

const ARTICLE_LOADING_LIMIT = 15;

type Loading = 'inactive' | 'initial' | 'next' | 'switch';

interface CategoryViewState {
    sessionId?: string;
    loading: Loading;
    initialLoad: boolean;
    data: Array<ArticleIntro>;
    offset: number;
    endReached: boolean;
    navigated: boolean;
}

@Injectable()
export class CategoryViewStore extends ComponentStore<CategoryViewState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi
    ) {
        super({
            navigated: false,
            loading: 'inactive',
            initialLoad: true,
            offset: 0,
            endReached: false,
            data: []
        });
    }

    readonly initSession = this.effect(
        (
            params$: Observable<{
                categoryId: string;
                restrictToMustRead: boolean;
                restrictToUnread: boolean;
                navigated: boolean;
                filter: boolean;
            }>
        ) =>
            params$.pipe(
                tap(({ navigated, filter }) =>
                    this.setLoading(filter ? 'next' : navigated ? 'switch' : 'initial')
                ),
                tap(() => this.store.dispatch(UIActions.closeSidebar())),
                switchMap(({ categoryId, restrictToMustRead, restrictToUnread, navigated }) =>
                    this.contentsApi.initCategorySession(categoryId, restrictToMustRead, restrictToUnread).pipe(
                        tap(data => this.setSessionId(data.sessionId)),
                        tap(() => this.resetArticlePreviews(navigated)),
                        tap(() =>
                            this.loadArticlePreviews({
                                navigated
                            })
                        )
                    )
                )
            )
    );

    readonly loadNextSet = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setLoading('next')),
            tap(() =>
                this.loadArticlePreviews({
                    navigated: false
                })
            )
        )
    );

    readonly loadArticlePreviews = this.effect(
        (
            params$: Observable<{
                navigated: boolean;
            }>
        ) =>
            params$.pipe(
                concatLatestFrom(() => [
                    this.sessionId$,
                    this.initialLoad$,
                    this.store.select(UISelectors.selectScrolledToEnd)
                ]),
                concatMap(([{ navigated }, sessionId, initialLoad, multiplier]) =>
                    this.contentsApi
                        .getArticlePreviews(
                            sessionId,
                            ARTICLE_LOADING_LIMIT,
                            initialLoad ? 0 : multiplier * ARTICLE_LOADING_LIMIT
                        )
                        .pipe(
                            tap(data =>
                                this.addArticlePreviews({
                                    data,
                                    navigated
                                })
                            ),
                            tap(() => this.setLoading('inactive'))
                        )
                )
            )
    );

    readonly setLoading = this.updater(
        (state, loading: Loading): CategoryViewState => ({
            ...state,
            loading
        })
    );

    readonly setSessionId = this.updater(
        (state, sessionId: string): CategoryViewState => ({
            ...state,
            sessionId
        })
    );

    readonly resetArticlePreviews = this.updater(
        (state, navigated: boolean): CategoryViewState => ({
            ...state,
            data: navigated ? state.data : [],
            offset: 0
        })
    );

    readonly addArticlePreviews = this.updater(
        (state, payload: { data: Array<ArticleIntro>; navigated: boolean }): CategoryViewState => ({
            ...state,
            initialLoad: false,
            data: payload.navigated ? payload.data : [...state.data, ...payload.data],
            endReached: payload.data.length === 0
        })
    );

    readonly initialLoad$: Observable<boolean> = this.select(state => state.initialLoad);

    readonly sessionId$: Observable<string> = this.select(state => state.sessionId);

    readonly introsLoading$: Observable<Loading> = this.select(state => state.loading);
    readonly introsEndReached$: Observable<boolean> = this.select(state => state.endReached);
    readonly introsPinned$: Observable<Array<ArticleIntro>> = this.select(state => state.data.filter(intro => intro.pinned))
    readonly introsRegular$: Observable<Array<ArticleIntro>> = this.select(state => state.data.filter(intro => !intro.pinned));
}
