import BaseHeading, { HeadingOptions } from '@tiptap/extension-heading';
import { mergeAttributes } from '@tiptap/core';

type Level = 2;

const classes: Record<Level, string> = {
    2: 'tw-text-[1.125rem] tw-font-bold tw-leading-[1.375rem] tw-mb-5 last:tw-mb-0'
};

const Heading = BaseHeading.extend({
    renderHTML({ node, HTMLAttributes }) {
        const hasLevel = this.options.levels.includes(node.attrs.level);
        const level = hasLevel ? node.attrs.level : this.options.levels[0];

        return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: `${classes[level]}`
            }),
            0
        ];
    }
});

export default Heading;
