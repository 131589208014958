<div class="tw-fixed tw-left-0 tw-top-top-bar tw-z-10 tw-w-full tw-bg-surface-brand-light tw-px-5 tw-pb-5 tw-pt-7.5 sm:tw-w-screen sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable lg:tw-top-top-bar-lg lg:tw-px-8 2xl:tw-left-64 2xl:tw-w-[calc(100vw-theme(space.64))] 2xl:tw-pt-12.5">
    <div class="tw-flex tw-justify-between">
        <a
            [routerLink]="'/feed/category/' + categoryId"
            chroma-button
            appearance="outline"
            class="tw-cursor-default"
            >Zur Übersicht</a
        >
        <div class="tw-hidden 2xl:tw-block">
            <button
                chroma-button
                appearance="outline"
                class="tw-cursor-default"
                size="icon"
                (click)="changeReadMode()">
                <span class="material-symbols-rounded tw-text-xl tw-leading-none">{{ (readMode$ | async) === ReadMode.View ? 'view_sidebar' : 'import_contacts' }}</span>
            </button>
        </div>
    </div>
</div>
<div class="tw-h-[3.625rem] 2xl:tw-h-[4.875rem]"></div>

<div #articleBase>
    <router-outlet></router-outlet>
</div>

@if ((readMode$ | async) === ReadMode.Explore && (articleLoaded$ | async)) {
    <div
        class="tw-fixed tw-bottom-[3.125rem] tw-right-8 tw-top-top-bar-lg tw-mt-32 tw-hidden 2xl:tw-block"
        [ngClass]="{
            'tw-pr-2.5 tw-scrollbar-gutter-stable': (scrollbarActive$ | async) === false
        }">
        @if ((introsLoading$ | async) === 'initial') {
            <div class="tw-flex tw-h-full tw-w-[23rem] tw-flex-col tw-gap-y-2.5 tw-overflow-y-auto tw-pr-2.5">
                @for (item of skeletonItems; track $index) {
                    <div class="tw-flex tw-h-[242px] tw-shrink-0 tw-flex-col tw-justify-between tw-rounded tw-bg-white tw-px-5 tw-py-7.5">
                        <span class="tw-flex tw-animate-pulse tw-flex-col tw-gap-y-2">
                            <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5">
                                <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                    <span class="tw-h-[25px] tw-w-[25px] tw-shrink-0 tw-rounded-full tw-bg-disabled-bg"></span>
                                    <span class="tw-h-[17px] tw-w-[200px] tw-rounded tw-bg-disabled-bg"></span>
                                </span>
                            </span>
                            <span class="tw-flex tw-flex-col tw-gap-y-1.5">
                                <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                                <span class="tw-h-[17px] tw-w-3/4 tw-rounded tw-bg-disabled-bg"></span>
                            </span>
                            <span class="tw-flex tw-flex-col tw-gap-y-1.5">
                                <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                                <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                            </span>
                        </span>
                        <span class="tw-flex tw-animate-pulse tw-justify-between">
                            <span class="tw-h-[18px] tw-w-[190px] tw-rounded tw-bg-disabled-bg"></span>
                            <span class="tw-h-[18px] tw-w-[50px] tw-rounded tw-bg-disabled-bg"> </span>
                        </span>
                    </div>
                }
            </div>
        } @else {
            <div
                class="tw-h-full tw-w-[23rem] tw-overflow-y-auto tw-pr-2.5"
                infiniteScroll
                [infiniteScrollDistance]="0.5"
                [infiniteScrollDisabled]="introsEndReached$ | async"
                [scrollWindow]="false"
                (scrolled)="loadMoreItems()">
                <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                    @for (intro of intros$ | async; track $index) {
                        <a
                            [routerLink]="intro.id"
                            class="tw-relative tw-flex tw-h-[242px] tw-cursor-default tw-flex-col tw-justify-between tw-bg-white tw-p-5"
                            [ngClass]="{
                                'tw-rounded hover:tw-ring-5 hover:tw-ring-inset hover:tw-ring-primary-focus': intro.id !== articleId,
                                'tw-rounded-e tw-shadow-soft': intro.id === articleId
                            }">
                            @if (intro.id === articleId) {
                                <span class="tw-absolute tw-bottom-0 tw-left-0 tw-top-0 tw-w-[5px] tw-bg-primary-base"></span>
                            }
                            <span class="tw-flex tw-flex-col tw-gap-y-2">
                                <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5">
                                    @if (intro.author.profilePictureId) {
                                        <img
                                            class="tw-h-[25px] tw-w-[25px] tw-rounded-full tw-object-contain"
                                            [src]="'/derifiles/' + intro.author.profilePictureId" />
                                    } @else {
                                        <span class="tw-inline-flex tw-h-[25px] tw-w-[25px] tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light">
                                            <span class="material-symbols-rounded tw-text-primary-base">person</span>
                                        </span>
                                    }
                                    <span class="tw-flex-1 tw-text-sm tw-font-bold tw-leading-none tw-text-secondary">{{ intro.author.firstName }} {{ intro.author.lastName }}</span>
                                </span>

                                <span class="tw-line-clamp-3 tw-font-bold">{{ intro.title }}</span>
                                <span class="tw-line-clamp-2">{{ intro.intro }}</span>
                            </span>
                            <span class="tw-flex tw-justify-between tw-text-secondary">
                                <span class="tw-text-sm tw-leading-4.5">Letzte Änderung: {{ intro.updatedAt | date: 'dd.MM.yyyy' }}</span>
                                <span class="tw-flex tw-items-center tw-gap-x-2">
                                    <span class="material-symbols-rounded tw-text-base tw-leading-none">chat_bubble</span>
                                    <span class="tw-text-sm tw-leading-4.5">{{ intro.commentCount }}</span>
                                </span>
                            </span></a
                        >
                    }

                    @if ((introsLoading$ | async) === 'next') {
                        <div class="tw-flex tw-justify-center tw-py-6">
                            <chroma-loading type="dot" />
                        </div>
                    }

                    @if (introsEndReached$ | async) {
                        <div class="tw-flex tw-justify-center tw-px-3.5 tw-py-6">
                            <span class="tw-text-center tw-text-neutral-400">Keine älteren Einträge gefunden.</span>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}
