import { Injectable } from '@angular/core';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import Color from '@tiptap/extension-color';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import History from '@tiptap/extension-history';
import Emoji from '../tiptap/emoji';
import Heading from '../tiptap/heading';

@Injectable()
export class TipTapExtensionsService {
    getExtensions() {
        return [
            Document,
            Text,
            Heading.configure({
                levels: [2]
            }),
            Paragraph.configure({
                HTMLAttributes: {
                    class: 'tw-leading-5 tw-mb-3.75 last:tw-mb-0 print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]'
                }
            }),
            BulletList.configure({
                HTMLAttributes: {
                    class: 'tw-list-disc tw-mb-3.75 tw-pl-6 [&_ul]:tw-list-[revert] [&_ul]:tw-pt-2 [&_ul]:tw-pb-2'
                }
            }),
            OrderedList.configure({
                HTMLAttributes: {
                    class: 'tw-list-decimal tw-mb-3.75 tw-pl-6 [&_ul]:tw-pt-2 [&_ul]:tw-pb-2'
                }
            }),
            ListItem,
            Link.configure({
                HTMLAttributes: {
                    class: 'tw-text-primary-base hover:tw-underline'
                }
            }).extend({
                inclusive: false
            }),
            Bold,
            Italic,
            Underline,
            Strike,
            TextStyle,
            TextAlign.configure({
                types: ['heading', 'paragraph']
            }),
            Placeholder.configure({
                placeholder: 'Bitte schreiben Sie Ihren Artikelinhalt hier...'
            }),
            Color,
            Dropcursor,
            Gapcursor,
            Table,
            TableRow,
            TableHeader,
            TableCell,
            Image.configure({
                allowBase64: true,
                inline: true,
                HTMLAttributes: {
                    class: 'tw-mb-3.75'
                }
            }).extend({
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        width: {
                            default: null
                        }
                    };
                }
            }),
            Emoji.configure({
                HTMLAttributes: {
                    class: 'tw-font-emoji tw-inline-block tw-h-[20px] tw-w-[20px] tw-text-[20px] tw-relative tw-top-0.5'
                }
            }),
            History
        ];
    }
}
