import { Node, PasteRule, mergeAttributes } from '@tiptap/core';
import emojiRegex from 'emoji-regex';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        emoji: {
            setEmoji: (emoji: string) => ReturnType;
        };
    }
}

const Emoji = Node.create({
    name: 'emoji',
    group: 'inline',
    inline: true,
    selectable: false,

    addOptions() {
        return {
            HTMLAttributes: {}
        };
    },

    addAttributes() {
        return {
            emoji: { default: null }
        };
    },

    parseHTML() {
        return [{ tag: `span[data-type="${this.name}"]` }];
    },

    renderHTML({ HTMLAttributes, node }) {
        return [
            'span',
            mergeAttributes(this.options.HTMLAttributes, {
                'data-type': this.name
            }),
            node.attrs.emoji
        ];
    },

    renderText({ node }) {
        return node.attrs.emoji;
    },

    addCommands() {
        return {
            setEmoji:
                emoji =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: this.name,
                        attrs: {
                            emoji
                        }
                    });
                }
        };
    },

    addPasteRules() {
        return [
            new PasteRule({
                find: emojiRegex(),
                handler: ({ range, match, commands }) => {
                    commands.insertContentAt(
                        range,
                        { type: this.name, attrs: { emoji: match[0] } },
                        { updateSelection: true }
                    );
                }
            })
        ];
    }
});

export default Emoji;
