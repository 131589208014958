import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthToken, AuthUser } from '../types/auth.type';
import { AuthError } from "../types/auth-error.type";

export const AuthActions = createActionGroup({
    source: 'Auth',
    events: {
        'Log In': props<{ username: string; password: string; returnUrl: string }>(),
        'Logged In': emptyProps(),
        'Log Out': emptyProps(),
        'Set Auth Token': props<{ authToken: AuthToken }>(),
        'Load Auth User': emptyProps(),
        'Set Auth User': props<AuthUser>(),
        'Refresh Token': emptyProps(),
        'Log In Failed': props<{ error: AuthError }>()
    }
});
