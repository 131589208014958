import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
    Article,
    ArticleComment,
    ComposeArticle,
    Intro,
    SessionData
} from '../types/contents.type';
import { SessionType } from '../types/session.type';
import { MercuryApi } from './mercury.api';

@Injectable({
    providedIn: 'root'
})
export class ContentsApi extends MercuryApi {
    constructor(http: HttpClient) {
        super('contents', http);
    }

    createSession(
        categoryId: string,
        restrictToMustRead: boolean,
        restrictToUnread: boolean
    ): Observable<SessionData> {
        return this.post<SessionData>('session', {
            type: SessionType.Category,
            categoryId: categoryId,
            restrictToMustRead,
            restrictToUnread
        }).pipe(map(response => response));
    }

    getArticlePreviews(sessionId: string, limit: number, offset: number): Observable<Array<Intro>> {
        return this.get<Array<Intro>>(`session/${sessionId}/data`, {
            params: {
                limit: limit.toString(),
                offset: offset.toString()
            }
        }).pipe(map(response => response));
    }

    getArticle(articleId: string): Observable<Article> {
        return this.get<Article>(articleId).pipe(map(response => response));
    }

    seenArticle(articleId: string): Observable<void> {
        return this.post<void>(`${articleId}/seen`);
    }

    createArticle(article: ComposeArticle): Observable<Article> {
        return this.post<Article>('', article).pipe(map(response => response));
    }

    editArticle(articleId: string, article: ComposeArticle): Observable<Article> {
        return this.patch<Article>(articleId, article).pipe(map(response => response));
    }

    deleteArticle(articleId: string): Observable<void> {
        return this.delete(articleId);
    }

    getCommentsForArticle(articleId: string): Observable<Array<ArticleComment>> {
        return this.get<Array<ArticleComment>>(`${articleId}/comments`).pipe(
            map(response => response)
        );
    }

    setBookmark(articleId: string): Observable<void> {
        return this.post(`${articleId}/bookmark`);
    }

    unsetBookmark(articleId: string): Observable<void> {
        return this.delete(`${articleId}/bookmark`);
    }

    setLike(articleId: string): Observable<void> {
        return this.post(`${articleId}/like`);
    }

    unsetLike(articleId: string): Observable<void> {
        return this.delete(`${articleId}/like`);
    }
}
