import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, catchError, switchMap } from 'rxjs';
import { AuthActions } from './store/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { ACCESS_TOKEN_KEY } from './types/auth.type';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private readonly SKIP_AUTH_HEADER = 'X-Skip-OAuth';

    constructor(
        private store: Store,
        private actions: Actions
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(this.SKIP_AUTH_HEADER)) {
            return next.handle(
                req.clone({
                    headers: req.headers.delete(this.SKIP_AUTH_HEADER)
                })
            );
        }

        if (req.url.endsWith('auth/token')) {
            return next.handle(req);
        }

        if (req.url.endsWith('auth/token/refreshing')) {
            return next.handle(req).pipe(
                catchError(err => {
                    if (err.status === 401) {
                        this.store.dispatch(AuthActions.logOut());
                    }

                    return EMPTY;
                })
            );
        }
        
        return next.handle(this.addToken(req)).pipe(
            catchError(err => {
                if (err.status === 401) {
                    this.store.dispatch(AuthActions.refreshToken());

                    return this.actions.pipe(
                        ofType(AuthActions.setAuthToken),
                        switchMap(() => next.handle(this.addToken(req)))
                    );
                }
            })
        );
    }

    private addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`
            }
        });
    }
}
