import { Component, Inject } from '@angular/core';
import { ButtonComponent, CHROMA_DIALOG_DATA, ChromaDialogHeader, ChromaDialogRef } from 'chroma-ui';
import { ConfirmDialogData } from './confirm-dialog.type';

@Component({
    selector: 'app-confirm-dialog',
    standalone: true,
    imports: [ChromaDialogHeader, ButtonComponent],
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
    constructor(
        private dialogRef: ChromaDialogRef<ConfirmDialogComponent>,
        @Inject(CHROMA_DIALOG_DATA) public data: ConfirmDialogData
    ) {}

    closeDialog(): void {
        this.dialogRef.close({ confirmed: false });
    }

    confirm(): void {
        this.dialogRef.close({ confirmed: true });
    }
}
