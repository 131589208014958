<div
    class="tw-inline-flex tw-w-full tw-flex-auto tw-gap-x-3.5 tw-rounded tw-bg-white tw-py-0.75 tw-pl-3.5 tw-pr-1.5 tw-ring-1 tw-ring-inset lg:tw-w-[372px]"
    [ngClass]="{ 'tw-ring-neutral-400': !focused, 'tw-ring-2 tw-ring-primary-focus': focused }">
    <input
        #input
        class="tw-w-full tw-text-base tw-leading-6 tw-text-black placeholder:tw-text-gray-400 autofill:tw-bg-clip-text focus:tw-outline-none"
        [matDatepicker]="datepicker"
        [min]="min()"
        [formControl]="dateControl"
        [placeholder]="placeholder ? placeholder : ''"
        [matDatepickerFilter]="filter"
        (focus)="focused = true"
        (blur)="focused = false" />
    <span class="tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full hover:tw-bg-primary-hover-light">
        <span
            class="material-symbols-rounded"
            (click)="onOpenDatepicker()"
            >calendar_month</span
        >
    </span>
</div>

<mat-datepicker
    #datepicker
    [calendarHeaderComponent]="customHeader"
    (opened)="onDatepickerOpened($event)"></mat-datepicker>
