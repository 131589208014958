import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { concatMap, map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { ArticleIntro } from '../../../shared/types/contents.type';
import { Store } from '@ngrx/store';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { ContentsApi } from '../../../shared/api/contents.api';
import { concatLatestFrom } from '@ngrx/effects';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import * as NavigationSelectors from '../../../core/navigation/store/navigation.selectors';

const ARTICLE_LOADING_LIMIT = 15;

type Loading = 'inactive' | 'initial' | 'next';

interface BookmarksViewState {
    sessionId?: string;
    loading: Loading;
    initialLoad: boolean;
    data: Array<ArticleIntro>;
    offset: number;
    endReached: boolean;
}

@Injectable()
export class BookmarksViewStore extends ComponentStore<BookmarksViewState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi
    ) {
        super({
            loading: 'inactive',
            initialLoad: true,
            offset: 0,
            endReached: false,
            data: []
        });
    }

    readonly initSession = this.effect(
        (
            params$: Observable<{
                restrictToMustRead: boolean;
                filter: boolean;
            }>
        ) =>
            params$.pipe(
                tap(({ filter }) => this.setLoading(filter ? 'next' : 'initial')),
                tap(() => this.store.dispatch(UIActions.closeSidebar())),
                switchMap(({ restrictToMustRead }) =>
                    this.contentsApi.initBookmarkSession(restrictToMustRead).pipe(
                        tap(data => this.setSessionId(data.sessionId)),
                        tap(() => this.resetArticlePreviews()),
                        tap(() => this.loadArticlePreviews())
                    )
                )
            )
    );

    readonly loadNextSet = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setLoading('next')),
            tap(() => this.loadArticlePreviews())
        )
    );

    readonly loadArticlePreviews = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => [
                this.sessionId$,
                this.initialLoad$,
                this.store.select(UISelectors.selectScrolledToEnd)
            ]),
            concatMap(([, sessionId, initialLoad, multiplier]) =>
                this.contentsApi
                    .getArticlePreviews(
                        sessionId,
                        ARTICLE_LOADING_LIMIT,
                        initialLoad ? 0 : multiplier * ARTICLE_LOADING_LIMIT
                    )
                    .pipe(
                        tap(data => this.addArticlePreviews(data)),
                        tap(() => this.setLoading('inactive'))
                    )
            )
        )
    );

    readonly unsetBookmark = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            switchMap(articleId =>
                this.contentsApi
                    .unsetBookmark(articleId)
                    .pipe(tap(() => this.filterUnsetBookmark(articleId)))
            )
        )
    );

    readonly setLoading = this.updater(
        (state, loading: Loading): BookmarksViewState => ({
            ...state,
            loading
        })
    );

    readonly setSessionId = this.updater(
        (state, sessionId: string): BookmarksViewState => ({
            ...state,
            sessionId
        })
    );

    readonly resetArticlePreviews = this.updater(
        (state): BookmarksViewState => ({
            ...state,
            data: [],
            offset: 0
        })
    );

    readonly addArticlePreviews = this.updater(
        (state, data: Array<ArticleIntro>): BookmarksViewState => ({
            ...state,
            initialLoad: false,
            data: [...state.data, ...data],
            endReached: data.length === 0
        })
    );

    readonly filterUnsetBookmark = this.updater(
        (state, articleId: string): BookmarksViewState => ({
            ...state,
            initialLoad: false,
            data: state.data.filter(intro => intro.id !== articleId)
        })
    );

    readonly initialLoad$: Observable<boolean> = this.select(state => state.initialLoad);

    readonly sessionId$: Observable<string> = this.select(state => state.sessionId);

    readonly introsLoading$: Observable<Loading> = this.select(state => state.loading);
    readonly introsEndReached$: Observable<boolean> = this.select(state => state.endReached);
    readonly intros$: Observable<Array<ArticleIntro & { categoryName: string }>> = this.select(
        state => state.data
    ).pipe(
        withLatestFrom(this.store.select(NavigationSelectors.selectNavigationItems)),
        map(([intros, navigationItems]) => {
            const navigationMap = new Map(
                navigationItems.map(item => [item.categoryId, item.label])
            );
            return intros.map(intro => ({
                ...intro,
                categoryName: navigationMap.get(intro.categoryId)
            }));
        })
    );
}
