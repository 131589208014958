export const REFRESH_TOKEN_KEY = 'refreshToken';
export const ACCESS_TOKEN_KEY = 'accessToken';

export interface AuthToken {
    token: string;
    refreshToken?: string;
}

export interface AuthUser {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    profilePictureId: string;
}
