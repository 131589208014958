import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
    Article,
    ArticleComment,
    Template,
    ComposeArticle,
    ArticleIntro,
    SessionData,
    ComposeTemplate
} from '../types/contents.type';
import { SessionType } from '../types/session.type';
import { MercuryApi } from './mercury.api';
import { JSONContent } from '@tiptap/core';

@Injectable({
    providedIn: 'root'
})
export class ContentsApi extends MercuryApi {
    constructor(http: HttpClient) {
        super('contents', http);
    }

    initCategorySession(
        categoryId: string,
        restrictToMustRead: boolean,
        restrictToUnread: boolean
    ): Observable<SessionData> {
        return this.post<SessionData>('session', {
            type: SessionType.Category,
            categoryId: categoryId,
            restrictToMustRead,
            restrictToUnread
        }).pipe(map(response => response));
    }

    initBookmarkSession(restrictToMustRead: boolean): Observable<SessionData> {
        return this.post<SessionData>('session', {
            type: SessionType.Bookmark,
            restrictToMustRead
        }).pipe(map(response => response));
    }

    getArticlePreviews(
        sessionId: string,
        limit: number,
        offset: number
    ): Observable<Array<ArticleIntro>> {
        return this.get<Array<ArticleIntro>>(`session/${sessionId}/data`, {
            params: {
                limit: limit.toString(),
                offset: offset.toString()
            }
        }).pipe(map(response => response));
    }

    getArticle(articleId: string): Observable<Article> {
        return this.get<Article>(articleId).pipe(map(response => response));
    }

    seenArticle(articleId: string): Observable<void> {
        return this.post<void>(`${articleId}/seen`);
    }

    createArticle(article: ComposeArticle): Observable<Article> {
        return this.post<Article>('', article).pipe(map(response => response));
    }

    updateArticle(articleId: string, article: ComposeArticle): Observable<Article> {
        return this.patch<Article>(articleId, article).pipe(map(response => response));
    }

    deleteArticle(articleId: string): Observable<void> {
        return this.delete(articleId);
    }

    createComment(articleId: string, parentCommentId: string, body: JSONContent): Observable<void> {
        return this.post<void>(`${articleId}/comments`, {
            parentCommentId,
            body
        });
    }

    getCommentsForArticle(articleId: string): Observable<Array<ArticleComment>> {
        return this.get<Array<ArticleComment>>(`${articleId}/comments`).pipe(
            map(response => response)
        );
    }

    updateComment(articleId: string, commentId: string, body: JSONContent): Observable<void> {
        return this.patch(`${articleId}/comments/${commentId}`, {
            body
        });
    }

    deleteComment(articleId: string, commentId: string): Observable<void> {
        return this.delete(`${articleId}/comments/${commentId}`);
    }

    setBookmark(articleId: string): Observable<void> {
        return this.post(`${articleId}/bookmark`);
    }

    unsetBookmark(articleId: string): Observable<void> {
        return this.delete(`${articleId}/bookmark`);
    }

    setLike(articleId: string): Observable<void> {
        return this.post(`${articleId}/like`);
    }

    unsetLike(articleId: string): Observable<void> {
        return this.delete(`${articleId}/like`);
    }

    getTemplates(): Observable<Array<Template>> {
        return this.get<Array<Template>>('templates').pipe(map(response => response));
    }

    createTemplate(template: ComposeTemplate): Observable<void> {
        return this.post('templates', template);
    }

    updateTemplate(templateId: string, template: ComposeTemplate): Observable<void> {
        return this.patch(`templates/${templateId}`, template);
    }

    deleteTemplate(templateId: string): Observable<void> {
        return this.delete(`templates/${templateId}`);
    }
}
