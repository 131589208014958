<div class="tw-fixed tw-top-0 tw-z-20 tw-w-full sm:tw-w-screen">
    <div class="tw-border-b tw-border-neutral-200 tw-bg-white sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable">
        <div class="tw-mx-auto tw-px-5 lg:tw-px-8">
            <div
                class="tw-flex tw-py-2.5"
                [ngClass]="{
                    'tw-justify-center 2xl:tw-justify-start': isAuthView(),
                    'tw-justify-between': !isAuthView()
                }">
                @if (!isAuthView()) {
                    <div class="2xl:tw-hidden">
                        <button
                            type="button"
                            class="tw-block tw-h-9.5 tw-w-[2.375rem] tw-overflow-hidden tw-rounded tw-text-black hover:tw-text-primary-base focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-primary-focus"
                            (click)="toggleSidebar()">
                            <span class="material-symbols-rounded tw-relative tw-bottom-1.25 tw-right-1.5 tw-font-light tw-icon-5xl">{{ (isSidebarOpen$ | async) ? 'close' : 'menu' }}</span>
                        </button>
                    </div>
                }

                <div
                    class="tw-w-56 tw-shrink-0 tw-items-center"
                    [ngClass]="{ 'tw-flex': isAuthView(), 'tw-hidden lg:tw-flex': !isAuthView() }">
                    <a
                        class="tw-flex tw-items-center focus-visible:tw-outline-2 focus-visible:tw-outline-primary-focus lg:tw-h-auto"
                        href="/">
                        <img
                            class="focus-visible:tw-outline"
                            [src]="logo" />
                    </a>
                </div>

                @if (!isAuthView()) {
                    <div class="tw-flex tw-items-center tw-justify-between lg:tw-pl-8 2xl:tw-w-full 2xl:tw-pl-5">
                        <div class="tw-hidden 2xl:tw-block">
                            @if (canCompose$ | async) {
                                <button
                                    type="button"
                                    chroma-button
                                    class="tw-cursor-default"
                                    cdkOverlayOrigin
                                    #articleMenuTrigger="cdkOverlayOrigin"
                                    (click)="isArticleMenuOpen = isArticleMenuOpen === 'desktop' ? false : 'desktop'">
                                    <span>Artikel</span>
                                    <span
                                        class="material-symbols-rounded tw-icon-xl"
                                        [ngClass]="{ 'tw-filled-symbol': isArticleMenuOpen || isComposeArticleDialogOpen() || isRouteActive('/feed/templates') }"
                                        icon-right
                                        >stylus_note</span
                                    >
                                </button>

                                <ng-template
                                    cdkConnectedOverlay
                                    [cdkConnectedOverlayOrigin]="articleMenuTrigger"
                                    [cdkConnectedOverlayOpen]="isArticleMenuOpen === 'desktop'"
                                    [cdkConnectedOverlayHasBackdrop]="true"
                                    cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop-soft"
                                    (overlayOutsideClick)="isArticleMenuOpen = false"
                                    (overlayKeydown)="isArticleMenuOpen = false">
                                    <div class="tw-mt-2.5 tw-flex tw-flex-col tw-rounded tw-bg-white tw-py-1.25 tw-shadow-soft">
                                        <button
                                            type="button"
                                            class="tw-cursor-default tw-px-3.5 tw-py-1.25 hover:tw-bg-primary-hover-light"
                                            (click)="startComposing()">
                                            Neuen Artikel erstellen
                                        </button>
                                        @if (featureFlags.includes('CONTENT_TEMPLATES')) {
                                            <a
                                                class="tw-cursor-default tw-px-3.5 tw-py-1.25 hover:tw-bg-primary-hover-light"
                                                routerLink="/feed/templates"
                                                >Zu meinen Vorlagen</a
                                            >
                                        }
                                    </div>
                                </ng-template>
                            }
                        </div>
                        <div class="tw-flex 2xl:tw-divide-x 2xl:tw-divide-neutral-400">
                            <div class="tw-hidden tw-pr-1.25 2xl:tw-block">
                                <a
                                    chroma-button
                                    appearance="text"
                                    class="tw-cursor-default"
                                    routerLink="/feed/bookmarks"
                                    routerLinkActive
                                    #rla="routerLinkActive">
                                    <span
                                        class="material-symbols-rounded tw-icon-xl"
                                        icon-left
                                        [ngClass]="{ 'tw-filled-symbol': rla.isActive && !isComposeArticleDialogOpen() }"
                                        >bookmarks</span
                                    >
                                    <span>Meine Leseliste</span>
                                </a>
                            </div>
                            <div class="2xl:tw-pl-5">
                                <button
                                    type="button"
                                    chroma-button
                                    size="icon"
                                    class="tw-cursor-default"
                                    cdkOverlayOrigin
                                    #profileMenuTrigger="cdkOverlayOrigin"
                                    [class.tw-pointer-events-none]="isProfileMenuOpen$ | async"
                                    (click)="toggleProfileMenu()">
                                    <span
                                        class="material-symbols-rounded tw-icon-xl"
                                        [ngClass]="{
                                            'tw-filled-symbol': isProfileMenuOpen$ | async
                                        }"
                                        >account_circle</span
                                    >
                                </button>
                                <ng-template
                                    cdkConnectedOverlay
                                    [cdkConnectedOverlayOrigin]="profileMenuTrigger"
                                    [cdkConnectedOverlayOpen]="isProfileMenuOpen$ | async"
                                    [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]"
                                    [cdkConnectedOverlayHasBackdrop]="true"
                                    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
                                    cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop-soft"
                                    (overlayOutsideClick)="toggleProfileMenu()"
                                    (overlayKeydown)="toggleProfileMenu()">
                                    <div class="tw-relative tw-left-5 tw-mt-[11px] tw-w-screen tw-border-neutral-100 tw-bg-white tw-px-5 tw-py-7.5 tw-shadow-soft sm:tw-left-0 sm:tw-mt-2.5 sm:tw-w-[420px] sm:tw-rounded sm:tw-border sm:tw-border-solid sm:tw-shadow-soft">
                                        <div class="tw-flex tw-flex-col tw-gap-y-7.5">
                                            @if (authUser$ | async; as user) {
                                                <div class="tw-flex tw-items-center tw-gap-x-2.5">
                                                    @if (user.profilePictureId) {
                                                        <img
                                                            class="tw-h-10 tw-w-10 tw-rounded-full tw-object-contain"
                                                            [src]="resolveDerifilesImage(user.profilePictureId)" />
                                                    } @else {
                                                        <span class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-base">
                                                            <span class="tw-text-base tw-leading-none tw-text-white">{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0) }}</span>
                                                        </span>
                                                    }
                                                    <span class="tw-text-lg tw-font-semibold tw-text-primary-base">{{ user.firstname }} {{ user.lastname }}</span>
                                                </div>
                                                <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                                                    <div class="tw-flex tw-flex-col tw-gap-y-1.25 tw-text-sm">
                                                        <span class="tw-text-neutral-400">Benutzername</span>
                                                        <span class="tw-text-black">{{ user.username }}</span>
                                                    </div>
                                                    <div class="tw-flex tw-flex-col tw-gap-y-1.25 tw-text-sm">
                                                        <span class="tw-text-neutral-400">E-Mail</span>
                                                        <span class="tw-text-black">{{ user.email }}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div class="tw-flex tw-justify-between">
                                                @if (innerWidth >= 1536) {
                                                    <a
                                                        chroma-button
                                                        appearance="outline"
                                                        [href]="dericonIdLink"
                                                        target="_blank"
                                                        >Profileinstellungen</a
                                                    >
                                                } @else {
                                                    <button
                                                        type="button"
                                                        chroma-button
                                                        appearance="outline"
                                                        #tooltip="chromaTooltip"
                                                        chromaTooltip="Profileinstellungen sind mobil leider nicht verfügbar. Bitte nutzen Sie hierfür die Desktop-Version von Mercury. Danke für Ihr Verständnis!"
                                                        (click)="tooltip.toggle()">
                                                        Profileinstellungen
                                                    </button>
                                                }
                                                <button
                                                    type="button"
                                                    chroma-button
                                                    class="tw-cursor-default"
                                                    (click)="logOut()">
                                                    <span>Log out</span>
                                                    <span
                                                        class="material-symbols-rounded tw-icon-xl"
                                                        icon-right
                                                        >logout</span
                                                    >
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

@if (!isAuthView()) {
    <div class="tw-h-top-bar lg:tw-h-top-bar-lg"></div>

    <div
        class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-[1100] tw-border-t tw-border-t-border-separator tw-bg-white tw-px-5 tw-py-3.75 2xl:tw-hidden"
        cdkOverlayOrigin
        #articleMenuTrigger="cdkOverlayOrigin">
        <div class="tw-flex tw-justify-center">
            <div
                class="tw-grid tw-gap-x-5"
                [ngClass]="{
                    'tw-grid-cols-2': canCompose$ | async
                }">
                <a
                    type="button"
                    chroma-button
                    [size]="innerWidth >= 640 ? 'base' : 'icon'"
                    appearance="outline"
                    class="tw-cursor-default"
                    routerLink="/feed/bookmarks"
                    routerLinkActive
                    #rla="routerLinkActive"
                    ><span
                        class="material-symbols-rounded tw-icon-xl"
                        [ngClass]="{ 'tw-filled-symbol': rla.isActive && !isComposeArticleDialogOpen() }"
                        >bookmarks</span
                    >
                    <span class="tw-hidden sm:tw-inline">Leseliste</span></a
                >
                @if (canCompose$ | async) {
                    <ng-container>
                        <button
                            type="button"
                            chroma-button
                            [size]="innerWidth >= 640 ? 'base' : 'icon'"
                            appearance="outline"
                            class="tw-cursor-default"
                            [ngClass]="{
                                'tw-pointer-events-none': isArticleMenuOpen
                            }"
                            (click)="isArticleMenuOpen = isArticleMenuOpen === 'mobile' ? false : 'mobile'">
                            <span
                                class="material-symbols-rounded tw-icon-xl"
                                [ngClass]="{ 'tw-filled-symbol': isArticleMenuOpen || isComposeArticleDialogOpen() || isRouteActive('/feed/templates') }"
                                >stylus_note</span
                            >
                            <span class="tw-hidden sm:tw-inline">Artikel</span>
                        </button>
                        <ng-template
                            cdkConnectedOverlay
                            [cdkConnectedOverlayOrigin]="articleMenuTrigger"
                            [cdkConnectedOverlayOpen]="isArticleMenuOpen === 'mobile'"
                            [cdkConnectedOverlayPositions]="[{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]"
                            [cdkConnectedOverlayHasBackdrop]="true"
                            cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop-soft"
                            (overlayOutsideClick)="isArticleMenuOpen = false">
                            <div class="tw-flex tw-w-screen tw-flex-col tw-gap-y-5 tw-px-5 tw-py-10 sm:tw-bg-init-bg/90 sm:tw-backdrop-blur">
                                <button
                                    type="button"
                                    chroma-button
                                    (click)="startComposing()">
                                    Neuen Artikel erstellen
                                </button>
                                @if (featureFlags.includes('CONTENT_TEMPLATES')) {
                                    <a
                                        class="tw-px-3.5 tw-py-1.25"
                                        chroma-button
                                        appearance="outline"
                                        routerLink="/feed/templates"
                                        >Zu meinen Vorlagen</a
                                    >
                                }
                            </div>
                        </ng-template>
                    </ng-container>
                }
            </div>
        </div>
    </div>
}
