import { Component, inject, OnInit } from '@angular/core';
import {
    ButtonComponent,
    CHROMA_DIALOG_DATA,
    ChromaDialogHeader,
    ChromaDialogRef,
    ChromaFormField,
    ChromaInput
} from 'chroma-ui';
import { ComposeTemplateDialogData } from './compose-template-dialog.type';
import { JSONContent } from '@tiptap/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AttachmentsApi } from '../../../api/attachments.api';
import { take } from 'rxjs';
import { ContentsApi } from '../../../api/contents.api';
import { EditorComponent } from '../../editor/editor.component';

@Component({
    selector: 'app-compose-template-dialog',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ChromaDialogHeader,
        EditorComponent,
        ButtonComponent,
        ChromaFormField,
        ChromaInput
    ],
    templateUrl: './compose-template-dialog.component.html'
})
export class ComposeTemplateDialogComponent implements OnInit {
    data: ComposeTemplateDialogData = inject(CHROMA_DIALOG_DATA);

    maxCoverFileSize = 1;

    composeTemplateForm: FormGroup<{
        title: FormControl<string>;
        body: FormControl<JSONContent>;
        coverImageId: FormControl<string>;
        tags: FormControl<Array<any>>;
    }>;

    get coverImageIdControl() {
        return this.composeTemplateForm.controls.coverImageId;
    }

    constructor(
        private dialogRef: ChromaDialogRef<ComposeTemplateDialogComponent>,
        private contentsApi: ContentsApi,
        private attachmentsApi: AttachmentsApi
    ) {}

    ngOnInit(): void {
        const { mode, template } = this.data;

        this.composeTemplateForm = new FormGroup({
            title: new FormControl(mode === 'edit' ? template.title : '', Validators.required),
            body: new FormControl(mode === 'edit' ? template.body : null, Validators.required),
            coverImageId: new FormControl(mode === 'edit' ? template.coverImageId : null),
            tags: new FormControl([])
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    uploadCoverImage(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            if (file.size > this.maxCoverFileSize * 1024 * 1024) {
                this.coverImageIdControl.setErrors({
                    fileSizeExceeded: true
                });
            } else {
                this.attachmentsApi
                    .uploadFile(file)
                    .pipe(take(1))
                    .subscribe(data => this.coverImageIdControl.setValue(data.fileId));
            }
        }
    }

    removeCoverImage(): void {
        this.coverImageIdControl.setValue(null);
    }

    onUpsertTemplate(): void {
        if (this.composeTemplateForm.valid) {
            const composedTemplate = this.composeTemplateForm.getRawValue();

            if (this.data.mode === 'edit') {
                this.contentsApi
                    .updateTemplate(this.data.template.id, composedTemplate)
                    .subscribe(() =>
                        this.dialogRef.close({
                            updated: true
                        })
                    );
            } else {
                this.contentsApi.createTemplate(composedTemplate).subscribe(() =>
                    this.dialogRef.close({
                        created: true
                    })
                );
            }
        } else {
            this.composeTemplateForm.markAllAsTouched();
        }
    }
}
