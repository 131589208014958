import {
    ENV_CONFIG_KEY,
    EnvironmentConfig,
    FeatureFlag
} from '../../core/environment/types/environment.type';

export function getFeatureFlags(): Array<FeatureFlag> {
    return localStorage.getItem(ENV_CONFIG_KEY)
        ? (JSON.parse(localStorage.getItem(ENV_CONFIG_KEY)) as EnvironmentConfig).featureFlags
        : [];
}
