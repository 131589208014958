import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfig } from '../types/environment.type';
import { MercuryApi } from '../../../shared/api/mercury.api';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentApi extends MercuryApi {
    constructor(http: HttpClient) {
        super('', http);
    }

    getConfig(): Observable<EnvironmentConfig> {
        return this.get<EnvironmentConfig>('config');
    }
}
