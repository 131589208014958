import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SearchResult, SolrResultsResponse } from '../../../types/solr.type';
import { SolrApi } from '../../../api/solr.api';
import { Observable, switchMap, tap } from 'rxjs';

interface CreateDialogState {
    solr: {
        products: Array<SearchResult>;
    };
}

@Injectable()
export class ComposeArticleDialogStore extends ComponentStore<CreateDialogState> {
    constructor(private solrApi: SolrApi) {
        super({
            solr: {
                products: []
            }
        });
    }

    readonly searchSolr = this.effect(
        (
            params$: Observable<{
                term: string;
            }>
        ) =>
            params$.pipe(
                switchMap(({ term }) =>
                    this.solrApi.getResults(term).pipe(tap(data => this.setSolrProducts(data)))
                )
            )
    );

    readonly setSolrProducts = this.updater((state, results: SolrResultsResponse) => ({
        ...state,
        solr: {
            ...state.solr,
            products: results.grouped.instrumentType.groups.flatMap(group => group.doclist.docs)
        }
    }));

    readonly solrProducts$: Observable<Array<SearchResult>> = this.select(
        state => state.solr.products
    );
}
