import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SolrResultsResponse } from '../types/solr.type';

@Injectable({ providedIn: 'root' })
export class SolrApi {
    constructor(private http: HttpClient) {}

    getResults(term: string): Observable<SolrResultsResponse> {
        return this.http.get<SolrResultsResponse>(`search/${term}`).pipe(map(response => response));
    }
}
