import { AfterContentInit, Component, ContentChild, ContentChildren, ElementRef, HostBinding, HostListener, InjectionToken, QueryList} from "@angular/core";
import { cva } from "cva";
import { ChromaSuffix, CHROMA_SUFFIX } from "./directives/suffix";
import { ChromaFormFieldControl } from "./form-field-control";

export const formFieldStyle = cva([
    'tw-w-[180px]',
    'tw-inline-flex', 'tw-flex-auto', 'tw-gap-x-3.5',
    'tw-py-1.75', 'tw-px-3.5',
    'tw-bg-white',
    'tw-rounded', 'dark:tw-rounded-none',
    'tw-ring-inset', 'tw-ring-1', 'tw-ring-neutral-400', 'dark:tw-ring-old-gray-400'
]);

export const CHROMA_FORM_FIELD = new InjectionToken<ChromaFormField>('ChromaFormField');

@Component({
    selector: 'chroma-form-field',
    templateUrl: './form-field.html',
    standalone: true,
    host: {
        '[class.tw-ring-2]': '_control.focused',
        '[class.tw-ring-primary-focus]': '_control.focused',
        '[class.dark:tw-ring-primary-focus]': '_control.focused',
        '[class.tw-ring-red-600]': '_control.errorState',
        '[class.dark:tw-ring-red-600]': '_control.errorState',
        '[class.tw-cursor-pointer]': '_control.pointer && !_control.disabled && !_control.panelOpen',
        '[class.tw-pointer-events-none]': '_control.panelOpen'
    },
    providers: [{provide: CHROMA_FORM_FIELD, useExisting: ChromaFormField}]
})
export class ChromaFormField implements AfterContentInit {

    @ContentChild(ChromaFormFieldControl) _control: ChromaFormFieldControl;
    @ContentChildren(CHROMA_SUFFIX, {descendants: true}) _suffixChildren: QueryList<ChromaSuffix>;

    @HostBinding('class')
    hostClasses = formFieldStyle();

    @HostBinding('class.tw-pr-[10px]')
    hasIconSuffix = false;

    @HostListener('click', ['$event'])
    handleClickEvent(event) {
        this._control.onContainerClick(event);
    }

    constructor(public _elementRef: ElementRef) { }

    ngAfterContentInit() {
        this.hasIconSuffix = this._suffixChildren.length > 0 || this._control.pointer;
    }

    getConnectedOverlayOrigin(): ElementRef {
        return this._elementRef;
    }
}
