import {
    ConnectionPositionPair,
    GlobalPositionStrategy,
    OverlayModule,
    ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { Component, HostListener, input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@shared/environments/environment';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { ImageReferenceResolverService } from '../../services/image-reference-resolver.service';
import * as AuthSelectors from '../../../core/auth/store/auth.selectors';
import { AuthActions } from '../../../core/auth/store/auth.actions';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { DERICON_ID_PROD, DERICON_ID_TEST } from '../../../../../../../shared/variables/dericon-id';
import { ButtonComponent, ChromaDialog, ChromaDialogRef, ChromaTooltipModule } from 'chroma-ui';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { Platform } from '@angular/cdk/platform';
import { ComposeArticleDialogComponent } from '../dialogs/compose-article-dialog/compose-article-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { ComposeArticleDialogData } from '../dialogs/compose-article-dialog/compose-article-dialog.type';

@Component({
    selector: 'app-navigation-bars',
    standalone: true,
    imports: [AsyncPipe, NgClass, NgStyle, ButtonComponent, OverlayModule, ChromaTooltipModule],
    templateUrl: './navigation-bars.component.html'
})
export class NavigationBarsComponent implements OnInit {
    isAuthView = input(false);

    logo = environment.logo;
    logoHeight = environment.logoHeight;
    innerWidth = window.innerWidth;
    overlayPositions = [
        new ConnectionPositionPair(
            {
                originX: 'end',
                originY: 'bottom'
            },
            {
                overlayX: 'end',
                overlayY: 'top'
            }
        )
    ];
    scrollStrategy = this.sso.block();

    composeArticleDialogRef: ChromaDialogRef<ComposeArticleDialogComponent>;

    get dericonIdLink(): string {
        return `https://${environment.production ? DERICON_ID_PROD : DERICON_ID_TEST}`;
    }

    authUser$ = this.store.select(AuthSelectors.selectAuthUser);
    isSidebarOpen$ = this.store.select(UISelectors.selectIsSidebarOpen);
    isProfileMenuOpen$ = this.store.select(UISelectors.selectIsProfileMenuOpen);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private imageResolver: ImageReferenceResolverService,
        private sso: ScrollStrategyOptions,
        private platform: Platform,
        private dialog: ChromaDialog
    ) {}

    ngOnInit(): void {
        this.router.events.subscribe(() =>
            this.dialog.closeAll()
        );
    }

    createArticle(): void {
        this.dialog.closeAll();

        this.composeArticleDialogRef = this.dialog.open<
            ComposeArticleDialogComponent,
            ComposeArticleDialogData
        >(ComposeArticleDialogComponent, {
            data: {
                editMode: false,
                article: {
                    categoryId: this.route.snapshot.firstChild.paramMap.get('categoryId')
                }
            },
            defaultContainer: false,
            positionStrategy: new GlobalPositionStrategy()
        });

        this.composeArticleDialogRef
            .afterClosed()
            .pipe(tap(() => (this.composeArticleDialogRef = undefined)))
            .subscribe();
    }

    toggleSidebar(): void {
        this.store.dispatch(UIActions.toggleSidebar());
    }

    toggleProfileMenu(): void {
        this.store.dispatch(UIActions.toggleProfileMenu());
    }

    resolveDerifilesImage(ref: string): string {
        return this.imageResolver.resolveRemoteImagePath(ref);
    }

    logOut(): void {
        this.store.dispatch(AuthActions.logOut());
    }

    @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;
    }

    platformSupportsMouseEvents() {
        return !this.platform.IOS && !this.platform.ANDROID;
    }
}
