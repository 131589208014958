import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryViewStore } from './category-view.store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { combineLatest, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { ContentsApi } from '../../../shared/api/contents.api';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChromaLoading, ChromaSlideToggleModule } from 'chroma-ui';
import { Store } from '@ngrx/store';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { ArticleIntro } from '../../../shared/types/contents.type';

@Component({
    selector: 'app-category-view',
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        DatePipe,
        RouterLink,
        NgTemplateOutlet,
        ChromaLoading,
        ChromaSlideToggleModule
    ],
    providers: [CategoryViewStore, ContentsApi],
    templateUrl: './category-view.component.html'
})
export class CategoryViewComponent implements OnInit, OnDestroy {
    restrictToMustRead = false;
    restrictToUnread = false;
    currentCategoryId: string;

    introsPinned$ = this.categoryViewStore.introsPinned$;
    introsRegular$ = this.categoryViewStore.introsRegular$;
    introsLoading$ = this.categoryViewStore.introsLoading$;
    introsEndReached$ = this.categoryViewStore.introsEndReached$;

    private destroy$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private readonly categoryViewStore: CategoryViewStore
    ) {}

    ngOnInit(): void {
        this.route.params.pipe(map(params => params.categoryId)).subscribe(categoryId => {
            this.currentCategoryId = this.currentCategoryId || categoryId;

            this.restrictToMustRead = false;
            this.restrictToUnread = false;

            this.categoryViewStore.initSession({
                categoryId,
                restrictToMustRead: this.restrictToMustRead,
                restrictToUnread: this.restrictToUnread,
                navigated: this.currentCategoryId !== categoryId,
                filter: false
            });

            this.currentCategoryId = categoryId;
        });

        combineLatest([
            this.categoryViewStore.initialLoad$,
            this.categoryViewStore.introsEndReached$,
            this.store.select(UISelectors.selectScrolledToEnd)
        ])
            .pipe(
                filter(
                    ([initialLoad, endReached, multiplier]) =>
                        !initialLoad && !endReached && multiplier > 0
                ),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.categoryViewStore.loadNextSet());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    asIntros(intros: any): Array<ArticleIntro> {
        return intros;
    }

    filterByStatus(property: string, value: boolean): void {
        this.store.dispatch(UIActions.resetScrolledToEnd());

        this[property] = value;

        this.categoryViewStore.initSession({
            categoryId: this.route.snapshot.params.categoryId,
            restrictToMustRead: this.restrictToMustRead,
            restrictToUnread: this.restrictToUnread,
            navigated: false,
            filter: true
        });
    }
}
