<div class="tw-flex tw-flex-col tw-gap-y-10">
    <div class="tw-flex tw-items-start tw-justify-between tw-gap-x-2.5 tw-px-5 sm:tw-px-0">
        <h1 class="tw-text-2.25xl tw-leading-7.5 sm:tw-text-3xl">Meine Vorlagen</h1>
        <button
            type="button"
            chroma-button
            class="tw-cursor-default"
            (click)="onCreateTemplate()">
            <span>Erstellen</span>
            <span
                class="material-symbols-rounded tw-icon-xl"
                icon-right
                >add_circle</span
            >
        </button>
    </div>
    @if (templatesLoading$ | async) {
        <div class="tw-fixed tw-left-0 tw-top-top-bar tw-flex tw-h-[calc(100%-theme(height.top-bar-lg))] tw-w-full tw-items-center tw-justify-center sm:tw-top-top-bar-lg 2xl:tw-left-64 2xl:tw-w-[calc(100%-theme(space.64))]">
            <chroma-loading />
        </div>
    } @else {
        @if ((templates$ | async).length) {
            <div class="tw-overflow-auto tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <table class="tw-min-w-full md:tw-divide-y md:tw-divide-border-separator">
                    <thead class="tw-sticky tw-top-0 tw-hidden md:tw-table-header-group">
                        <tr>
                            <th class="tw-py-2.5 tw-text-left tw-font-normal tw-text-secondary">Name</th>
                            <th class="tw-py-2.5 tw-text-left tw-font-normal tw-text-secondary">Erstellt</th>
                            <th class="tw-py-2.5 tw-text-left tw-font-normal tw-text-secondary">Zuletzt geändert</th>
                            <th class="tw-py-2.5 tw-text-left tw-font-normal tw-text-secondary"></th>
                        </tr>
                    </thead>
                    <tbody class="tw-divide-y tw-divide-border-separator">
                        @for (template of templates$ | async; track $index) {
                            <tr>
                                <td class="tw-py-2.5 tw-pr-2.5 tw-align-top sm:tw-w-1/2 md:tw-align-middle">
                                    <span class="tw-inline-flex tw-flex-col tw-gap-y-2.5">
                                        <span class="tw-line-clamp-3 tw-min-h-[38px] tw-font-semibold md:tw-min-h-0 xl:tw-line-clamp-2">{{ template.title }}</span>
                                        <span class="tw-inline-flex tw-flex-col tw-gap-y-1.25 md:tw-hidden">
                                            <span class="tw-inline-flex tw-gap-x-1.25">
                                                <span class="material-symbols-rounded tw-icon-xl">add_circle</span>
                                                <span>{{ template.createdAt | date: 'dd.MM.yyyy' }}</span>
                                            </span>
                                            @if (template.updatedAt) {
                                                <span class="tw-inline-flex tw-gap-x-1.25">
                                                    <span class="material-symbols-rounded tw-icon-xl">edit</span>
                                                    <span>{{ (template.updatedAt | date: 'dd.MM.yyyy') ?? '&mdash;' }}</span>
                                                </span>
                                            }
                                        </span>
                                    </span>
                                </td>
                                <td class="tw-hidden tw-py-2.5 tw-pr-2.5 md:tw-table-cell">{{ template.createdAt | date: 'dd.MM.yyyy' }}</td>
                                <td class="tw-hidden tw-py-2.5 tw-pr-2.5 md:tw-table-cell">{{ (template.updatedAt | date: 'dd.MM.yyyy') ?? '&mdash;' }}</td>
                                <td class="tw-flex tw-flex-wrap tw-justify-end tw-gap-2.5 tw-py-2.5 tw-pl-2.5 sm:tw-flex-nowrap">
                                    <button
                                        type="button"
                                        chroma-button
                                        size="icon"
                                        appearance="outline"
                                        class="tw-order-2 tw-cursor-default sm:tw-order-none"
                                        (click)="onEditTemplate(template)">
                                        <span class="material-symbols-rounded tw-icon-xl">edit</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        size="icon"
                                        appearance="outline"
                                        class="tw-order-1 tw-cursor-default sm:tw-order-none"
                                        (click)="onDeleteTemplate(template)">
                                        <span class="material-symbols-rounded tw-icon-xl">delete</span>
                                    </button>
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        (click)="startComposing(template)">
                                        Auswählen
                                    </button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        } @else {
            <div class="tw-flex tw-justify-center tw-px-3.5 tw-py-6">
                <span class="tw-text-center tw-text-neutral-400"> Keine Einträge gefunden </span>
            </div>
        }
    }
</div>
