import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Template } from '../../../shared/types/contents.type';
import { Observable, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../shared/api/contents.api';

interface TemplateViewState {
    loading: boolean;
    data: Array<Template>;
}

@Injectable()
export class TemplatesViewStore extends ComponentStore<TemplateViewState> {
    constructor(private contentsApi: ContentsApi) {
        super({
            loading: false,
            data: []
        });
    }

    readonly loadTemplates = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(() =>
                this.contentsApi.getTemplates().pipe(
                    tap(data => this.setTemplates(data)),
                    tap(() => this.setLoading(false))
                )
            )
        )
    );

    readonly setLoading = this.updater(
        (state, loading: boolean): TemplateViewState => ({
            ...state,
            loading
        })
    );

    readonly setTemplates = this.updater(
        (state, data: Array<Template>): TemplateViewState => ({
            ...state,
            data
        })
    );

    readonly templatesLoading$: Observable<boolean> = this.select(state => state.loading);
    readonly templates$: Observable<Array<Template>> = this.select(state => state.data);
}
