import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MercuryApi } from './mercury.api';
import { FileAttachment } from '../types/attachment.type';

@Injectable({
    providedIn: 'root'
})
export class AttachmentsApi extends MercuryApi {
    constructor(http: HttpClient) {
        super('attachments', http);
    }

    uploadFile(file: File): Observable<FileAttachment> {
        const formData = new FormData();
        formData.append('file', file);

        return this.post<FileAttachment>('', formData).pipe(map(response => response));
    }
}
