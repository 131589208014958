import { GlobalPositionStrategy, OverlayModule, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, HostListener, input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@shared/environments/environment';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { ImageReferenceResolverService } from '../../services/image-reference-resolver.service';
import * as AuthSelectors from '../../../core/auth/store/auth.selectors';
import { AuthActions } from '../../../core/auth/store/auth.actions';
import { AsyncPipe, NgClass } from '@angular/common';
import { DERICON_ID_PROD, DERICON_ID_TEST } from '../../../../../../../shared/variables/dericon-id';
import { ButtonComponent, ChromaDialog, ChromaTooltipModule } from 'chroma-ui';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { ComposeArticleDialogComponent } from '../dialogs/compose-article-dialog/compose-article-dialog.component';
import {
    ActivatedRoute,
    NavigationStart,
    Router,
    RouterLink,
    RouterLinkActive,
    UrlTree
} from '@angular/router';
import { filter } from 'rxjs';
import { ComposeArticleDialogData } from '../dialogs/compose-article-dialog/compose-article-dialog.type';
import * as NavigationSelectors from '../../../core/navigation/store/navigation.selectors';
import { getFeatureFlags } from '../../helpers/local-storage.helper';

@Component({
    selector: 'app-navigation-bars',
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        RouterLink,
        RouterLinkActive,
        ButtonComponent,
        OverlayModule,
        ChromaTooltipModule
    ],
    templateUrl: './navigation-bars.component.html'
})
export class NavigationBarsComponent implements OnInit {
    isAuthView = input(false);

    logo = environment.logo;
    logoHeight = environment.logoHeight;
    innerWidth = window.innerWidth;
    scrollStrategy = this.sso.block();

    featureFlags = getFeatureFlags();

    get dericonIdLink(): string {
        return `https://${environment.production ? DERICON_ID_PROD : DERICON_ID_TEST}`;
    }

    isArticleMenuOpen: 'mobile' | 'desktop' | false = false;

    authUser$ = this.store.select(AuthSelectors.selectAuthUser);
    isSidebarOpen$ = this.store.select(UISelectors.selectIsSidebarOpen);
    isProfileMenuOpen$ = this.store.select(UISelectors.selectIsProfileMenuOpen);
    canCompose$ = this.store.select(NavigationSelectors.canCompose);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private imageResolver: ImageReferenceResolverService,
        private sso: ScrollStrategyOptions,
        private dialog: ChromaDialog
    ) {}

    ngOnInit(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
            this.isArticleMenuOpen = false;
            this.dialog.closeAll();
        });
    }

    startComposing(): void {
        this.isArticleMenuOpen = false;
        this.dialog.closeAll();

        this.dialog.open<ComposeArticleDialogComponent, ComposeArticleDialogData>(
            ComposeArticleDialogComponent,
            {
                data: {
                    mode: 'create',
                    article: {
                        categoryId: this.route.snapshot.firstChild.paramMap.get('categoryId')
                    }
                },
                defaultContainer: false,
                positionStrategy: new GlobalPositionStrategy()
            }
        );
    }

    toggleSidebar(): void {
        this.dialog.closeAll();

        this.store.dispatch(UIActions.toggleSidebar());
    }

    toggleProfileMenu(): void {
        this.isArticleMenuOpen = false;

        this.store.dispatch(UIActions.toggleProfileMenu());
    }

    isRouteActive(url: string | UrlTree): boolean {
        return this.router.isActive(url, {
            paths: 'exact',
            queryParams: 'exact',
            fragment: 'ignored',
            matrixParams: 'ignored'
        });
    }

    isComposeArticleDialogOpen(): boolean {
        return this.dialog.openDialogs.some(
            dialog => dialog.componentInstance instanceof ComposeArticleDialogComponent
        );
    }

    resolveDerifilesImage(ref: string): string {
        return this.imageResolver.resolveRemoteImagePath(ref);
    }

    logOut(): void {
        this.store.dispatch(AuthActions.logOut());
    }

    @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;

        if (this.innerWidth >= 1536 && this.isArticleMenuOpen === 'mobile') {
            this.isArticleMenuOpen = 'desktop';
        } else if (this.innerWidth < 1536 && this.isArticleMenuOpen === 'desktop') {
            this.isArticleMenuOpen = 'mobile';
        }
    }
}
