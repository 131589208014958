import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, HostBinding, Component, NgModule, ApplicationRef, createComponent, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FloatingMenuPlugin } from '@tiptap/extension-floating-menu';
import { BubbleMenuPlugin } from '@tiptap/extension-bubble-menu';
import { NodeView } from '@tiptap/core';
class EditorDirective {
  constructor(elRef, renderer, changeDetectorRef) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.changeDetectorRef = changeDetectorRef;
    this.outputFormat = 'html';
    this.onChange = () => {};
    this.onTouched = () => {};
    this.handleChange = ({
      editor,
      transaction
    }) => {
      if (!transaction.docChanged) {
        return;
      }
      // Needed for ChangeDetectionStrategy.OnPush to get notified about changes
      this.changeDetectorRef.markForCheck();
      if (this.outputFormat === 'html') {
        this.onChange(editor.getHTML());
        return;
      }
      this.onChange(editor.getJSON());
    };
  }
  // Writes a new value to the element.
  // This methods is called when programmatic changes from model to view are requested.
  writeValue(value) {
    if (!this.outputFormat && typeof value === 'string') {
      this.outputFormat = 'html';
    }
    this.editor.chain().setContent(value, false).run();
  }
  // Registers a callback function that is called when the control's value changes in the UI.
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // Registers a callback function that is called by the forms API on initialization to update the form model on blur.
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // Called by the forms api to enable or disable the element
  setDisabledState(isDisabled) {
    this.editor.setEditable(!isDisabled);
    this.renderer.setProperty(this.elRef.nativeElement, 'disabled', isDisabled);
  }
  ngOnInit() {
    if (!this.editor) {
      throw new Error('Required: Input `editor`');
    }
    // take the inner contents and clear the block
    const {
      innerHTML
    } = this.elRef.nativeElement;
    this.elRef.nativeElement.innerHTML = '';
    // insert the editor in the dom
    this.elRef.nativeElement.append(...Array.from(this.editor.options.element.childNodes));
    // update the options for the editor
    this.editor.setOptions({
      element: this.elRef.nativeElement
    });
    // update content to the editor
    if (innerHTML) {
      this.editor.chain().setContent(innerHTML, false).run();
    }
    // register blur handler to update `touched` property
    this.editor.on('blur', () => {
      this.onTouched();
    });
    // register update handler to listen to changes on update
    this.editor.on('update', this.handleChange);
    // Needed for ChangeDetectionStrategy.OnPush to get notified
    this.editor.on('selectionUpdate', () => this.changeDetectorRef.markForCheck());
  }
  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }
  static {
    this.ɵfac = function EditorDirective_Factory(t) {
      return new (t || EditorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: EditorDirective,
      selectors: [["tiptap", "editor", ""], ["", "tiptap", "", "editor", ""], ["tiptap-editor", "editor", ""], ["", "tiptapEditor", "", "editor", ""]],
      inputs: {
        editor: "editor",
        outputFormat: "outputFormat"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditorDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorDirective, [{
    type: Directive,
    args: [{
      selector: 'tiptap[editor], [tiptap][editor], tiptap-editor[editor], [tiptapEditor][editor]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditorDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }], {
    editor: [{
      type: Input
    }],
    outputFormat: [{
      type: Input
    }]
  });
})();
class FloatingMenuDirective {
  constructor(elRef) {
    this.elRef = elRef;
    this.pluginKey = 'NgxTiptapFloatingMenu';
    this.tippyOptions = {};
    this.shouldShow = null;
  }
  ngOnInit() {
    if (!this.editor) {
      throw new Error('Required: Input `editor`');
    }
    this.editor.registerPlugin(FloatingMenuPlugin({
      pluginKey: this.pluginKey,
      editor: this.editor,
      element: this.elRef.nativeElement,
      tippyOptions: this.tippyOptions,
      shouldShow: this.shouldShow
    }));
  }
  ngOnDestroy() {
    this.editor.unregisterPlugin(this.pluginKey);
  }
  static {
    this.ɵfac = function FloatingMenuDirective_Factory(t) {
      return new (t || FloatingMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FloatingMenuDirective,
      selectors: [["tiptap-floating-menu", "editor", ""], ["", "tiptapFloatingMenu", "", "editor", ""]],
      inputs: {
        pluginKey: "pluginKey",
        editor: "editor",
        tippyOptions: "tippyOptions",
        shouldShow: "shouldShow"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatingMenuDirective, [{
    type: Directive,
    args: [{
      selector: 'tiptap-floating-menu[editor], [tiptapFloatingMenu][editor]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    pluginKey: [{
      type: Input
    }],
    editor: [{
      type: Input
    }],
    tippyOptions: [{
      type: Input
    }],
    shouldShow: [{
      type: Input
    }]
  });
})();
class BubbleMenuDirective {
  constructor(elRef) {
    this.elRef = elRef;
    this.pluginKey = 'NgxTiptapBubbleMenu';
    this.tippyOptions = {};
    this.shouldShow = null;
  }
  ngOnInit() {
    if (!this.editor) {
      throw new Error('Required: Input `editor`');
    }
    this.editor.registerPlugin(BubbleMenuPlugin({
      pluginKey: this.pluginKey,
      editor: this.editor,
      element: this.elRef.nativeElement,
      tippyOptions: this.tippyOptions,
      shouldShow: this.shouldShow,
      updateDelay: this.updateDelay
    }));
  }
  ngOnDestroy() {
    this.editor.unregisterPlugin(this.pluginKey);
  }
  static {
    this.ɵfac = function BubbleMenuDirective_Factory(t) {
      return new (t || BubbleMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BubbleMenuDirective,
      selectors: [["tiptap-bubble-menu", "editor", ""], ["", "tiptapBubbleMenu", "", "editor", ""]],
      inputs: {
        pluginKey: "pluginKey",
        editor: "editor",
        tippyOptions: "tippyOptions",
        shouldShow: "shouldShow",
        updateDelay: "updateDelay"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BubbleMenuDirective, [{
    type: Directive,
    args: [{
      selector: 'tiptap-bubble-menu[editor], [tiptapBubbleMenu][editor]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    pluginKey: [{
      type: Input
    }],
    editor: [{
      type: Input
    }],
    tippyOptions: [{
      type: Input
    }],
    shouldShow: [{
      type: Input
    }],
    updateDelay: [{
      type: Input
    }]
  });
})();
class DraggableDirective {
  constructor() {
    this.draggable = true;
    this.handle = '';
  }
  static {
    this.ɵfac = function DraggableDirective_Factory(t) {
      return new (t || DraggableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DraggableDirective,
      selectors: [["", "tiptapDraggable", ""]],
      hostVars: 2,
      hostBindings: function DraggableDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("draggable", ctx.draggable)("data-drag-handle", ctx.handle);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DraggableDirective, [{
    type: Directive,
    args: [{
      selector: '[tiptapDraggable]'
    }]
  }], null, {
    draggable: [{
      type: HostBinding,
      args: ['attr.draggable']
    }],
    handle: [{
      type: HostBinding,
      args: ['attr.data-drag-handle']
    }]
  });
})();
class NodeViewContentDirective {
  constructor() {
    this.handle = '';
    this.whiteSpace = 'pre-wrap';
  }
  static {
    this.ɵfac = function NodeViewContentDirective_Factory(t) {
      return new (t || NodeViewContentDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NodeViewContentDirective,
      selectors: [["", "tiptapNodeViewContent", ""]],
      hostVars: 3,
      hostBindings: function NodeViewContentDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-node-view-content", ctx.handle);
          i0.ɵɵstyleProp("white-space", ctx.whiteSpace);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeViewContentDirective, [{
    type: Directive,
    args: [{
      selector: '[tiptapNodeViewContent]'
    }]
  }], null, {
    handle: [{
      type: HostBinding,
      args: ['attr.data-node-view-content']
    }],
    whiteSpace: [{
      type: HostBinding,
      args: ['style.white-space']
    }]
  });
})();
class AngularNodeViewComponent {
  static {
    this.ɵfac = function AngularNodeViewComponent_Factory(t) {
      return new (t || AngularNodeViewComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AngularNodeViewComponent,
      selectors: [["ng-component"]],
      inputs: {
        editor: "editor",
        node: "node",
        decorations: "decorations",
        selected: "selected",
        extension: "extension",
        getPos: "getPos",
        updateAttributes: "updateAttributes",
        deleteNode: "deleteNode"
      },
      decls: 0,
      vars: 0,
      template: function AngularNodeViewComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularNodeViewComponent, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], null, {
    editor: [{
      type: Input
    }],
    node: [{
      type: Input
    }],
    decorations: [{
      type: Input
    }],
    selected: [{
      type: Input
    }],
    extension: [{
      type: Input
    }],
    getPos: [{
      type: Input
    }],
    updateAttributes: [{
      type: Input
    }],
    deleteNode: [{
      type: Input
    }]
  });
})();
class NgxTiptapModule {
  static {
    this.ɵfac = function NgxTiptapModule_Factory(t) {
      return new (t || NgxTiptapModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxTiptapModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTiptapModule, [{
    type: NgModule,
    args: [{
      declarations: [EditorDirective, FloatingMenuDirective, BubbleMenuDirective, DraggableDirective, NodeViewContentDirective],
      exports: [EditorDirective, FloatingMenuDirective, BubbleMenuDirective, DraggableDirective, NodeViewContentDirective]
    }]
  }], null, null);
})();
class AngularRenderer {
  constructor(ViewComponent, injector, props) {
    this.applicationRef = injector.get(ApplicationRef);
    this.componentRef = createComponent(ViewComponent, {
      environmentInjector: this.applicationRef.injector,
      elementInjector: injector
    });
    // set input props to the component
    this.updateProps(props);
    this.applicationRef.attachView(this.componentRef.hostView);
  }
  get instance() {
    return this.componentRef.instance;
  }
  get elementRef() {
    return this.componentRef.injector.get(ElementRef);
  }
  get dom() {
    return this.elementRef.nativeElement;
  }
  updateProps(props) {
    Object.entries(props).forEach(([key, value]) => {
      this.componentRef.setInput(key, value);
    });
  }
  detectChanges() {
    this.componentRef.changeDetectorRef.detectChanges();
  }
  destroy() {
    this.componentRef.destroy();
    this.applicationRef.detachView(this.componentRef.hostView);
  }
}
class AngularNodeView extends NodeView {
  mount() {
    const injector = this.options.injector;
    const props = {
      editor: this.editor,
      node: this.node,
      decorations: this.decorations,
      selected: false,
      extension: this.extension,
      getPos: () => this.getPos(),
      updateAttributes: (attributes = {}) => this.updateAttributes(attributes),
      deleteNode: () => this.deleteNode()
    };
    this.handleSelectionUpdate = this.handleSelectionUpdate.bind(this);
    this.editor.on('selectionUpdate', this.handleSelectionUpdate);
    // create renderer
    this.renderer = new AngularRenderer(this.component, injector, props);
    // Register drag handler
    if (this.extension.config.draggable) {
      this.renderer.elementRef.nativeElement.ondragstart = e => {
        this.onDragStart(e);
      };
    }
    this.contentDOMElement = this.node.isLeaf ? null : document.createElement(this.node.isInline ? 'span' : 'div');
    if (this.contentDOMElement) {
      // For some reason the whiteSpace prop is not inherited properly in Chrome and Safari
      // With this fix it seems to work fine
      // See: https://github.com/ueberdosis/tiptap/issues/1197
      this.contentDOMElement.style.whiteSpace = 'inherit';
      // Required for editable node views
      // The content won't be rendered if `editable` is set to `false`
      this.renderer.detectChanges();
    }
    this.appendContendDom();
  }
  get dom() {
    return this.renderer.dom;
  }
  get contentDOM() {
    if (this.node.isLeaf) {
      return null;
    }
    return this.contentDOMElement;
  }
  appendContendDom() {
    const contentElement = this.dom.querySelector('[data-node-view-content]');
    if (this.contentDOMElement && contentElement && !contentElement.contains(this.contentDOMElement)) {
      contentElement.appendChild(this.contentDOMElement);
    }
  }
  handleSelectionUpdate() {
    const {
      from,
      to
    } = this.editor.state.selection;
    if (from <= this.getPos() && to >= this.getPos() + this.node.nodeSize) {
      this.selectNode();
    } else {
      this.deselectNode();
    }
  }
  update(node, decorations) {
    const updateProps = () => {
      this.renderer.updateProps({
        node,
        decorations
      });
    };
    if (this.options.update) {
      const oldNode = this.node;
      const oldDecorations = this.decorations;
      this.node = node;
      this.decorations = decorations;
      return this.options.update({
        oldNode,
        oldDecorations,
        newNode: node,
        newDecorations: decorations,
        updateProps: () => updateProps()
      });
    }
    if (node.type !== this.node.type) {
      return false;
    }
    if (node === this.node && this.decorations === decorations) {
      return true;
    }
    this.node = node;
    this.decorations = decorations;
    updateProps();
    return true;
  }
  selectNode() {
    this.renderer.updateProps({
      selected: true
    });
  }
  deselectNode() {
    this.renderer.updateProps({
      selected: false
    });
  }
  destroy() {
    this.renderer.destroy();
    this.editor.off('selectionUpdate', this.handleSelectionUpdate);
    this.contentDOMElement = null;
  }
}
const AngularNodeViewRenderer = (ViewComponent, options) => {
  return props => {
    return new AngularNodeView(ViewComponent, props, options);
  };
};

/*
 * Public API Surface of ngx-tiptap
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularNodeViewComponent, AngularNodeViewRenderer, AngularRenderer, BubbleMenuDirective, DraggableDirective, EditorDirective, FloatingMenuDirective, NgxTiptapModule, NodeViewContentDirective };
