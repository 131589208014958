import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { NavigationGroup, UnseenContents } from '../types/navigations.type';
import { MercuryApi } from '../../../shared/api/mercury.api';

@Injectable({
    providedIn: 'root'
})
export class NavigationsApi extends MercuryApi {
    constructor(http: HttpClient) {
        super('', http);
    }

    loadDisplays(): Observable<Array<NavigationGroup>> {
        return this.get<Array<NavigationGroup>>('navigations').pipe(map(response => response));
    }

    loadUnseen(): Observable<UnseenContents> {
        return this.get<UnseenContents>('unseen-contents').pipe(map(response => response));
    }
}
