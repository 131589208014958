<div class="tw-flex tw-gap-x-2.5">
    <button
        role="switch"
        type="button"
        [class]="buttonStyle({ checked })"
        [id]="buttonId"
        [disabled]="disabled"
        (click)="_handleClick()">
        <span
            class="tw-pointer-events-none tw-inline-block tw-h-3 tw-w-3 tw-rounded-full tw-transition tw-duration-200 tw-ease-in-out"
            [ngClass]="{
            'tw-translate-x-0 tw-bg-neutral-400': !checked,
            'tw-translate-x-4.5 tw-bg-primary-base': checked
        }"></span>
    </button>
    <label
        [class]="labelStyle({ disabled })"
        [for]="buttonId"
        (click)="$event.stopPropagation()"
        ><ng-content></ng-content
    ></label>
</div>
