import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState } from './navigation.state';
import { selectRouteParam } from '../../../shared/store/router/router.selectors';

export const selectNavigation = createFeatureSelector<NavigationState>('navigation');

export const selectNavigationGroups = createSelector(
    selectNavigation,
    selectRouteParam('categoryId'),
    (state, categoryId) =>
        state.groups.map(group => ({
            ...group,
            active: group.navigationItems.map(item => item.categoryId).includes(categoryId),
            navigationItems: group.navigationItems.map(item =>
                item.categoryId === categoryId
                    ? {
                          ...item,
                          active: true
                      }
                    : item
            )
        }))
);
