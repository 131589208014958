<div class="tw-fixed tw-left-0 tw-top-top-bar tw-z-20 tw-w-full tw-bg-surface-brand-light tw-px-5 tw-pb-5 tw-pt-7.5 sm:tw-w-screen sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable lg:tw-left-64 lg:tw-top-top-bar-lg lg:tw-w-[calc(100vw-256px)] lg:tw-px-8 2xl:tw-pt-12.5">
    <div class="tw-flex tw-justify-end tw-gap-x-5">
        <chroma-slide-toggle
            [checked]="restrictToMustRead === true"
            [disabled]="['initial', 'switch'].includes(introsLoading$ | async)"
            (change)="filterByStatus('restrictToMustRead', $event)"
            >lesepflichtige</chroma-slide-toggle
        >
        <chroma-slide-toggle
            [checked]="restrictToUnread === true"
            [disabled]="['initial', 'switch'].includes(introsLoading$ | async)"
            (change)="filterByStatus('restrictToUnread', $event)"
            >ungelesene</chroma-slide-toggle
        >
    </div>
</div>
<div class="tw-h-10"></div>

@if ((introsLoading$ | async) === 'initial') {
    <div class="tw-fixed tw-left-0 tw-top-top-bar tw-flex tw-h-[calc(100%-theme(height.top-bar-lg))] tw-w-full tw-items-center tw-justify-center sm:tw-top-top-bar-lg lg:tw-left-64 lg:tw-w-[calc(100%-theme(space.64))]">
        <chroma-loading />
    </div>
} @else {
    <div class="tw-mt-5 tw-flex tw-flex-col tw-gap-y-2.5">
        <div class="tw-flex tw-flex-col tw-gap-y-7.5">
            @if ((introsPinned$ | async).length) {
                <div class="tw-mx-5 tw-flex tw-items-center tw-gap-x-7.5 sm:tw-mx-0">
                    <span class="tw-inline-flex tw-items-center tw-gap-x-2.5">
                        <span class="material-symbols-rounded">push_pin</span>
                        <span class="tw-font-semibold tw-leading-5">Pinned</span>
                    </span>
                    <hr class="tw-flex-1 tw-border-b tw-border-black" />
                </div>
                <ng-container *ngTemplateOutlet="introTmpl; context: { intros: introsPinned$ | async }"></ng-container>
                @if ((introsRegular$ | async).length) {
                    <hr class="tw-mx-5 tw-flex-1 tw-border-b tw-border-black sm:tw-mx-0" />
                }
            }
            <ng-container *ngTemplateOutlet="introTmpl; context: { intros: introsRegular$ | async }"></ng-container>
            <ng-template
                #introTmpl
                let-intros="intros">
                <div class="4xl:tw-grid-cols-3 tw-grid tw-flex-1 tw-divide-y tw-divide-border-separator sm:tw-grid-cols-2 sm:tw-gap-2.5 sm:tw-divide-y-0 2xl:tw-grid-cols-2">
                    @for (intro of intros; track $index) {
                        @if ((introsLoading$ | async) === 'switch') {
                            <div class="tw-flex tw-h-[195px] tw-flex-col tw-justify-between tw-bg-white tw-px-5 tw-py-7.5 sm:tw-h-[224px] sm:tw-rounded sm:tw-shadow-soft">
                                <span class="tw-flex tw-animate-pulse tw-flex-col tw-gap-y-2">
                                    <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5">
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="tw-h-[25px] tw-w-[25px] tw-shrink-0 tw-rounded-full tw-bg-disabled-bg"></span>
                                            <span class="tw-h-[17px] tw-w-[200px] tw-rounded tw-bg-disabled-bg"></span>
                                        </span>
                                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                                            <span class="tw-h-6 tw-w-6 tw-bg-disabled-bg"></span>
                                            <span class="tw-h-6 tw-w-6 tw-bg-disabled-bg"></span>
                                        </span>
                                    </span>
                                    <span class="tw-flex tw-flex-col tw-gap-y-1.5">
                                        <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                                        <span class="tw-h-[17px] tw-w-3/4 tw-rounded tw-bg-disabled-bg"></span>
                                    </span>
                                    <span class="tw-hidden tw-flex-col tw-gap-y-1.5 sm:tw-flex">
                                        <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                                        <span class="tw-h-[17px] tw-w-full tw-rounded tw-bg-disabled-bg"></span>
                                    </span>
                                </span>
                                <span class="tw-flex tw-animate-pulse tw-justify-between">
                                    <span class="tw-h-[18px] tw-w-[190px] tw-rounded tw-bg-disabled-bg"></span>
                                    <span class="tw-h-[18px] tw-w-[50px] tw-rounded tw-bg-disabled-bg"> </span>
                                </span>
                            </div>
                        } @else {
                            <a
                                [routerLink]="'article/' + intro.id"
                                class="tw-relative tw-flex tw-h-[195px] tw-cursor-default tw-bg-white after:tw-absolute after:-tw-inset-px after:tw-z-10 after:tw-rounded after:hover:tw-ring-5 after:hover:tw-ring-inset after:hover:tw-ring-primary-focus sm:tw-h-[224px] sm:tw-rounded sm:tw-shadow-soft">
                                <span class="tw-flex tw-flex-grow tw-flex-col tw-justify-between tw-px-5 tw-py-7.5">
                                    <span class="tw-flex tw-flex-col tw-gap-y-2">
                                        <span class="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5">
                                            @if (intro.author.profilePictureId) {
                                                <img
                                                    class="tw-h-[25px] tw-w-[25px] tw-rounded-full tw-object-contain"
                                                    [ngClass]="{ 'tw-grayscale': intro.meta.seen }"
                                                    [src]="'/derifiles/' + intro.author.profilePictureId" />
                                            } @else {
                                                <span class="tw-inline-flex tw-h-[25px] tw-w-[25px] tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light">
                                                    <span class="material-symbols-rounded tw-text-primary-base">person</span>
                                                </span>
                                            }
                                            <span class="tw-flex-1 tw-text-sm tw-font-bold tw-leading-none tw-text-secondary">{{ intro.author.firstName }} {{ intro.author.lastName }}</span>
                                            @if (intro.mustRead) {
                                                <span
                                                    class="material-symbols-rounded"
                                                    [ngClass]="{
                                                        'tw-text-secondary': intro.meta.seen,
                                                        'tw-text-warning': !intro.meta.seen
                                                    }"
                                                    >flag</span
                                                >
                                            }
                                            @if (intro.meta.bookmarked) {
                                                <span
                                                    class="material-symbols-rounded"
                                                    [ngClass]="{ 'tw-text-secondary': intro.meta.seen }"
                                                    >bookmark_added</span
                                                >
                                            } @else {
                                                <span
                                                    class="material-symbols-rounded"
                                                    [ngClass]="{ 'tw-text-secondary': intro.meta.seen }"
                                                    >bookmark</span
                                                >
                                            }
                                        </span>
                                        <span
                                            class="tw-line-clamp-3 tw-font-bold tw-leading-5 sm:tw-line-clamp-2"
                                            [ngClass]="{ 'tw-text-secondary': intro.meta.seen }"
                                            >{{ intro.title }}</span
                                        >
                                        <span
                                            class="tw-hidden tw-leading-5 sm:tw-line-clamp-2"
                                            [ngClass]="{ 'tw-text-secondary': intro.meta.seen }"
                                            >{{ intro.intro }}</span
                                        >
                                    </span>
                                    <span class="tw-flex tw-justify-between tw-text-secondary">
                                        <span class="tw-text-sm tw-leading-4.5">Letzte Änderung: {{ intro.updatedAt | date: 'dd.MM.yyyy' }}</span>
                                        <span class="tw-flex tw-items-center tw-gap-x-2">
                                            <span class="material-symbols-rounded tw-text-base tw-leading-none">chat_bubble</span>
                                            <span class="tw-text-sm tw-leading-4.5">{{ intro.commentCount }}</span>
                                        </span>
                                    </span>
                                </span>
                                @if (intro.coverImageId) {
                                    <img
                                        class="tw-hidden tw-h-full tw-w-56 tw-rounded-r tw-object-cover 2xl:tw-block"
                                        [src]="'/derifiles/' + intro.coverImageId" />
                                }
                            </a>
                        }
                    }
                </div>
            </ng-template>
        </div>
        @if ((introsLoading$ | async) === 'next') {
            <div class="tw-flex tw-justify-center tw-py-6">
                <chroma-loading type="dot" />
            </div>
        }
        @if (introsEndReached$ | async) {
            <div class="tw-flex tw-justify-center tw-px-3.5 tw-py-6">
                <span class="tw-text-center tw-text-base tw-leading-5 tw-text-neutral-400">Keine älteren Einträge gefunden.</span>
            </div>
        }
    </div>
}
