import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
    ButtonComponent,
    ChromaFormField,
    ChromaInput,
    ChromaLoading,
    ChromaSuffix
} from 'chroma-ui';
import { environment } from '@shared/environments/environment';
import { DERICON_ID_PROD, DERICON_ID_TEST } from '@shared/variables/dericon-id';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../core/auth/store/auth.actions';
import * as AuthSelectors from '../../core/auth/store/auth.selectors';
import { NavigationBarsComponent } from '../../shared/components/navigation-bars/navigation-bars.component';
import { AuthError } from '../../core/auth/types/auth-error.type';

@Component({
    selector: 'app-login-page',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        AsyncPipe,
        ReactiveFormsModule,
        A11yModule,
        ChromaFormField,
        ChromaInput,
        ChromaSuffix,
        ButtonComponent,
        ChromaLoading,
        NavigationBarsComponent
    ],
    templateUrl: './login-page.component.html'
})
export class LoginPageComponent {
    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
    });

    hidePassword = true;
    authError = AuthError;
    returnUrl: string;

    get resetPasswordUrl(): string {
        return `https://${environment.production ? DERICON_ID_PROD : DERICON_ID_TEST}/forgot-password?returnUrl=${encodeURIComponent(window.location.href)}`;
    }

    loggingIn$ = this.store.select(AuthSelectors.selectAuthLoggingIn);
    authError$ = this.store.select(AuthSelectors.selectAuthError);

    constructor(private store: Store) {}

    onSubmit(): void {
        if (this.loginForm.valid) {
            this.store.dispatch(
                AuthActions.logIn({
                    username: this.loginForm.value.username,
                    password: this.loginForm.value.password,
                    returnUrl: this.returnUrl
                })
            );
        }
    }
}
