@if ((articleLoading$ | async) === false) {
    @if (article$ | async; as article) {
        <div class="tw-flex tw-flex-col tw-gap-y-7.5 sm:tw-gap-y-2.5 2xl:tw-mr-[23rem] 2xl:tw-pr-2.5">
            <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <app-article-content [article]="article">
                    <app-article-actions
                        [article]="article"
                        (clipboard)="onCopyToClipboard()"
                        (bookmark)="onArticleBookmark($event)"
                        (like)="onArticleLike($event)"
                        (print)="onArticlePrint()"
                        (delete)="onArticleDelete()"
                        (edit)="onArticleEdit(article.id)" />
                </app-article-content>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-y-5 tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <div class="tw-text-xl tw-leading-6">Diesen Artikel kommentieren</div>
                <app-comment-input
                    editorClasses="tw-min-h-12"
                    placeholder="Schreiben Sie hier Ihren Kommentar..."
                    (upsertComment)="onCommentSubmit($event)" />
            </div>
            <div class="tw-flex tw-flex-col tw-gap-y-5 tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                <div class="tw-text-xl tw-leading-6">Kommentare</div>
                <div class="tw-flex tw-flex-col tw-divide-y tw-divide-border-separator">
                    @if ((articleComments$ | async).length) {
                        @for (comment of articleComments$ | async; track comment.id) {
                            <div class="tw-flex tw-flex-col tw-gap-y-10 tw-py-10 first:tw-pt-0 last:tw-pb-0">
                                <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                    <ng-container *ngTemplateOutlet="commentTmpl; context: { articleId: article.id, comment }"></ng-container>
                                </div>
                                @if (comment.replies.length) {
                                    <div class="tw-flex tw-gap-x-5">
                                        <div class="tw-w-0.5 tw-rounded-full tw-bg-primary-base"></div>
                                        <div class="tw-flex tw-grow tw-flex-col tw-gap-y-10">
                                            @for (reply of comment.replies; track reply.id) {
                                                <ng-container *ngTemplateOutlet="commentTmpl; context: { articleId: article.id, comment: reply, parentCommentId: reply.parentCommentId }"></ng-container>
                                            }
                                        </div>
                                    </div>
                                }
                                <app-comment-input
                                    [parentCommentId]="comment.id"
                                    placeholder="Antworten Sie hier..."
                                    (upsertComment)="onSubCommentSubmit($event, comment.id)">
                                    @if (authUser$ | async; as user) {
                                        <img
                                            class="tw-peer tw-h-[50px] tw-w-[50px] tw-rounded-full tw-object-contain"
                                            [src]="'/derifiles/' + user.profilePictureId" />
                                    }
                                </app-comment-input>
                            </div>
                        }
                    } @else {
                        <span class="tw-text-secondary">Bisher keine Kommentare vorhanden.</span>
                    }
                </div>
            </div>
        </div>

        @if ((readMode$ | async) === ReadMode.View) {
            <div
                class="tw-fixed tw-bottom-[3.125rem] tw-right-8 tw-top-top-bar-lg tw-mt-32 tw-hidden 2xl:tw-block"
                [ngClass]="{
                    'tw--mx-1.5 tw--mb-1.5 tw-overflow-y-auto tw-px-1.5 tw-pb-1.5 tw-scrollbar-gutter-stable': (scrollbarActive$ | async) === false
                }">
                <div class="tw-h-full tw-w-[23rem] tw-overflow-y-auto tw-rounded tw-bg-white tw-px-5 tw-pb-7.5 tw-shadow">
                    <div
                        class="tw-sticky tw-top-0 tw-bg-white tw-py-7.5"
                        [ngClass]="{ 'tw-border-b tw-border-border-separator': article.attachments.length || article.products.length }">
                        <app-article-actions
                            [article]="article"
                            (clipboard)="onCopyToClipboard()"
                            (bookmark)="onArticleBookmark($event)"
                            (like)="onArticleLike($event)"
                            (print)="onArticlePrint()"
                            (delete)="onArticleDelete()"
                            (edit)="onArticleEdit(article.id)" />
                    </div>
                    <div class="tw-pt-7.5">
                        <app-article-assets
                            [attachments]="article.attachments"
                            [products]="article.products" />
                    </div>
                </div>
            </div>
        }
    }
} @else {
    <div
        class="tw-fixed tw-left-0 tw-top-top-bar tw-flex tw-h-[calc(100%-theme(height.top-bar-lg))] tw-w-full tw-items-center tw-justify-center sm:tw-top-top-bar-lg 2xl:tw-left-64"
        [ngClass]="{
            '2xl:tw-w-[calc(100%-theme(space.64))]': (articleLoaded$ | async) === false,
            '2xl:tw-w-[calc(100%-39rem)]': (readMode$ | async) === ReadMode.Explore && (articleLoaded$ | async)
        }">
        <chroma-loading />
    </div>
}

<ng-template
    #commentTmpl
    let-articleId="articleId"
    let-comment="comment"
    let-parentCommentId="parentCommentId">
    @if (asCommentBase(comment); as comment) {
        <div class="tw-flex tw-flex-col tw-gap-y-2.5">
            <div class="tw-flex tw-justify-between">
                <div class="tw-inline-flex tw-items-center tw-gap-x-2.5">
                    @if (comment.author.profilePictureId) {
                        <img
                            class="tw-h-[50px] tw-w-[50px] tw-rounded-full tw-object-contain"
                            [src]="'/derifiles/' + comment.author.profilePictureId" />
                    } @else {
                        <span class="tw-inline-flex tw-h-[50px] tw-w-[50px] tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light tw-text-center">
                            <span class="material-symbols-rounded tw-text-[2rem]">person</span>
                        </span>
                    }
                    <span class="tw-inline-flex tw-flex-col tw-gap-y-1">
                        <span class="tw-font-bold">{{ comment.author.firstName }} {{ comment.author.lastName }}</span>
                        <span class="tw-text-secondary">{{ comment.createdAt | date }} {{ comment.createdAt | date: 'shortTime' }}</span>
                    </span>
                </div>
                @if (comment.meta.isEditor) {
                    <div class="tw-flex tw-items-center tw-gap-x-1.25">
                        <button
                            type="button"
                            chroma-button
                            size="icon"
                            appearance="text"
                            class="tw-cursor-default"
                            (click)="onCommentEdit(comment.id, parentCommentId)">
                            <span
                                class="material-symbols-rounded tw-icon-xl"
                                [ngClass]="{
                                    'tw-filled-symbol': comment.editing
                                }"
                                >edit</span
                            >
                        </button>
                        <button
                            type="button"
                            chroma-button
                            size="icon"
                            appearance="text"
                            class="tw-cursor-default"
                            (click)="onCommentDelete(articleId, comment.id)">
                            <span class="material-symbols-rounded tw-icon-xl">delete</span>
                        </button>
                    </div>
                }
            </div>
            @if (comment.editing) {
                <app-comment-input
                    [editing]="true"
                    placeholder="Antworten Sie hier..."
                    [value]="comment.body"
                    (upsertComment)="onCommentUpdate(articleId, comment.id, $event)" />
            } @else {
                <tiptap-editor [editor]="comment.body | tiptapEditor" />
            }
        </div>
    }
</ng-template>

@if (printViewVisible) {
    <iframe
        #printView
        class="tw-fixed tw-top-0 tw--z-10"
        [src]="origin + '/print/article/' + (article$ | async).id | safeResourceUrl"></iframe>
}
