import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnDestroy, OnInit } from '@angular/core';
import {
    ButtonComponent,
    CheckboxComponent,
    CHROMA_DIALOG_DATA,
    ChromaDialogHeader,
    ChromaDialogRef,
    ChromaInput,
    ChromaSelectModule,
    FileSizePipe
} from 'chroma-ui';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, JSONContent } from '@tiptap/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TipTapExtensionsService } from '../../../services/tiptap-extensions.service';
import { Store } from '@ngrx/store';
import * as NavigationSelectors from '../../../../core/navigation/store/navigation.selectors';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ComposeArticleDialogData } from './compose-article-dialog.type';
import { AttachmentsApi } from '../../../api/attachments.api';
import {
    combineLatest,
    debounceTime,
    map,
    Observable,
    startWith,
    Subject,
    take,
    takeUntil
} from 'rxjs';
import { FileAttachment } from '../../../types/attachment.type';
import { OverlayModule } from '@angular/cdk/overlay';
import { emojis } from '../../../constants/emojis';
import { PickerColor, TextStyle } from '../../../types/tiptap.type';
import { ContentsApi } from '../../../api/contents.api';
import { ComposeArticleDialogStore } from './compose-article-dialog.store';
import { Product } from '../../../types/product.type';
import { Router } from '@angular/router';
import { SearchResult } from '../../../types/solr.type';

@Component({
    selector: 'app-compose-article-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        FileSizePipe,
        NgClass,
        NgStyle,
        ReactiveFormsModule,
        ChromaDialogHeader,
        ButtonComponent,
        ChromaSelectModule,
        ChromaInput,
        CheckboxComponent,
        NgxTiptapModule,
        OverlayModule
    ],
    templateUrl: './compose-article-dialog.component.html',
    providers: [ComposeArticleDialogStore, TipTapExtensionsService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComposeArticleDialogComponent implements OnInit, OnDestroy {
    data: ComposeArticleDialogData = inject(CHROMA_DIALOG_DATA);

    editor = new Editor({
        editorProps: {
            attributes: {
                class: 'tw-outline-none tw-min-h-60'
            }
        },
        extensions: inject(TipTapExtensionsService).getExtensions(),
        onSelectionUpdate: ({ editor }) => {
            if (editor.isActive('paragraph')) {
                this.textStyleControl.setValue('paragraph');
            } else {
                this.textStyleControl.setValue('heading');
            }
        }
    });

    textStyleControl = new FormControl<TextStyle>('paragraph');

    isColorPickerOpen = false;
    pickerColors = Object.values(PickerColor);
    PickerColor = PickerColor;

    isLinkInputOpen = false;

    isEmojiPickerOpen = false;
    emojis = emojis.map(emoji =>
        String.fromCodePoint(...emoji.split('-').map(u => parseInt(`0x${u}`, 16)))
    );

    maxCoverFileSize = 1;
    maxAttachmentFileSize = 15;

    isProductSelectionOpen = false;

    composeArticleForm: FormGroup<{
        categoryId: FormControl<string>;
        title: FormControl<string>;
        body: FormControl<JSONContent>;
        coverImageId: FormControl<string>;
        mustRead: FormControl<boolean>;
        pinned: FormControl<boolean>;
        notifyUsers: FormControl<boolean>;
        attachments: FormControl<Array<FileAttachment>>;
        products: FormControl<Array<Product>>;
        tags: FormControl<Array<any>>;
    }>;

    get bodyControl() {
        return this.composeArticleForm.controls.body;
    }

    get coverImageIdControl() {
        return this.composeArticleForm.controls.coverImageId;
    }

    get attachmentsControl() {
        return this.composeArticleForm.controls.attachments;
    }

    get productsControl() {
        return this.composeArticleForm.controls.products;
    }

    linkForm = new FormGroup({
        url: new FormControl<string>(null, Validators.required),
        text: new FormControl<string>(null)
    });

    get linkUrlControl() {
        return this.linkForm.controls.url;
    }

    get linkTextControl() {
        return this.linkForm.controls.text;
    }

    solrSearchControl = new FormControl();

    private _destroy = new Subject<void>();

    navigationGroups$ = this.store.select(NavigationSelectors.selectNavigationGroups);

    solrProducts$: Observable<Array<SearchResult>>;

    constructor(
        private router: Router,
        private dialogRef: ChromaDialogRef<ComposeArticleDialogComponent>,
        private store: Store,
        private readonly composeArticleDialogStore: ComposeArticleDialogStore,
        private contentsApi: ContentsApi,
        private attachmentsApi: AttachmentsApi
    ) {}

    ngOnInit(): void {
        const { article, editMode } = this.data;

        this.composeArticleForm = new FormGroup({
            categoryId: new FormControl(article.categoryId, Validators.required),
            title: new FormControl(editMode ? article.title : '', Validators.required),
            body: new FormControl(editMode ? article.body : null, Validators.required),
            coverImageId: new FormControl(editMode ? article.coverImageId : null),
            mustRead: new FormControl(editMode ? article.mustRead : false),
            pinned: new FormControl(editMode ? article.pinned : false),
            notifyUsers: new FormControl(false),
            attachments: new FormControl(editMode ? article.attachments : []),
            products: new FormControl(editMode ? article.products : []),
            tags: new FormControl([])
        });

        this.solrProducts$ = combineLatest([
            this.composeArticleDialogStore.solrProducts$,
            this.productsControl.valueChanges.pipe(startWith([]))
        ]).pipe(
            map(([solrProducts]) =>
                solrProducts.filter(
                    solrProduct =>
                        !this.productsControl.value
                            .map(product => product.dq)
                            .includes(solrProduct.id)
                )
            )
        );

        this.solrSearchControl.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy))
            .subscribe(term => this.composeArticleDialogStore.searchSolr({ term }));
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    isBodyControlInvalid(): boolean {
        return this.bodyControl.invalid && this.bodyControl.touched;
    }

    changeTextStyle(textStyle: TextStyle): void {
        if (textStyle === 'paragraph') {
            this.editor.commands.setParagraph();
        } else {
            this.editor.commands.setHeading({ level: 2 });
        }
    }

    changeTextColor(color: PickerColor): void {
        if (color === PickerColor.Black) {
            this.editor.chain().focus().unsetColor().run();
        } else {
            this.editor.chain().focus().setColor(color).run();
        }

        this.isColorPickerOpen = false;
    }

    openLinkInput(): void {
        this.isLinkInputOpen = true;

        const { view, state } = this.editor;
        const { from, to } = view.state.selection;

        if (!state.selection.empty) {
            const text = state.doc.textBetween(from, to, '');

            this.linkTextControl.setValue(text);
        }
    }

    insertLink(): void {
        if (this.linkForm.valid) {
            const linkText = this.linkTextControl.value || this.linkUrlControl.value;

            if (this.linkTextControl.dirty || !this.linkTextControl.value) {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: this.linkUrlControl.value })
                    .command(({ tr }) => {
                        tr.insertText(linkText);
                        return true;
                    })
                    .run();
            } else {
                this.editor.commands.setLink({
                    href: this.linkUrlControl.value
                });
            }

            this.isLinkInputOpen = false;
            this.linkForm.reset();
        }
    }

    insertImage(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = e => {
                this.editor
                    .chain()
                    .focus()
                    .setImage({ src: e.target.result as string })
                    .run();
            };

            reader.readAsDataURL(file);
        }
    }

    uploadCoverImage(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            if (file.size > this.maxCoverFileSize * 1024 * 1024) {
                this.coverImageIdControl.setErrors({
                    fileSizeExceeded: true
                });
            } else {
                this.attachmentsApi
                    .uploadFile(file)
                    .pipe(take(1))
                    .subscribe(data => this.coverImageIdControl.setValue(data.fileId));
            }
        }
    }

    removeCoverImage(): void {
        this.coverImageIdControl.setValue(null);
    }

    uploadAttachment(e: Event): void {
        const input = e.target as HTMLInputElement;
        const file = input.files?.[0];

        if (file) {
            if (file.size > this.maxAttachmentFileSize * 1024 * 1024) {
                this.attachmentsControl.setErrors({
                    fileSizeExceeded: true
                });
            } else {
                this.attachmentsApi
                    .uploadFile(file)
                    .pipe(take(1))
                    .subscribe(attachment =>
                        this.attachmentsControl.setValue([
                            ...this.attachmentsControl.value,
                            attachment
                        ])
                    );
            }
        }
    }

    removeAttachment(fileId: string): void {
        this.attachmentsControl.setValue(
            this.attachmentsControl.value.filter(attachment => attachment.fileId !== fileId)
        );
    }

    addProduct(product: Product): void {
        this.productsControl.setValue([...this.productsControl.value, product]);

        this.isProductSelectionOpen = false;
    }

    removeProduct(dq: string): void {
        this.productsControl.setValue(
            this.productsControl.value.filter(product => product.dq !== dq)
        );
    }

    upsertArticle(): void {
        if (this.composeArticleForm.valid) {
            const { attachments, products, ...formData } = this.composeArticleForm.getRawValue();
            const composedArticle = {
                ...formData,
                attachments: attachments.map(attachment => attachment.fileId),
                products: products.map(product => product.dq)
            };

            if (this.data.editMode) {
                this.contentsApi.editArticle(this.data.article.id, composedArticle).subscribe(() =>
                    this.dialogRef.close({
                        updated: true
                    })
                );
            } else {
                this.contentsApi
                    .createArticle(composedArticle)
                    .subscribe(data =>
                        this.router.navigate([
                            'feed',
                            'category',
                            data.categoryId,
                            'article',
                            data.id
                        ])
                    );
            }
        } else {
            this.composeArticleForm.markAllAsTouched();
        }
    }
}
