import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, forwardRef } from '@angular/core';
import { ChromaInput } from '../input/input';
import { ChromaFormField } from '../form-field/form-field';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest, delay, of, takeUntil } from 'rxjs';
import { ChromaSelect } from './select';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { ChromaOption } from '../core/option/option';

@Component({
  selector: 'chroma-select-search',
  standalone: true,
  templateUrl: './select-search.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChromaSelectSearch),
      multi: true
    }
  ],
  imports: [AsyncPipe, ReactiveFormsModule, ChromaFormField, ChromaInput]
})
export class ChromaSelectSearch implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild('searchSelectInput', { read: ElementRef, static: true }) searchSelectInput: ElementRef;

  public set _options(_options: QueryList<ChromaOption>) {
    this._options$.next(_options);
  }
  public _options$: BehaviorSubject<QueryList<ChromaOption>> = new BehaviorSubject<QueryList<ChromaOption>>(null);

  private optionsList$: Observable<Array<ChromaOption>> = this._options$.pipe(
    switchMap(_options =>
        _options
            ? _options.changes.pipe(
                  map(options => options.toArray()),
                  startWith(_options.toArray())
              )
            : of(null)
    )
  );

  private optionsLength$: Observable<number> = this.optionsList$.pipe(
    map(options => options ? options.length : 0)
  );

  formControl = new FormControl<string>('');

  showNoEntriesFound$: Observable<boolean> = combineLatest([
    this.formControl.valueChanges,
    this.optionsLength$
  ]).pipe(map(([value, optionsLength]) => value && optionsLength === 0));

  private previousSelectedValues: Array<any>;

  protected readonly _destroy = new Subject<void>();

  constructor(@Inject(ChromaSelect) public chromaSelect: ChromaSelect) { }

  ngOnInit(): void {
    this.chromaSelect.openedChange
        .pipe(delay(1), takeUntil(this._destroy))
        .subscribe(opened => (opened ? this._focus() : this._reset()));

    this.chromaSelect.openedChange.pipe(
      take(1),
      switchMap(() => this._options = this.chromaSelect.options)
    ).pipe(takeUntil(this._destroy)).subscribe();

    this.initMultipleHandling();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  writeValue(value: string): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.pipe(
      takeUntil(this._destroy)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void { }

  private _focus(): void {
    this.searchSelectInput.nativeElement.focus();
  }

  private _reset(): void {
    this.formControl.setValue('');
  }

  private initMultipleHandling(): void {
    if (this.chromaSelect.ngControl && this.chromaSelect.multiple) {
        this.chromaSelect.ngControl.valueChanges
            .pipe(takeUntil(this._destroy))
            .subscribe(values => {
                let restoreSelectedValues = false;

                if (this.previousSelectedValues) {
                    const optionValues = this.chromaSelect.options.map(option => option.value);
                    this.previousSelectedValues.forEach(previousValue => {
                        if (
                            !values.some(v => this.chromaSelect.compareWith(v, previousValue)) &&
                            !optionValues.some(v => this.chromaSelect.compareWith(v, previousValue))
                        ) {
                            values.push(previousValue);
                            restoreSelectedValues = true;
                        }
                    });
                }
                this.previousSelectedValues = values;

                if (restoreSelectedValues) {
                    this.chromaSelect._onChange(values);
                }
            });
    }
  }
}
