<div
    cdkOverlayOrigin
    class="tw-inline-flex tw-w-full"
    (click)="toggle()">
    <div class="tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
        <span
            class="tw-text-base tw-leading-6 tw-text-black dark:tw-text-old-black"
            [ngClass]="{'tw-text-neutral-400 dark:tw-text-old-gray-500' : disabled}"
            >{{ empty ? 'Bitte wählen' : triggerValue }}</span
        >
    </div>
    <span
        class="material-symbols-rounded tw-text-primary-base"
        [ngClass]="{'tw-text-neutral-400 dark:tw-text-old-gray-500' : disabled}"
        >expand_more</span
    >
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="_preferredOverlayOrigin"
    [cdkConnectedOverlayPositions]="_positions"
    [cdkConnectedOverlayOpen]="panelOpen"
    [cdkConnectedOverlayWidth]="_overlayWidth"
    (overlayOutsideClick)="close()"
    (attach)="_onAttached()"
    (detach)="close()">
    <div
        #panel
        class="tw-max-h-[275px] tw-w-full tw-overflow-auto tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-shadow-soft dark:tw-rounded-none dark:tw-border-old-gray-200 dark:tw-shadow-none"
        [ngClass]="{
            'tw-pb-1.25': _hasSearch,
            'tw-py-1.25': !_hasSearch
        }">
        <ng-content />
    </div>
</ng-template>
