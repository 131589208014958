<div class="tw-sticky tw-top-0 tw-flex tw-bg-white tw-px-3.75 tw-pb-1.25 tw-pt-2.5">
    <chroma-form-field>
        <input
            #searchSelectInput
            chromaInput
            type="text"
            placeholder="Suche"
            [formControl]="formControl" />
    </chroma-form-field>
</div>
@if (showNoEntriesFound$ | async) {
<div class="tw-px-3.5 tw-py-1.25 tw-text-neutral-400 dark:tw-text-old-gray-500">Keine Ergebnisse gefunden.</div>
}
