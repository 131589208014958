<div
    class="tw-flex tw-flex-col tw-gap-y-6 tw-rounded tw-px-2.5 tw-pb-2.5 tw-ring-1 tw-ring-inset"
    [ngClass]="{
        'tw-ring-2': focused,
        'tw-ring-secondary': !invalid && !focused,
        'tw-ring-primary-focus': focused && !invalid,
        'tw-ring-red-600': invalid,
        'tw-pt-[0.4375rem]': showToolbar,
        'tw-pt-2.5': !showToolbar
    }">
    @if (showToolbar) {
        <div class="tw-divide-separator tw-hidden tw-divide-x sm:tw-flex">
            <div class="tw-flex tw-w-[250px] tw-pr-2.5">
                <chroma-form-field>
                    <chroma-select
                        [formControl]="textStyleControl"
                        (selectionChange)="changeTextStyle($event)">
                        <chroma-option value="heading">Überschrift</chroma-option>
                        <chroma-option value="paragraph">Fließtext</chroma-option>
                    </chroma-select>
                </chroma-form-field>
            </div>
            <div class="tw-flex tw-gap-x-1.25 tw-px-2.5">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('bold')"
                    (click)="editor.chain().focus().toggleBold().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_bold</span>
                </button>
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('italic')"
                    (click)="editor.chain().focus().toggleItalic().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_italic</span>
                </button>
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('underline')"
                    (click)="editor.chain().focus().toggleUnderline().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_underlined</span>
                </button>
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('strike')"
                    (click)="editor.chain().focus().toggleStrike().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_strikethrough</span>
                </button>
            </div>
            <div class="tw-px-2.5">
                <button
                    type="button"
                    chroma-button
                    class="tw-relative tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="isColorPickerOpen"
                    cdkOverlayOrigin
                    #colorPickerTrigger="cdkOverlayOrigin"
                    [class.tw-pointer-events-none]="isColorPickerOpen"
                    (click)="isColorPickerOpen = !isColorPickerOpen">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_color_text</span>
                    <span
                        class="tw-absolute tw-bottom-[9px] tw-h-[4px] tw-w-[17px] tw-rounded-[1px]"
                        [ngStyle]="{
                            'background-color': editor.getAttributes('textStyle').color ? editor.getAttributes('textStyle').color : PickerColor.Black
                        }"></span>
                </button>
                <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="colorPickerTrigger"
                    [cdkConnectedOverlayOpen]="isColorPickerOpen"
                    (overlayOutsideClick)="isColorPickerOpen = false">
                    <div class="tw-my-2.5 tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                        <div class="tw-grid tw-grid-cols-4 tw-gap-2.5">
                            @for (color of pickerColors; track $index) {
                                <button
                                    type="button"
                                    class="tw-inline-flex tw-h-7.5 tw-w-7.5 tw-cursor-default tw-items-center tw-justify-center tw-rounded-half"
                                    [ngStyle]="{ 'background-color': color }"
                                    (click)="changeTextColor(color)">
                                    <span
                                        class="material-symbols-rounded"
                                        [ngClass]="{
                                            'tw-text-white': editor.isActive('textStyle', { color }) || (color === PickerColor.Black && !editor.getAttributes('textStyle').color),
                                            'tw-text-transparent hover:tw-text-white/60': !editor.isActive('textStyle', { color }) && (color !== PickerColor.Black || editor.getAttributes('textStyle').color)
                                        }">
                                        check
                                    </span>
                                </button>
                            }
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="tw-flex tw-gap-x-1.25 tw-px-2.5">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('bulletList')"
                    (click)="editor.chain().focus().toggleBulletList().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_list_bulleted</span>
                </button>
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="editor.isActive('orderedList')"
                    (click)="editor.chain().focus().toggleOrderedList().run()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">format_list_numbered</span>
                </button>
            </div>
            <div class="tw-flex tw-gap-x-1.25 tw-pl-2.5">
                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    cdkOverlayOrigin
                    #linkInputTrigger="cdkOverlayOrigin"
                    [class.tw-pointer-events-none]="isLinkInputOpen"
                    (click)="openLinkInput()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">link</span>
                </button>
                <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="linkInputTrigger"
                    [cdkConnectedOverlayOpen]="isLinkInputOpen"
                    (overlayOutsideClick)="isLinkInputOpen = false; linkForm.reset()">
                    <div class="tw-my-2.5 tw-w-[400px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                        <form [formGroup]="linkForm">
                            <div class="tw-flex tw-flex-col tw-items-end tw-gap-y-5">
                                <div class="tw-flex tw-w-full">
                                    <chroma-form-field>
                                        <input
                                            type="text"
                                            chroma-input
                                            formControlName="url"
                                            placeholder="URL" />
                                    </chroma-form-field>
                                </div>
                                <div class="tw-flex tw-w-full">
                                    <chroma-form-field>
                                        <input
                                            type="text"
                                            chroma-input
                                            formControlName="text"
                                            placeholder="Anzeigetext (optional)" />
                                    </chroma-form-field>
                                </div>
                                <div>
                                    <button
                                        type="text"
                                        chroma-button
                                        class="tw-cursor-default"
                                        (click)="insertLink()">
                                        Einfügen
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>

                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    (click)="editorImageFile.click()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">image</span>
                </button>
                <input
                    #editorImageFile
                    type="file"
                    class="tw-hidden"
                    accept="image/png, image/jpeg"
                    (change)="insertImage($event)" />

                <button
                    type="button"
                    chroma-button
                    class="tw-cursor-default"
                    size="icon"
                    appearance="text"
                    [active]="isEmojiPickerOpen"
                    cdkOverlayOrigin
                    #emojiPickerTrigger="cdkOverlayOrigin"
                    [class.tw-pointer-events-none]="isEmojiPickerOpen"
                    (click)="isEmojiPickerOpen = !isEmojiPickerOpen">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-none">sentiment_satisfied</span>
                </button>
                <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="emojiPickerTrigger"
                    [cdkConnectedOverlayOpen]="isEmojiPickerOpen"
                    (overlayOutsideClick)="isEmojiPickerOpen = false">
                    <div class="tw-my-2.5 tw-h-[251px] tw-rounded tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3.75 tw-py-2.5 tw-shadow-soft">
                        <div class="tw-h-full tw-overflow-y-auto">
                            <div class="tw-mr-4 tw-grid tw-grid-cols-7 tw-gap-1.25">
                                @for (emoji of emojis; track $index) {
                                    <button
                                        type="button"
                                        class="tw-h-[34px] tw-w-[34px] tw-cursor-default tw-rounded hover:tw-bg-primary-hover-light"
                                        (click)="editor.commands.setEmoji(emoji)">
                                        <span
                                            class="tw-inline-block tw-h-[24px] tw-w-[24px] tw-font-emoji tw-text-[24px] tw-leading-[32px]"
                                            [innerHTML]="emoji"></span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    }

    <tiptap-editor [editor]="editor" />
</div>
