import { Component, Inject } from '@angular/core';
import { CHROMA_DIALOG_DATA, ChromaDialogHeader, ChromaDialogRef } from 'chroma-ui';
import { FactsheetDialogData } from './factsheet-dialog.type';
import { SafeResourceUrlPipe } from '../../../../../../../shared/pipes/safe-resource-url.pipe';

@Component({
    selector: 'app-factsheet-dialog',
    standalone: true,
    imports: [ChromaDialogHeader, SafeResourceUrlPipe],
    templateUrl: './factsheet-dialog.component.html',
})
export class FactsheetDialogComponent {
    constructor(
        private dialogRef: ChromaDialogRef<FactsheetDialogComponent>,
        @Inject(CHROMA_DIALOG_DATA) public data: FactsheetDialogData
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }
}
