import { NgModule } from '@angular/core';
import { ChromaSelect } from './select';
import { ChromaFormFieldModule } from '../form-field/module';
import { ChromaOptionModule } from '../core/option';

@NgModule({
    imports: [ChromaSelect, ChromaOptionModule],
    exports: [ChromaFormFieldModule, ChromaSelect, ChromaOptionModule]
})
export class ChromaSelectModule {}
