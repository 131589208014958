<div class="tw-flex tw-flex-col tw-gap-y-10 print:tw-gap-y-5">
    @if (attachments().length) {
        <div class="tw-flex tw-flex-col tw-gap-y-5 print:tw-gap-y-2.5">
            <span class="tw-text-xl tw-leading-6 print:tw-text-xs print:tw-leading-[0.9375rem]">Anhänge</span>
            <span class="tw-flex tw-flex-wrap tw-gap-5 print:tw-grid print:tw-grid-cols-3">
                @for (attachment of attachments(); track $index) {
                    <a
                        [href]="'/derifiles/' + attachment.fileId"
                        target="_blank"
                        class="tw-w-asset-button tw-cursor-default tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base hover:tw-bg-primary-hover-light print:tw-w-full print:tw-px-2.5 print:tw-py-1.25">
                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                            <span class="material-symbols-rounded tw-text-asset-icon print:tw-text-2xl print:tw-leading-none">attach_file</span>
                            <span class="tw-inline-flex tw-min-w-0 tw-flex-col">
                                <span class="tw-inline-flex tw-text-sm tw-font-bold print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]"
                                    ><span class="tw-line-clamp-1 tw-break-all">{{ attachment.fileName.split('.')[0] }}</span>
                                    <span>.{{ attachment.fileName.split('.')[1] }}</span>
                                </span>
                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]">{{ attachment.size | filesize }}</span>
                            </span>
                        </span>
                    </a>
                }
            </span>
        </div>
    }

    @if (products().length) {
        <div class="tw-hidden tw-flex-col tw-gap-y-5 xl:tw-flex print:tw-flex print:tw-gap-y-2.5">
            <span class="tw-text-xl tw-leading-6 print:tw-text-xs print:tw-leading-[0.9375rem]">Finanzprodukte</span>
            <span class="tw-flex tw-flex-wrap tw-gap-5 print:tw-grid print:tw-grid-cols-3">
                @for (product of products(); track $index) {
                    <button
                        type="button"
                        class="tw-w-asset-button tw-cursor-default tw-rounded tw-px-3.75 tw-py-2.5 tw-text-left tw-ring-1 tw-ring-inset tw-ring-primary-base hover:tw-bg-primary-hover-light print:tw-w-full print:tw-px-2.5 print:tw-py-1.25"
                        (click)="openFactsheetDialog(product.dq)">
                        <span class="tw-flex tw-items-center tw-gap-x-2.5">
                            <span class="material-symbols-rounded tw-text-asset-icon print:tw-text-2xl print:tw-leading-none">show_chart</span>
                            <span class="tw-inline-flex tw-min-w-0 tw-flex-col">
                                <span class="tw-line-clamp-1 tw-break-all tw-text-sm tw-font-bold print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]">{{ product.name }} </span>
                                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]">(ISIN) {{ product.isin }}</span>
                            </span>
                        </span>
                    </button>
                }
            </span>
        </div>
    }
</div>
