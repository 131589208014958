import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectAuthLoggingIn = createSelector(selectAuth, state => state.loggingIn);

export const selectAuthUser = createSelector(selectAuth, state => state.user);

export const selectIsLoggedIn = createSelector(selectAuth, state => state.loggedIn);

export const selectAuthError = createSelector(selectAuth, state => state.error);
