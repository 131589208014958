import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { NavigationsApi } from '../api/navigations.api';
import { NavigationActions } from './navigation.actions';
import { filter, map, of, switchMap } from 'rxjs';
import * as NavigationSelectors from './navigation.selectors';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as RouterSelectors from '../../../shared/store/router/router.selectors';

@Injectable()
export class NavigationEffects {
    loadGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.loadGroups),
            switchMap(() =>
                this.navigationsApi
                    .loadDisplays()
                    .pipe(map(data => NavigationActions.setGroups({ data })))
            )
        )
    );

    loadUnseen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.loadUnseen, NavigationActions.setGroups),
            switchMap(() =>
                this.navigationsApi
                    .loadUnseen()
                    .pipe(map(data => NavigationActions.patchUnseen({ data })))
            )
        )
    );

    loadFirstCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.setGroups),
            concatLatestFrom(() => this.store.select(RouterSelectors.selectRouteParams)),
            filter(([, params]) => !params.categoryId),
            concatLatestFrom(() =>
                this.store
                    .select(NavigationSelectors.selectNavigationGroups)
                    .pipe(map(groups => groups[0].navigationItems[0].categoryId))
            ),
            switchMap(([, firstCategoryId]) => {
                this.router.navigate(['feed', 'category', firstCategoryId]);
                return of(NavigationActions.expandActiveGroup({ categoryId: firstCategoryId }));
            })
        )
    );

    expandCategoryGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.setGroups),
            concatLatestFrom(() => this.store.select(RouterSelectors.selectRouteParams)),
            filter(([, params]) => params.categoryId),
            map(([, params]) =>
                NavigationActions.expandActiveGroup({
                    categoryId: params.categoryId
                })
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private router: Router,
        private navigationsApi: NavigationsApi
    ) {}
}
