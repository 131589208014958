import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'chroma-dialog-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-header.html',
})
export class ChromaDialogHeader {

  @Input() title: string;
  
  @Input() largeHeader = false;

  @Output() closeDialog = new EventEmitter<void>();
}
